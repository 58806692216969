import { Inject, Injectable } from '@angular/core'
import SphericalMercator from '@mapbox/sphericalmercator'
import maplibregl, { GeoJSONSource, LngLat, Map, MapGeoJSONFeature } from 'maplibre-gl'
import { XyzTileModel } from '../../shared/models/xyz-tile.model'
import { Subject } from 'rxjs'
import { FeatureCollection } from 'geojson'
import * as turf from '@turf/turf'
import { MapIcons } from '../enums/map-icons.enum'
import { GeocodingControl } from '@maptiler/geocoding-control/maplibregl'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from '../models'
import { Layers } from 'src/app/shared/layers-config/layers'

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private sphericalMercator: SphericalMercator
  private currentXyzTiles: XyzTileModel[] = []
  private currentCenter: LngLat = new LngLat(0, 0)
  private currentZoom: number | undefined
  public mapViewChanged = new Subject<XyzTileModel[]>()
  public mapReadyChanged = new Subject<void>()
  public isMapReady = false

  public gc = new GeocodingControl({
    apiKey: this.config.maptiler_key,
    marker: false,
    limit: 4,
    minLength: 2,
    fuzzyMatch: false,
    debounceSearch: 500,
    language: ['de', 'en'],
    collapsed: false,
    noResultsMessage: 'No matching location found. Format: lat/lon always 3 decimals',
    errorMessage: 'No matching location found. Format: lat/lon always 3 decimals',
    types: ['neighbourhood', 'place', 'postal_code', 'poi'],
    excludeTypes: true,
  })
  public markerClassName = ''
  public el = document.createElement('div')
  public marker = new maplibregl.Marker({
    anchor: 'bottom',
    element: this.el,
    draggable: false,
  })

  public map!: Map
  private readonly images = [
    { uri: 'assets/images/accident.png', id: MapIcons.VA },
    { uri: 'assets/images/bdv.png', id: MapIcons.BDV },
    { uri: 'assets/images/tl.png', id: MapIcons.TL },
    { uri: 'assets/images/cs.png', id: MapIcons.CS },
    { uri: 'assets/images/lv.png', id: MapIcons.LV },
    { uri: 'assets/images/heavy-rain.png', id: MapIcons.HR },
    { uri: 'assets/images/heavy-snow.png', id: MapIcons.HS },
    { uri: 'assets/images/strong-wind.png', id: MapIcons.SW },
    { uri: 'assets/images/accident-test.png', id: MapIcons.TVA },
    { uri: 'assets/images/bdv-test.png', id: MapIcons.TBDV },
    { uri: 'assets/images/tl-test.png', id: MapIcons.TTL },
    { uri: 'assets/images/cs-test.png', id: MapIcons.TCS },
    { uri: 'assets/images/lv-test.png', id: MapIcons.TLV },
    { uri: 'assets/images/heavy-rain-test.png', id: MapIcons.THR },
    { uri: 'assets/images/heavy-snow-test.png', id: MapIcons.THS },
    { uri: 'assets/images/strong-wind-test.png', id: MapIcons.TSW },
    { uri: 'assets/images/camera.png', id: MapIcons.WC },
    { uri: 'assets/images/keyboard-arrow-left.png', id: 'arrow' },
    { uri: 'assets/images/arrows-left-right.png', id: 'arrow-both' },
  ]

  constructor(@Inject(APP_CONFIG) private readonly config: AppConfigModel) {
    this.sphericalMercator = new SphericalMercator({ size: 256 })
  }

  private calcXyzTiles(minZoom?: number, maxZoom?: number): XyzTileModel[] {
    const xyzTiles: XyzTileModel[] = []

    if (this.map) {
      let zoom = Math.floor(this.map.getZoom())
      // if there is a max zoom provided don't return tiles for larger zoom levels
      zoom = maxZoom !== undefined ? Math.min(zoom, maxZoom) : zoom
      zoom = minZoom !== undefined ? Math.max(zoom, minZoom) : zoom
      const tiles = this.sphericalMercator.xyz(
        [
          this.map.getBounds().getWest(),
          this.map.getBounds().getSouth(),
          this.map.getBounds().getEast(),
          this.map.getBounds().getNorth(),
        ],
        zoom,
      )
      for (let x = tiles.minX; x <= tiles.maxX; x++) {
        for (let y = tiles.minY; y <= tiles.maxY; y++) {
          xyzTiles.push({
            x: x,
            y: y,
            z: zoom,
          })
        }
      }
    }

    return xyzTiles
  }

  public initMap(map: Map) {
    window.map = map
    this.map = map
    this.map.on('moveend', () => {
      this.updateMapTiles()
    })
    this.map.on('zoomend', () => {
      this.updateMapTiles()
    })
    this.map.on('load', async () => {
      if (this.map.loaded()) {
        await this.loadImages()
        this.isMapReady = true
        this.updateMapTiles(false)
        this.mapReadyChanged.next()
      }
    })
  }

  public getXyzTiles(maxZoom?: number, minZoom?: number) {
    if (minZoom && maxZoom) {
      return this.calcXyzTiles(minZoom, maxZoom)
    } else if (maxZoom) {
      return this.calcXyzTiles(maxZoom)
    }
    return this.currentXyzTiles
  }

  public setPosition(lat?: number, lon?: number, zoom?: number) {
    if (lat && lon && this.currentCenter.lat != lat && this.currentCenter.lng != lon) {
      this.currentCenter = new LngLat(lon, lat)
      this.map.setCenter(this.currentCenter)
    }
    if (zoom && this.currentZoom != zoom) {
      this.currentZoom = zoom
      this.map.setZoom(this.currentZoom)
    }
  }

  public setLayerVisibility(layerId: string, isVisible: boolean) {
    this.map.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none')
  }

  public getLayerVisibilty(layerId: string) {
    const layer = this.map.getLayer(layerId)
    if (layer && layer.visibility === 'visible') {
      return true
    }
    return false
  }

  public updateMapTiles(emitEvent = true): void {
    this.currentXyzTiles = this.calcXyzTiles()
    this.currentCenter = this.map.getCenter()
    this.currentZoom = this.map.getZoom()
    if (emitEvent) {
      this.mapViewChanged.next(this.currentXyzTiles)
    }

    // if (!isEqual(this.currentXyzTiles, xyzTiles) || forceUpdate) {
    //   this.currentXyzTiles = xyzTiles
    //   this.xyzTileSubject.next(xyzTiles)
    // }
  }

  public setGeoJsonData(sourceId: string, featureCollection: FeatureCollection) {
    const source: GeoJSONSource | undefined = this.map.getSource(sourceId) as GeoJSONSource
    if (source) {
      source.setData(featureCollection)
    }
  }

  public getFeatures(layerId: string, bbox: turf.helpers.BBox): MapGeoJSONFeature[] {
    const coordSw = this.map.project([bbox[0], bbox[1]])
    const coordNe = this.map.project([bbox[2], bbox[3]])

    const features = this.map.queryRenderedFeatures([coordSw, coordNe], {
      layers: [layerId],
    })

    if (features && features.length > 0) {
      return features.filter(
        (obj, index) => features.findIndex((item) => item.id === obj.id) === index,
      )
    }
    return []
  }

  private async loadImages(): Promise<any> {
    for (const image of this.images) {
      const imageToAdd = await this.map.loadImage(image.uri)
      if (imageToAdd) {
        this.map.addImage(image.id, imageToAdd.data)
      }
    }
  }

  public activateGeoLocationSearch() {
    this.map.addControl(this.gc, 'top-left')
    return undefined
  }

  public deactivateGeoLocationSearch() {
    this.map.removeControl(this.gc)
    return undefined
  }

  public setMarker(coordinates: LngLat, className: string) {
    this.markerClassName ? this.marker.removeClassName(this.markerClassName) : null
    this.markerClassName = className
    this.marker.addClassName(this.markerClassName)
    this.marker.setLngLat(coordinates).addTo(this.map)
  }

  public changeMarker(className: string) {
    try {
      this.marker ? this.marker.removeClassName(this.markerClassName) : null
      this.markerClassName = className
      this.marker.addClassName(this.markerClassName)
    } catch (e) {}
  }

  public removeMarker() {
    try {
      this.marker.remove()
    } catch (e) {
      console.log(e)
    }
  }

  public changeMapStyle(style: string) {
    const layerCfg = {
      transformStyle: (previousStyle: any, nextStyle: any) => {
        var custom_layers = previousStyle
          ? previousStyle.layers.filter((layer: any) => {
              return Object.values(Layers).find((entry) =>
                layer.id.substring(0, entry.name.length).includes(entry.name),
              )
            })
          : []
        var layers = nextStyle.layers.concat(custom_layers)
        var sources = nextStyle.sources
        if (previousStyle) {
          for (const [key, value] of Object.entries(previousStyle.sources)) {
            if (key) {
              sources[key] = value
            }
          }
        }
        return {
          ...nextStyle,
          sources: sources,
          layers: layers,
        }
      },
    }
    this.map.setStyle(style, layerCfg)
  }
}
