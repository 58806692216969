import { DataDrivenPropertyValueSpecification, ExpressionSpecification } from 'maplibre-gl'

export const colorByPropertyName: DataDrivenPropertyValueSpecification<string> | undefined = [
  'case',
  ['==', ['get', 'name'], 'accident'],
  '#ae4203',
  ['==', ['get', 'name'], 'brokenDownVehicle'],
  '#ad9702',
  ['==', ['get', 'name'], 'constructionSite'],
  '#088735',
  ['==', ['get', 'name'], 'lowVisibility'],
  '#0052a8',
  ['==', ['get', 'name'], 'tractionLoss'],
  '#ad0206',
  ['==', ['get', 'name'], 'strongWind'],
  '#99c408',
  ['==', ['get', 'name'], 'heavyRain'],
  '#d1026d',
  ['==', ['get', 'name'], 'heavySnow'],
  '#02d1a1',
  '#ffffff',
]

export const strokeByPropertyName: DataDrivenPropertyValueSpecification<string> | undefined = [
  'case',
  ['==', ['get', 'name'], 'accident'],
  '#d07002',
  ['==', ['get', 'name'], 'brokenDownVehicle'],
  '#d6c967',
  ['==', ['get', 'name'], 'constructionSite'],
  '#34a85d',
  ['==', ['get', 'name'], 'lowVisibility'],
  '#4f7fb3',
  ['==', ['get', 'name'], 'tractionLoss'],
  '#c2696b',
  ['==', ['get', 'name'], 'strongWind'],
  '#cce86d',
  ['==', ['get', 'name'], 'heavyRain'],
  '#d690b4',
  ['==', ['get', 'name'], 'heavySnow'],
  '#9de0d1',
  '#ffffff',
]

export const warningFilter = {
  accidentFilter: ['==', ['get', 'name'], 'accident'] as ExpressionSpecification,
  bdvFilter: ['==', ['get', 'name'], 'brokenDownVehicle'] as ExpressionSpecification,
  csFilter: ['==', ['get', 'name'], 'constructionSite'] as ExpressionSpecification,
  hrFilter: ['==', ['get', 'name'], 'heavyRain'] as ExpressionSpecification,
  hsFilter: ['==', ['get', 'name'], 'heavySnow'] as ExpressionSpecification,
  swFilter: ['==', ['get', 'name'], 'strongWind'] as ExpressionSpecification,
  tlFilter: ['==', ['get', 'name'], 'tractionLoss'] as ExpressionSpecification,
  lvFilter: ['==', ['get', 'name'], 'lowVisibility'] as ExpressionSpecification,
}
