<div class="position-absolute bottom-7 start-20">
  <app-scale-control> </app-scale-control>
</div>

<div
  class="position-absolute bottom-7 end-5 z-3 shadow-sm rounded-2 bg-dark cursor-pointer border border-darkgray d-flex gap-2 align-items-center text-deactivated"
>
  <div class="d-flex">
    <div
      class="d-flex flex-column justify-content-center p-2 text-deactivated"
      placement="top"
      ngbTooltip="Show history mode"
      tooltipClass="custom-tooltip"
      [openDelay]="1000"
    >
      <span
        class="rb-ic rb-ic-history fs-2xl ps-2 ho-layer-text"
        (click)="toggleHistory()"
        [class.sel-menu-item]="isHistoryVisible"
        *hasPermissionAny="[Permissions.Historic_All_Read]"
      ></span>
    </div>
  </div>

  <app-auto-refresh
    (intervalSet)="setLiveModeRefreshInterval($event)"
    [isActive]="isInLiveMode"
    [isDisabled]="isHistoryVisible"
  ></app-auto-refresh>
</div>
<app-map-navigation>
  <span class="border-bottom border-darkgray"> </span>
  <span
    class="rb-ic rb-ic-alert-info fs-2xl hover-effect p-2"
    [class.sel-menu-item]="isLegendVisible"
    (click)="toggleLegend()"
    placement="start"
    ngbTooltip="Show legend"
    tooltipClass="custom-tooltip"
    [openDelay]="1000"
  >
  </span>
</app-map-navigation>
<app-obstacle-layer-config></app-obstacle-layer-config>
<app-obstacle-layer-legend *ngIf="isLegendVisible"></app-obstacle-layer-legend>
<app-obstacle-raw-details></app-obstacle-raw-details>
<app-obstacle-road-details></app-obstacle-road-details>
<app-obstacle-history-control
  (historyClosed)="handleHistoryClosed()"
  *ngIf="isHistoryVisible"
></app-obstacle-history-control>
<app-last-backend-call></app-last-backend-call>
