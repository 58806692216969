import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  private messages: string[] = []
  public messagesChanged = new Subject<string[]>()
  public debugVisibiltyChanged = new Subject<boolean>()
  public isDebugVisibile = false

  constructor() {}

  writeToLog(message: string) {
    this.messages.push(message)
    this.messagesChanged.next(this.messages)
  }

  getMessages(): string[] {
    return this.messages
  }

  clearMessages(): void {
    this.messages = []
  }

  toggleDebugVisibility() {
    this.isDebugVisibile = !this.isDebugVisibile
    this.debugVisibiltyChanged.next(this.isDebugVisibile)
  }
}
