<div class="flex-fill">
  <span
    class="d-flex flex-column align-items-center justify-content-center rounded-2 ho-layer-item"
    [class.sel-menu-item]="isSelected === true"
    (click)="clicked.emit(key)"
  >
    <div class="rb-ic fs-2xl bg-darkgray p-2 rounded-2">
      <span [ngClass]="getIcon()" [ngStyle]="{ transform: 'rotate(' + rotateIcon + 'deg)' }"></span>
    </div>
    <div *ngIf="description" class="position-relative mb-6">
      <span class="position-absolute translate-middle-x top-1 fs-2xs text-center text-wrap">{{
        description
      }}</span>
    </div>
  </span>
</div>
