import { Component } from '@angular/core'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { debounceTime } from 'rxjs'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from '../../../shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { BwcMetrics } from '../../enums/bwc-metrics.enum'
import { WeatherPropertiesModel } from 'src/app/shared/models/weather-properties.model'
import { WeatherPredictions } from '../../../shared/models/weather.model'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { format } from 'date-fns'
import { AppPaths } from 'src/app/app-routing.module'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-bwc-details-weather',
  templateUrl: './bwc-details-weather.component.html',
})
export class BwcDetailsWeatherComponent {
  public details = new Map<string, DetailsModel>()
  public metric: string[] | undefined = []
  public BwcMetrics = BwcMetrics
  public Permissions = Permissions
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public bearing = ''
  public icon = 'rb-ic rb-ic-arrow-down-frame fs-2xl'
  public selectedFeatureId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    private appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (features[this.index].layer.id === Layers.WeatherPrediction.name) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    this.metric = this.appState.selectMetrics(Layers.WeatherPrediction.name)
    const properties: WeatherPropertiesModel = this.features[this.index]
      .properties as WeatherPropertiesModel
    const weatherPredictions: WeatherPredictions = (this.features[this.index] as any)?.state
    this.selectedFeatureId = this.features[this.index].id?.toString() ?? ''
    this.mapWeatherToDetails(properties, weatherPredictions)
    this.appState.setState(AppPaths.BadWeatherCondition, {
      selectedFeatureId: this.selectedFeatureId,
    })
  }

  mapWeatherToDetails(properties: WeatherPropertiesModel, weatherPredictions: WeatherPredictions) {
    this.details.set('Meta', {
      title: 'Area',
      items: [
        {
          description: 'Voronoi ID',
          value: properties.SegmentId,
        },
        {
          description: 'Global ID',
          value: this.selectedFeatureId,
        },
        {
          description: `Last Updated (${getTimeZoneLabel(new Date(weatherPredictions?.timestamp))})`,
          value: weatherPredictions?.timestamp
            ? format(new Date(weatherPredictions?.timestamp), 'yyyy-MM-dd HH:mm:ss')
            : '-',
        },
      ],
    })

    this.details.set(BwcMetrics.WeatherPrediction_AirTemp, {
      title: 'Air Temperature',
      items: [
        {
          description: 'Value',
          value: weatherPredictions?.airTemp?.value.toFixed(2),
          unit: '°C',
        },
        {
          description: 'Lower bound',
          value: weatherPredictions?.airTempLb?.value.toFixed(2),
          unit: '°C',
        },
        {
          description: 'Upper bound',
          value: weatherPredictions?.airTempUb?.value.toFixed(2),
          unit: '°C',
        },
      ],
    })

    this.details.set(BwcMetrics.WeatherPrediction_PercipRain, {
      title: 'Precipitation Rain',
      items: [
        {
          description: 'Value',
          value: weatherPredictions?.prr?.value.toFixed(2),
          unit: 'mm/h',
        },
        {
          description: 'Lower bound',
          value: weatherPredictions?.prrLb?.value.toFixed(2),
          unit: 'mm/h',
        },
        {
          description: 'Upper bound',
          value: weatherPredictions?.prrUb?.value.toFixed(2),
          unit: 'mm/h',
        },
      ],
    })

    this.details.set(BwcMetrics.WeatherPrediction_Visibility, {
      title: 'Visibility',
      items: [
        {
          description: 'Value',
          value: weatherPredictions?.visibility?.value.toString(),
          unit: 'm',
        },
      ],
    })

    this.details.set(BwcMetrics.WeatherPrediction_PercipSnow, {
      title: 'Precipitation Snow',
      items: [
        {
          description: 'Value',
          value: (weatherPredictions?.prs?.value).toFixed(1),
          unit: 'mm/h',
        },
        {
          description: 'Lower bound',
          value: weatherPredictions?.prsLb?.value.toFixed(2),
          unit: 'mm/h',
        },
        {
          description: 'Upper bound',
          value: weatherPredictions?.prsUb?.value.toFixed(2),
          unit: 'mm/h',
        },
      ],
    })

    this.details.set(BwcMetrics.WeatherPrediction_WindSpeed, {
      title: 'Wind Speed',
      items: [
        {
          description: 'Value',
          value: weatherPredictions?.windSpeed?.value.toFixed(2),
          unit: 'm/s',
        },
      ],
    })

    this.details.set(BwcMetrics.WeatherPrediction_Humidity, {
      title: 'Relative Humidity',
      items: [
        {
          description: 'Value',
          value: weatherPredictions?.rh?.value.toString(),
          unit: '%',
        },
        {
          description: 'Lower bound',
          value: weatherPredictions?.rhLb?.value.toString(),
          unit: '%',
        },
        {
          description: 'Upper bound',
          value: weatherPredictions?.rhUb?.value.toString(),
          unit: '%',
        },
      ],
    })
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.BadWeatherCondition, {
      selectedFeatureId: undefined,
    })
  }
}
