import { Component, Inject, OnDestroy } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { LayerRoadSegmentService } from '../../../shared/layers/layer-road-segment.service'
import { LayerWeatherService } from '../../../shared/layers/layer-weather.service'

import { getMetricFromMap } from '../../enums/bwc-metrics.enum'

import { Subject, debounceTime, interval, takeUntil } from 'rxjs'

import { DataRoadSegmentService } from 'src/app/shared/services/data-road-segment.service'
import { DataWeatherPredictionService } from 'src/app/shared/services/data-weather-prediction.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import {
  vehicleFogLayerProvider,
  vehicleHRLayerProvider,
} from 'src/app/shared/providers/layer-service-provider'
import { LayerRawEventsService } from 'src/app/shared/layers/layer-raw-events.service'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { LayerWebcamService } from 'src/app/shared/layers/layer-static-webcams.service'
import { DataWebcamsService } from 'src/app/shared/services/data-webcams.service'

@Component({
  selector: 'app-bad-weather-condition-page',
  templateUrl: './bad-weather-condition-page.component.html',
  providers: [
    LayerRoadSegmentService,
    LayerWeatherService,
    LayerWebcamService,
    DataRoadSegmentService,
    DataWeatherPredictionService,
    DataWebcamsService,
    { provide: 'metrics', useValue: getMetricFromMap },
    vehicleHRLayerProvider,
    vehicleFogLayerProvider,
  ],
})
export class BadWeatherConditionPageComponent implements OnDestroy {
  private stopTimer = new Subject<void>()
  private cleanUp$ = new Subject<void>()
  public Permissions = Permissions
  public isWeatherPredictionLayerVisible = false
  public isRoadMetricLayerVisible = false
  public isVehicleDataFogLayerVisible = false
  public isVehicleDataRainLayerVisible = false
  public isLayerSelectionVisible = false
  public isWebcamLayerVisible = false
  public isInLiveMode = false
  private autoRefreshIntervalInMs = 0
  public layerIds = Layers
  public currentTimestamp: Date | undefined
  public currentTimespan: number | undefined
  public isHistoryVisible = false
  public isLegendVisible = false

  constructor(
    private mapService: MapService,
    private appState: AppStateService,
    private weatherLayerService: LayerWeatherService,
    private roadLayerService: LayerRoadSegmentService,
    private webcamsLayerService: LayerWebcamService,
    @Inject('vehicleHRLayer') public vehicleHRLayerService: LayerRawEventsService,
    @Inject('vehicleFogLayer') public vehicleFogLayerService: LayerRawEventsService,
  ) {
    this.mapService.activateGeoLocationSearch()
    this.mapService.setPosition(
      this.appState.getState().lat,
      this.appState.getState().lon,
      this.appState.getState().zoom,
    )
    if (this.appState.getState().historyEnabled) {
      this.isHistoryVisible = true
    }
    this.updateQueryParameters()
    this.mapService.mapViewChanged
      .pipe(takeUntil(this.cleanUp$))
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateQueryParameters()
      })

    this.evaluateQueryParameters()
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe(() => {
      this.evaluateQueryParameters()
    })
  }

  ngOnDestroy() {
    this.mapService.deactivateGeoLocationSearch()
    this.cleanUp$.next()
    this.cleanUp$.complete()
    this.stopTimer.next()
    this.stopTimer.complete()
  }

  startLiveMode() {
    this.isInLiveMode = true
    interval(this.autoRefreshIntervalInMs)
      .pipe(takeUntil(this.stopTimer))
      .subscribe(() => {
        this.roadLayerService.reload()
        this.weatherLayerService.reload()
        this.vehicleFogLayerService.reload()
        this.vehicleHRLayerService.reload()
      })
  }

  stopLiveMode() {
    this.isInLiveMode = false
    this.stopTimer.next()
  }

  toggleHistory = () => {
    this.isHistoryVisible = !this.isHistoryVisible
    this.updateQueryParameters()
  }

  toggleLegend = () => {
    this.isLegendVisible = !this.isLegendVisible
  }

  toggleWebcamLayer = () => {
    this.isWebcamLayerVisible = !this.isWebcamLayerVisible
    const layers = this.appState
      .selectLayers()
      .filter((layer) => !layer.includes(Layers.Webcams.name))

    if (this.isWebcamLayerVisible) {
      layers.push(Layers.Webcams.name)
    }

    this.appState.setState(AppPaths.BadWeatherCondition, {
      layers: layers?.toString(),
    })
  }

  handleHistoryClosed = () => {
    this.isHistoryVisible = false
    this.updateQueryParameters()
  }

  setLiveModeRefreshInterval(seconds: number) {
    this.autoRefreshIntervalInMs = seconds * 1000
    this.stopLiveMode()
    if (this.autoRefreshIntervalInMs > 0) {
      this.startLiveMode()
    }
  }

  evaluateQueryParameters() {
    if (this.appState.getState().historyEnabled) {
      this.isHistoryVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.RoadSegment.name)) {
      this.isRoadMetricLayerVisible = true
    }

    if (this.appState.getState().layers?.includes(Layers.WeatherPrediction.name)) {
      this.isWeatherPredictionLayerVisible = true
    }

    if (this.appState.getState().layers?.includes(Layers.VehicleDataFog.name)) {
      this.isVehicleDataFogLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.VehicleDataRain.name)) {
      this.isVehicleDataRainLayerVisible = true
    }

    if (this.appState.getState().layers?.includes(Layers.Webcams.name)) {
      this.isWebcamLayerVisible = true
    }
  }

  updateQueryParameters() {
    this.appState.setState(AppPaths.BadWeatherCondition, {
      lat: this.mapService.map.getCenter().lat,
      lon: this.mapService.map.getCenter().lng,
      zoom: this.mapService.map.getZoom(),
      historyEnabled: this.isHistoryVisible ? true : undefined,
    })
  }
}
