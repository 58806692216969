import { Injectable } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import { CircleLayerSpecification, MapGeoJSONFeature, SymbolLayerSpecification } from 'maplibre-gl'
import { Subject, debounceTime, takeUntil } from 'rxjs'
import { LayerBaseServiceModel } from 'src/app/core/models'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from '../layers-config/layers'
import { MapIcons } from 'src/app/core/enums/map-icons.enum'
import { emptyGeoJsonSource } from 'src/app/core/utils/source-utils'
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DataWebcamsService } from '../services/data-webcams.service'

@Injectable()
export class LayerWebcamService implements LayerBaseServiceModel {
  public sourceId = 'static-webcam-source'
  public isLayerVisible = false
  private cleanUp$ = new Subject<void>()
  private minZoom = 4
  private clusterMaxZoom = 11
  private clusterRadius = 50
  public layerId = Layers.Webcams.name
  private layerCircleClusterId = `${Layers.Webcams.name}-cluster`
  private layerSymbolClusterCountId = `${Layers.Webcams.name}-cluster-count`
  private webcams = {}

  private staticWebcamsClusterDataLayerSpecification: CircleLayerSpecification = {
    id: this.layerCircleClusterId,
    source: this.sourceId,
    minzoom: this.minZoom,
    type: 'circle',
    filter: ['has', 'point_count'],
    layout: {
      visibility: 'none',
    },
    paint: {
      'circle-stroke-color': '#db4fd2',
      'circle-stroke-width': 2,
      'circle-color': '#9E2896',
      'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 10, 22, 20, 25],
    },
  }

  private staticWebcamsClusterCountDataLayerSpecification: SymbolLayerSpecification = {
    id: this.layerSymbolClusterCountId,
    source: this.sourceId,
    minzoom: this.minZoom,

    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      visibility: 'none',
      'text-field': ['get', 'point_count'],
      'text-allow-overlap': true,
      'text-size': ['step', ['get', 'point_count'], 10, 5, 15, 20, 20],
    },
    paint: {},
  }

  private staticWebcamDataLayerSpecification: SymbolLayerSpecification = {
    id: this.layerId,
    source: this.sourceId,
    minzoom: this.minZoom,
    filter: ['!', ['has', 'point_count']],
    type: 'symbol',
    layout: {
      visibility: 'none',
      'icon-size': 1,
      'icon-anchor': 'center',
      'icon-allow-overlap': true,
      'icon-image': MapIcons.WC,
    },
    paint: {},
  }

  constructor(
    private mapService: MapService,
    private mapEventsService: MapEventsService,
    private dataWebcamsService: DataWebcamsService,
    private appState: AppStateService,
  ) {
    this.mapEventsService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        const coordinates =
          features[0].geometry.type === 'Point' ? features[0].geometry.coordinates : null

        if (features[0].layer.id === Layers.Webcams.name && coordinates) {
          window.open(
            `https://www.windy.com/webcams/${features[0].properties['webcamId']}?${coordinates[0]},${coordinates[1]},14`,
            '_blank',
          )
        }
      })
    if (mapService.isMapReady) {
      this.initializeLayer()
    } else {
      this.mapService.mapReadyChanged.subscribe(() => {
        this.initializeLayer()
      })
    }
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe((state) => {
      this.evaluateState()
    })
  }

  ngOnDestroy() {
    this.cleanUp$.next()
    this.mapEventsService.detachMapEvents(this)
    this.mapService.map?.removeLayer(this.layerId)
    this.mapService.map?.removeLayer(this.layerCircleClusterId)
    this.mapService.map?.removeLayer(this.layerSymbolClusterCountId)
    this.mapService.map?.removeSource(this.sourceId)
  }
  private async initializeLayer() {
    await this.dataWebcamsService.loadWebcamsJson().then(async (response) => {
      this.webcams = response
    })
    this.mapService.map.addSource(this.sourceId, {
      ...emptyGeoJsonSource,
      promoteId: 'webcamId',
      cluster: true,
      clusterMaxZoom: this.clusterMaxZoom, // Max zoom to cluster points on
      clusterRadius: this.clusterRadius,
    })

    this.mapService.map.addLayer(this.staticWebcamDataLayerSpecification)
    this.mapService.map?.addLayer(this.staticWebcamsClusterDataLayerSpecification)
    this.mapService.map?.addLayer(this.staticWebcamsClusterCountDataLayerSpecification)
    this.mapEventsService.registerForMapEvents(this, true)

    this.mapService.setGeoJsonData(
      this.sourceId,
      this.webcams as FeatureCollection<Geometry, GeoJsonProperties>,
    )

    this.evaluateState()
  }

  private evaluateState() {
    if (this.appState.getState().layers?.includes(Layers.Webcams.name)) {
      this.setVisibility(true)
    } else {
      this.setVisibility(false)
    }
    // if layer is not visible stop all events
    if (this.isLayerVisible === false) return
  }

  setVisibility(isVisible: boolean): void {
    this.isLayerVisible = isVisible
    if (this.mapService.isMapReady) {
      if (this.mapService.getLayerVisibilty(this.layerId) != this.isLayerVisible) {
        this.mapService.setLayerVisibility(this.layerId, isVisible)
        this.mapService.setLayerVisibility(this.layerCircleClusterId, isVisible)
        this.mapService.setLayerVisibility(this.layerSymbolClusterCountId, isVisible)
      }
    }
  }
}
