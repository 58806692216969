import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { RoadCondition, RoadSegmentValues } from '../../../shared/models/road-segment.model'

type HighestRoadCondition = {
  description: string
  value: number | undefined
}
@Component({
  selector: 'app-mobile-details',
  templateUrl: './mobile-details.component.html',
  styleUrls: ['./mobile-details.component.scss'],
})
export class MobileDetailsComponent implements OnChanges {
  public roadSegment: RoadSegmentValues | undefined
  public highestRoadCondtion: HighestRoadCondition = {
    description: 'Cond',
    value: undefined,
  }
  public isCollapsed = true
  public index = 0
  public feature: MapGeoJSONFeature | undefined

  @Input() features: MapGeoJSONFeature[] = []

  constructor() {}
  ngOnChanges(): void {
    this.feature = this.features[this.index]
    this.roadSegment = this.feature?.state as RoadSegmentValues
    if (this.roadSegment?.roadCondition) {
      this.setHighestRoadCondition(this.roadSegment.roadCondition)
    }
  }

  setHighestRoadCondition(roadCondition: RoadCondition) {
    let highestValue = 0
    let highestCondition = ''
    Object.keys(roadCondition).forEach((e) => {
      const value = (roadCondition as any)[e]
      if (value >= highestValue) {
        highestValue = value
        highestCondition = e
      }
    })
    this.highestRoadCondtion = {
      description: highestCondition,
      value: highestValue,
    }
  }

  toggleInsights() {
    this.isCollapsed = !this.isCollapsed
  }
}
