import { Component, Inject, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from '../../models'
import { Permissions } from '../../enums/permissions.enum'

@Component({
  selector: 'app-app-selection',
  templateUrl: './app-selection.component.html',
  styleUrls: ['./app-selection.component.scss'],
})
export class AppSelectionComponent implements OnInit {
  Permissions = Permissions
  isContentVisible: boolean = false
  currentApp: string = ''
  isRCSEnabled: boolean = false
  isBWCEnabled: boolean = false
  isMobileEnabled: boolean = false
  isObstacleEnabled: boolean = false
  isCustomerEnabled: boolean = false
  isSimulationEnabled: boolean = false

  constructor(
    private route: Router,
    @Inject(APP_CONFIG) public readonly config: AppConfigModel,
  ) {}

  ngOnInit(): void {
    this.route.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      const routerEvent: NavigationEnd = event as any as NavigationEnd
      this.isRCSEnabled =
        routerEvent.url.includes('road-condition') ||
        routerEvent.urlAfterRedirects.includes('road-condition')
          ? true
          : false
      this.isBWCEnabled =
        routerEvent.url.includes('bad-weather-condition') ||
        routerEvent.urlAfterRedirects.includes('bad-weather-condition')
          ? true
          : false
      this.isMobileEnabled =
        routerEvent.url.includes('mobile') || routerEvent.urlAfterRedirects.includes('mobile')
          ? true
          : false
      this.isObstacleEnabled =
        routerEvent.url.includes('obstacle') || routerEvent.urlAfterRedirects.includes('obstacle')
          ? true
          : false
      this.isCustomerEnabled =
        routerEvent.url.includes('customer') || routerEvent.urlAfterRedirects.includes('customer')
          ? true
          : false
      this.isSimulationEnabled =
        routerEvent.url.includes('simulation') ||
        routerEvent.urlAfterRedirects.includes('simulation')
          ? true
          : false
    })
  }

  toggleContent() {
    this.isContentVisible = !this.isContentVisible
  }

  hideContent() {
    this.isContentVisible = false
  }
}
