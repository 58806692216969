import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, tap } from 'rxjs'
import { AppStateService } from './services/app-state.service'
import { AppPaths } from '../app-routing.module'
import { AppConfigModel } from './models/app-config.model'
import { APP_CONFIG } from '../app.config'

const apisToEvaluate: string[] = ['/historic']

// There are a lot of missing pieces here, so bear with me for now
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = []
  constructor(
    private appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {}
  // This is the code that will run whenever an HttpRequest is made
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.checkForLoadingState(req.url)) {
      this.appState.isLoadingChanged$.next(true)
      this.requests.push(req)

      return next.handle(req).pipe(
        tap((event) => {
          if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
            this.removeRequest(req)
          }
        }),
      )
    } else {
      return next.handle(req).pipe(
        tap((event) => {
          if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
            if (
              event.url &&
              !event.url.includes(AppPaths.Maintenance) &&
              event.url.includes(this.config.backend_url) &&
              event.ok
            ) {
              this.appState.hasLastBackendCallChanged$.next(Date.now())
            }
          }
          return event
        }),
      )
    }
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req)
    if (i >= 0) {
      this.requests.splice(i, 1)
    }
    this.appState.isLoadingChanged$.next(this.requests.length > 0)
  }

  checkForLoadingState(url: string): boolean {
    for (const subPath of apisToEvaluate) {
      if (url.includes(subPath)) {
        return true
      }
    }
    return false
  }
}
