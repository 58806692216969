export function calculatePercentageValues(
  percentageValues: Array<number>,
  numberOfDesiredDecimalPlaces: number,
) {
  // the following approach was followed: https://stackoverflow.com/a/13483710 (Largest Remainder Method)
  let decimals: Array<number> = []
  let remainder = Math.pow(10, 2 + numberOfDesiredDecimalPlaces)

  // convert incoming values to cut unnecessary decimals (otherwise causes miscalculations due of floating point representation)
  percentageValues = percentageValues.map((item) =>
    Math.round(item * Math.pow(10, numberOfDesiredDecimalPlaces + 1)),
  )

  // get decimal part that should be cut
  // calculate difference between the expected sum and the sum of rounded down values
  percentageValues.forEach(function (value) {
    decimals.push(value % 10)
    remainder -= Math.floor(value / 10)
  })

  // mark items that should be rounded up (values with the largest remainder)
  // decrease difference between expected and actual sum
  while (remainder > 0) {
    decimals[decimals.indexOf(Math.max(...decimals))] = -1
    remainder--
  }

  // define final values: marked values (-1) are rounded up, other down
  // convert values to desired decimals
  for (let index = 0; index < decimals.length; index++) {
    percentageValues[index] =
      decimals[index] == -1
        ? Math.ceil(percentageValues[index] / 10) / Math.pow(10, numberOfDesiredDecimalPlaces)
        : Math.floor(percentageValues[index] / 10) / Math.pow(10, numberOfDesiredDecimalPlaces)
  }
  return percentageValues
}
