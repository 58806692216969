import { Inject, Injectable } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import {
  VectorSourceSpecification,
  FillLayerSpecification,
  ExpressionSpecification,
} from 'maplibre-gl'
import { DataWeatherPredictionService } from '../services/data-weather-prediction.service'
import { Subject, takeUntil, debounceTime } from 'rxjs'
import { LayerBaseServiceModel } from 'src/app/core/models'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { AppConfigModel } from 'src/app/core/models'
import { APP_CONFIG } from 'src/app/app.config'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from '../layers-config/layers'
import { MetricModel } from '../models/metric.model'
import { addMinutes } from 'date-fns'

@Injectable()
export class LayerWeatherService implements LayerBaseServiceModel {
  public sourceId = 'rcs-static-weather-source'
  public layerId = Layers.WeatherPrediction.name
  public sourceLayerName = 'voronoi_layer'
  public isLayerVisible = false
  private cleanUp$ = new Subject<void>()
  private cancelRequests$ = new Subject<void>()
  private maxZoomAvailable = 8
  private minZoomAvailable = 6
  private minZoom = 6
  private colorExpression: ExpressionSpecification | string = '#a9a9a9'
  private timestamp: Date | undefined
  private timespan: number | undefined
  public selectedFeatureId: string = ''

  private vectorSource: VectorSourceSpecification = {
    type: 'vector',
    tiles: [`${this.config.road_condition.weather_tile_url}?key=${this.config.maptiler_key}`],
    maxzoom: this.maxZoomAvailable,
    minzoom: this.minZoomAvailable,
  }

  private getLayerSpecifictation = (): FillLayerSpecification => {
    return {
      id: this.layerId,
      minzoom: this.minZoom,
      type: 'fill',
      source: this.sourceId, // reference the data source
      'source-layer': this.sourceLayerName,
      paint: {
        'fill-color': this.colorExpression,
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.3,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          0.3,
          0.1,
        ],
        'fill-outline-color': 'rgb(105,105,105)',
      },
      layout: {
        visibility: 'none',
      },
    }
  }

  constructor(
    private mapService: MapService,
    private mapDataWeatherPredictionService: DataWeatherPredictionService,
    private mapEventsService: MapEventsService,
    private appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    @Inject('metrics')
    private getMetricFromMap: (metric: string) => MetricModel,
  ) {
    if (mapService.isMapReady) {
      this.initializeLayer()
    } else {
      this.mapService.mapReadyChanged.subscribe(() => {
        this.initializeLayer()
      })
    }
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe((state) => {
      this.evaluateState()
    })
  }

  ngOnDestroy() {
    this.cancelRequests()
    this.cleanUp$.next()
    this.mapEventsService.detachMapEvents(this)
    this.mapService.map?.removeLayer(this.layerId)
    this.mapService.map?.removeSource(this.sourceId)
  }

  private async initializeLayer() {
    this.mapService.map.addSource(this.sourceId, this.vectorSource)
    this.mapService.map.addLayer(this.getLayerSpecifictation())
    this.mapEventsService.registerForMapEvents(this, false)
    this.evaluateState()
  }

  public updateLayer() {
    if (this.mapService.isMapReady) {
      this.mapService.map.removeFeatureState({
        source: this.sourceId,
        sourceLayer: this.sourceLayerName,
      })

      const queryTimestamp =
        this.timestamp && this.timespan ? addMinutes(this.timestamp, this.timespan) : this.timestamp

      this.mapDataWeatherPredictionService
        .getWeatherPredictions(queryTimestamp)
        .forEach((weatherPredictions) => {
          for (let weatherPrediction of weatherPredictions) {
            this.mapService.map.setFeatureState(
              {
                source: this.sourceId,
                sourceLayer: this.sourceLayerName,
                id: weatherPrediction[0],
              },
              {
                airTemp: { value: weatherPrediction[1].airTemp },
                airTempLb: { value: weatherPrediction[1].airTempLb },
                airTempUb: { value: weatherPrediction[1].airTempUb },
                icyProb: { value: weatherPrediction[1].icyProb },
                prr: { value: weatherPrediction[1].prr },
                prrLb: { value: weatherPrediction[1].prrLb },
                prrUb: { value: weatherPrediction[1].prrUb },
                prs: { value: weatherPrediction[1].prs },
                prsLb: { value: weatherPrediction[1].prsLb },
                prsUb: { value: weatherPrediction[1].prsUb },
                rst: { value: weatherPrediction[1].rst },
                visibility: { value: weatherPrediction[1].visibility },
                wft: { value: weatherPrediction[1].wft },
                wftLb: { value: weatherPrediction[1].wftLb },
                wftUb: { value: weatherPrediction[1].wftUb },
                rh: { value: weatherPrediction[1].rh },
                rhLb: { value: weatherPrediction[1].rhLb },
                rhUb: { value: weatherPrediction[1].rhUb },
                windSpeed: { value: weatherPrediction[1].windSpeed },
                timestamp: weatherPrediction[1].timestamp,
              },
            )
          }
        })
    }
  }

  private evaluateState() {
    if (this.appState.getState().layers?.includes(Layers.WeatherPrediction.name)) {
      this.setVisibility(true)
    } else {
      this.setVisibility(false)
    }
    // if layer is not visible stop all events
    if (this.isLayerVisible === false) return

    if (!this.timestamp) this.timestamp = this.appState.selectTimestamp()
    if (!this.timespan) this.timespan = this.appState.getState().timespan

    if (this.appState.getState().metrics) {
      const weatherMetrics = this.appState.selectMetrics(Layers.WeatherPrediction.name)
      if (weatherMetrics) {
        this.setColorExpression(
          this.getMetricFromMap(weatherMetrics[0]).expression as ExpressionSpecification,
        )
      }
    }

    if (!this.mapDataWeatherPredictionService.isDataInitialized) {
      this.reload()
    }

    if (
      this.appState.hasLayerVisibilityChanged(Layers.WeatherPrediction.name) &&
      !this.appState.getState().historyEnabled
    ) {
      this.reload()
    }

    if (this.appState.hasMapBoundsChanged() && !this.appState.getState().historyEnabled) {
      this.reload()
    }

    if (this.appState.hasTimestampChanged()) {
      this.timestamp = this.appState.selectTimestamp()
      this.reload()
    }
    if (this.appState.hasSelectedFeatureIdChanged()) {
      this.selectedFeatureId = this.appState.getState().selectedFeatureId ?? ''
      this.mapService.map.setPaintProperty(this.layerId, 'fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.3,
        ['==', ['id'], Number(this.selectedFeatureId)],
        0.3,
        0.1,
      ])
    }

    if (this.appState.hasTimespanChanged()) {
      this.timespan = this.appState.state.timespan
      this.updateLayer()
    }
  }

  public async reload() {
    this.cancelRequests()
    if (this.mapService.isMapReady && this.isLayerVisible && !this.appState.isLoading) {
      const xyzTiles = this.mapService.getXyzTiles(this.maxZoomAvailable, this.minZoomAvailable)
      await this.mapDataWeatherPredictionService.loadWeatherPredictions(xyzTiles, this.timestamp)
    }
    this.updateLayer()
  }

  setVisibility(isVisible: boolean): void {
    this.isLayerVisible = isVisible
    if (this.mapService.isMapReady) {
      if (this.mapService.getLayerVisibilty(this.layerId) != this.isLayerVisible) {
        this.mapService.setLayerVisibility(this.layerId, isVisible)
        if (!isVisible) {
          this.cancelRequests()
        }
      }
    }
  }

  public setColorExpression(expression: ExpressionSpecification | string) {
    // set expression in case map is not ready and map ready event hast to set the color
    this.colorExpression = expression
    if (this.mapService.isMapReady) {
      this.mapService.map.setPaintProperty(this.layerId, 'fill-color', expression)
    }
  }

  private cancelRequests() {
    this.cancelRequests$.next()
    this.cancelRequests$.complete()
    this.cancelRequests$ = new Subject()
  }
}
