import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { MapService } from 'src/app/core/services/map.service'
import { WarningDatasources, WarningTypes } from '../enums/warning-types.enum'
import { LayerCustomerWarningService } from '../layers/layer-customer-warning.service'
import { DataCustomerWarningService } from '../services/data-customer-warning.service'
import { AuthService } from 'src/app/core/services/auth.service'
import { LayerSimulationWarningService } from '../layers/layer-simulation-warning.service'
import { DataSimulationWarningService } from '../services/data-simulation-warning.service'

// warning Layers

export const customerWarningLayerProvider = {
  provide: 'customerWarningLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    authService: AuthService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerCustomerWarningService => {
    return new LayerCustomerWarningService(
      router,
      mapService,
      mapEventsService,
      appState,
      authService,
      'customer',
      Object.values(WarningTypes),
      false,
      6,
      6,
      3,
      new DataCustomerWarningService(http, appState, config),
    )
  },
  deps: [
    Router,
    MapService,
    MapEventsService,
    AppStateService,
    AuthService,
    HttpClient,
    APP_CONFIG,
  ],
}

export const roadConditionWarningLayerProvider = {
  provide: 'roadConditionWarningLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    authService: AuthService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerCustomerWarningService => {
    return new LayerCustomerWarningService(
      router,
      mapService,
      mapEventsService,
      appState,
      authService,
      'roadCondition',
      [WarningTypes.TractionLoss],
      true,
      6,
      6,
      4,
      new DataCustomerWarningService(http, appState, config),
      WarningDatasources.Rcs,
    )
  },
  deps: [
    Router,
    MapService,
    MapEventsService,
    AppStateService,
    AuthService,
    HttpClient,
    APP_CONFIG,
  ],
}

export const obstacleWarningLayerProvider = {
  provide: 'obstacleWarningLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    authService: AuthService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerCustomerWarningService => {
    return new LayerCustomerWarningService(
      router,
      mapService,
      mapEventsService,
      appState,
      authService,
      'obstacle',
      [WarningTypes.BrokendownVehicle, WarningTypes.VehicleAccident, WarningTypes.ConstructionSite],
      false,
      6,
      6,
      4,
      new DataCustomerWarningService(http, appState, config),
      WarningDatasources.Rcs,
    )
  },
  deps: [
    Router,
    MapService,
    MapEventsService,
    AppStateService,
    AuthService,
    HttpClient,
    APP_CONFIG,
  ],
}

export const mobileWarningLayerProvider = {
  provide: 'mobileWarningLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    authService: AuthService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerCustomerWarningService => {
    return new LayerCustomerWarningService(
      router,
      mapService,
      mapEventsService,
      appState,
      authService,
      'mobile',
      [
        WarningTypes.BrokendownVehicle,
        WarningTypes.VehicleAccident,
        WarningTypes.TractionLoss,
        WarningTypes.LowVisibility,
      ],
      false,
      6,
      6,
      4,
      new DataCustomerWarningService(http, appState, config),
    )
  },
  deps: [
    Router,
    MapService,
    MapEventsService,
    AppStateService,
    AuthService,
    HttpClient,
    APP_CONFIG,
  ],
}
