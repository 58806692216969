<app-layer-category description="Warnings">
  <app-layer-control-item
    description="Accidents"
    [isLayerVisibile]="isVALayerVisible"
    [layerId]="Layers.VAWarning.name"
    icon="car-crash"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.VAWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="BDV"
    [isLayerVisibile]="isBDVLayerVisible"
    [layerId]="Layers.BDVWarning.name"
    icon="car-mechanic"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.BDVWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Traction Loss"
    [isLayerVisibile]="isTLLayerVisible"
    [layerId]="Layers.TLWarning.name"
    icon="wheel-vehicle"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.TLWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Construction Sites"
    [isLayerVisibile]="isCSLayerVisible"
    [layerId]="Layers.CSWarning.name"
    icon="construction"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.CSWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Low Visibility"
    [isLayerVisibile]="isLVLayerVisible"
    [layerId]="Layers.LVWarning.name"
    icon="weather-mist"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.LVWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Heavy Rain"
    [isLayerVisibile]="isHRLayerVisible"
    [layerId]="Layers.HRWarning.name"
    icon="weather-rain-warning"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.HRWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Heavy Snow"
    [isLayerVisibile]="isHSLayerVisible"
    [layerId]="Layers.HSWarning.name"
    icon="ice"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.HSWarning.permissions"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Strong Wind"
    [isLayerVisibile]="isSWLayerVisible"
    [layerId]="Layers.SWWarning.name"
    icon="wind-vane-moderate"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="Layers.SWWarning.permissions"
  ></app-layer-control-item>
</app-layer-category>
