import { Directive, ElementRef } from '@angular/core'
import { take } from 'rxjs'
import { DemoService } from 'src/app/core/services/demo.service'

@Directive({
  selector: '[isDemoEnabled]',
})
export class IsDemoEnabledDirective {
  constructor(
    private el: ElementRef,
    private demoService: DemoService,
  ) {
    this.demoService.addDemoModeSource.pipe().subscribe((demoMode) => {
      this.changeDemoMode(demoMode)
    })
  }

  changeDemoMode(enabled: string) {
    if (enabled === 'false') {
      let el = this.el.nativeElement
      el.classList.remove('blur2')
    } else {
      let el = this.el.nativeElement
      el.classList.add('blur2')
    }
  }
}
