import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { debounceTime } from 'rxjs'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { RcsMetrics } from '../../enums/rcs-metrics.enum'
import { WeatherPropertiesModel } from 'src/app/shared/models/weather-properties.model'
import { WeatherPredictions } from 'src/app/shared/models/weather.model'
import { format, getTime } from 'date-fns'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-rc-details-weather',
  templateUrl: './rc-details-weather.component.html',
})
export class RcDetailsWeatherComponent {
  Permissions = Permissions
  public Metrics = RcsMetrics
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public index: number = 0
  public selectedFeatureId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    private appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (features[this.index].layer.id === Layers.WeatherPrediction.name) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    const weather: WeatherPredictions = (this.features[this.index] as any).state
    this.selectedFeatureId = this.features[this.index].id?.toString() || ''
    this.rawFeature = JSON.stringify(this.features[this.index], undefined, 2)
    const properties: WeatherPropertiesModel = this.features[this.index]
      .properties as WeatherPropertiesModel
    this.mapWeatherToDetails(weather, properties)
    this.appState.setState(AppPaths.RoadCondition, {
      selectedFeatureId: this.selectedFeatureId,
    })
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.RoadCondition, {
      selectedFeatureId: undefined,
    })
  }

  mapWeatherToDetails(weather: WeatherPredictions, properties: WeatherPropertiesModel) {
    this.details.set('Meta', {
      title: 'Area',
      items: [
        {
          description: 'Segment ID',
          value: properties.SegmentId,
        },
        {
          description: 'Global ID',
          value: this.selectedFeatureId,
        },
        {
          description: `Last Updated (${getTimeZoneLabel(new Date(weather.timestamp))})`,
          value: weather.timestamp
            ? format(new Date(weather.timestamp), 'yyyy-MM-dd HH:mm:ss')
            : '-',
        },
      ],
    })

    this.details.set(RcsMetrics.WeatherPrediction_Wft, {
      title: 'Waterfilm Thickness',
      items: [
        {
          description: 'Value',
          value: weather.wft.value.toFixed(2),
          unit: 'mm',
        },
        {
          description: 'Lower bound',
          value: weather.wftLb.value.toFixed(2),
          unit: 'mm',
        },
        {
          description: 'Upper bound',
          value: weather.wftUb.value.toFixed(2),
          unit: 'mm',
        },
      ],
    })

    this.details.set('Condition', {
      title: 'Weather Condtion',
      items: [
        {
          description: 'Icy',
          value: Math.round(weather.icyProb.value * 100).toString(),
          unit: '%',
        },
        {
          description: 'Road Temperature',
          value: weather.rst.value.toFixed(2),
          unit: '°C',
        },
      ],
    })
  }
}
