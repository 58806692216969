<div class="position-relative d-flex justify-content-center min-wpx-40">
  <ng-container *ngIf="type == 'hazard'">
    <div
      class="bg-{{ value }} p-1 rounded-circle border border-{{ category }}-hazard border-2 "
    ></div>
    <span class="position-absolute translate-middle-x top-4 fs-2xs start-50 text-center">{{
      description
    }}</span>
  </ng-container>
  <ng-container *ngIf="type == 'prediction'">
    <div class="bg-{{ value }} hf-xs w-100 rounded-2 "></div>
    <span
      class="position-absolute translate-middle-x top-3 fs-2xs start-50 text-center text-nowrap"
      >{{ description }}</span
    >
  </ng-container>
</div>
