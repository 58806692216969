<div class="position-absolute bottom-7 start-20">
  <app-scale-control> </app-scale-control>
</div>
<div
  class="position-absolute translate-middle-x bottom-7 start-50 col"
  [class.d-none]="allowedFilters.length <= 1"
  data-cy="dropdown-warnings-menu"
>
  <div isDemoEnabled ngbDropdown placement="top" class="d-inline-block">
    <button
      type="button"
      class="btn btn-outline-primary z-3 shadow-sm rounded-2 bg-dark cursor-pointer border hover-effect border-darkgray text-deactivated"
      id="dropdownBasic1"
      ngbDropdownToggle
      data-cy="dropdown-warnings-toggle"
    >
      {{ this.currentFilter.split('.')[1] }}
    </button>
    <div
      isDemoEnabled
      ngbDropdownMenu
      class="z-3 shadow-sm rounded-2 bg-dark cursor-pointer border border-darkgray hover-effect text-deactivated"
      aria-labelledby="dropdownBasic1"
      dropdownWarningsToggle
    >
      <div *ngFor="let filter of allowedFilters">
        <button
          ngbDropdownItem
          class="z-3 shadow-sm rounded-2 bg-dark hover cursor-pointer hover-effect text-deactivated"
          (click)="setCurrentFilter(filter)"
          data-cy="dropdown-warnings-item"
        >
          {{ filter }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="position-absolute bottom-7 end-5 z-3 shadow-sm rounded-2 bg-dark cursor-pointer border border-darkgray d-flex gap-2 align-items-center text-deactivated"
>
  <app-auto-refresh
    (intervalSet)="setLiveModeRefreshInterval($event)"
    [isActive]="isInLiveMode"
  ></app-auto-refresh>
</div>
<app-map-navigation></app-map-navigation>
<app-customer-layer-config></app-customer-layer-config>
<app-customer-warnings-details></app-customer-warnings-details>
<app-last-backend-call></app-last-backend-call>
