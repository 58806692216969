import { Component } from '@angular/core'
import { AuthService } from './core/services/auth.service'
import { DemoService } from './core/services/demo.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'RCS-Maps'

  constructor(
    private authService: AuthService,
    private demoService: DemoService,
  ) {}
}
