<div class="flex-fill">
  <span
    class="d-flex flex-column align-items-center justify-content-center rounded-2 ho-layer-item"
    [class.sel-menu-item]="isLayerVisibile === true"
    (click)="visibilityToggled.emit(layerId)"
    data-cy="layer-button"
  >
    <span class="fs-2xl bg-darkgray p-2 rounded-2" [ngClass]="getIcon()"></span>
    <div class="position-relative mb-5">
      <span class="position-absolute translate-middle-x top-1 fs-2xs text-center text-wrap">{{
        description
      }}</span>
    </div>
  </span>
</div>
