import { Component, EventEmitter, Output, Input } from '@angular/core'
import { addMinutes, format } from 'date-fns'

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
})
export class RangePickerComponent {
  @Input() timestamp: Date | undefined
  @Input() timespan: number | undefined = 0
  @Input() min: string | undefined = '-30'
  @Input() max: string | undefined = '30'
  @Input() step: string | undefined = '15'
  @Input() disabled = true

  @Output() timeSpanToggled = new EventEmitter<string>()

  calculateTimeStamp(step: string) {
    return this.timestamp ? format(addMinutes(this.timestamp, Number(step)), 'HH:mm') : undefined
  }
}
