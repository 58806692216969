<div
  class="position-absolute bottom-20 end-5 z-1"
  *ngIf="
    isOneWarningLayerVisible() || isOneVehicleDataLayerVisible() || isOneProviderLayerVisible()
  "
>
  <app-legend>
    <app-legend-category
      *ngIf="isOneWarningLayerVisible()"
      name="Road Hazard"
      [metric]="obstacleMetric"
    ></app-legend-category>
    <app-legend-category
      *ngIf="isOneProviderLayerVisible()"
      name="Provider"
      [metric]="providerMetric"
    ></app-legend-category>
    <app-legend-category
      *ngIf="isOneVehicleDataLayerVisible()"
      name="Standard Vehicle"
      [metric]="vehicleDataMetric"
    ></app-legend-category>
  </app-legend>
</div>
