import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
})
export class ButtonIconComponent {
  @Input() isSelected: boolean | undefined
  @Input() key: string | undefined
  @Input() description: string | undefined
  @Input() icon: string | undefined
  @Input() rotateIcon: string = '0'

  @Output() clicked = new EventEmitter<string>()

  public getIcon(): string {
    if (this.icon) {
      return `rb-ic rb-ic-${this.icon}`
    }
    return ''
  }
}
