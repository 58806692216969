<app-layer-category class="border-bottom border-darkgray" description="Road Hazards">
  <ng-container *hasPermissionAny="[Permissions.Weather_All_Read]">
    <app-layer-config-item
      *ngVar="getMetricFromMap(metrics.RoadSegment_HeavyRain) as metric"
      [description]="metric.displayName"
      [isSelected]="selectedRoadMetric === metric.key"
      [key]="metric.key"
      [icon]="metric.icon"
      (clicked)="setMetric($event)"
    ></app-layer-config-item>
  </ng-container>
  <ng-container *hasPermissionAny="[Permissions.Weather_All_Read]">
    <app-layer-config-item
      *ngVar="getMetricFromMap(metrics.RoadSegment_HeavySnow) as metric"
      [description]="metric.displayName"
      [isSelected]="selectedRoadMetric === metric.key"
      [key]="metric.key"
      [icon]="metric.icon"
      (clicked)="setMetric($event)"
    ></app-layer-config-item>
  </ng-container>
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.RoadSegment_LowVisibility) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedRoadMetric === metric.key"
    [key]="metric.key"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  ></app-layer-config-item>
  <ng-container *hasPermissionAny="[Permissions.Weather_All_Read]">
    <app-layer-config-item
      *ngVar="getMetricFromMap(metrics.RoadSegment_StrongWind) as metric"
      [description]="metric.displayName"
      [isSelected]="selectedRoadMetric === metric.key"
      [key]="metric.key"
      [icon]="metric.icon"
      (clicked)="setMetric($event)"
    ></app-layer-config-item>
  </ng-container>
</app-layer-category>
