<app-layer-category class="border-bottom border-darkgray" description="Road Hazards">
  <app-layer-control-item
    description="Accidents"
    [isLayerVisibile]="isVAWarningLayerVisible"
    [layerId]="layerIds.VAWarning.name"
    icon="car-crash"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="BDV"
    [isLayerVisibile]="isBDVWarningVisible"
    [layerId]="layerIds.BDVWarning.name"
    icon="car-mechanic"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="CS"
    [isLayerVisibile]="isCSWarningVisible"
    [layerId]="layerIds.CSWarning.name"
    icon="construction"
    (visibilityToggled)="toggleLayerVisbility($event)"
    *hasPermissionAny="layerIds.CSWarning.permissions"
  ></app-layer-control-item>
</app-layer-category>
