import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { subMonths } from 'date-fns'
import { Subject, debounceTime, takeUntil } from 'rxjs'
import { getTimeZoneLabel } from '../../utils/date.utils'

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit, OnDestroy, OnChanges {
  @Output() datetimeChanged = new EventEmitter<Date>()
  datetimeChanged$ = new Subject<void>()
  cleanUp$ = new Subject<void>()
  minDate = subMonths(new Date(Date.now()), 1)
  @Input() datetime: string | undefined
  @Input() disabled = false
  public timezone: string | undefined

  ngOnInit(): void {
    if (this.datetime) this.timezone = getTimeZoneLabel(new Date(this.datetime))
    this.datetimeChanged$.pipe(debounceTime(500), takeUntil(this.cleanUp$)).subscribe(() => {
      if (this.datetime) {
        this.timezone = getTimeZoneLabel(new Date(this.datetime))
        this.datetimeChanged.emit(new Date(this.datetime))
      }
    })
  }

  ngOnDestroy(): void {
    this.cleanUp$.next()
    this.cleanUp$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onChange(time: any) {
    this.datetime = time
    this.datetimeChanged$.next()
  }
}
