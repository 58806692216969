export enum HazardTypes {
  GeneralWarning = 'GW',
  HazardLight = 'HL',
  Fog = 'FOG',
  HeavyRain = 'HR',
  BrokendownVehicle = 'BDV',
  VehicleAccident = 'VA',
  SlipperyRoad = 'SR',
  EmergencyBraking = 'EB',
  CrossWind = 'CW',
}
