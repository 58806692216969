import { Component } from '@angular/core'
import { format } from 'date-fns'
import { debounceTime, throttleTime } from 'rxjs'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { getTimeZoneLabel } from '../../utils/date.utils'

@Component({
  selector: 'app-last-backend-call',
  templateUrl: './last-backend-call.component.html',
  styleUrls: ['./last-backend-call.component.scss'],
})
export class LastBackendCallComponent {
  public timezone = getTimeZoneLabel(new Date())

  public lastBackendCall: string = `${format(new Date().toISOString(), 'HH:mm:ss')} (${this.timezone})`

  constructor(private appState: AppStateService) {
    this.appState.hasLastBackendCallChanged$
      .pipe(debounceTime(500))
      .pipe(takeUntilDestroyed())
      .subscribe((lastBackendCall) => {
        this.timezone = getTimeZoneLabel(new Date(lastBackendCall))
        this.lastBackendCall = `${format(new Date(lastBackendCall), 'HH:mm:ss')} (${this.timezone})`
      })
  }
}
