<div
  class="d-flex flex-column align-items-center justify-content-center p-2 text-gray hover-effect cursor-pointer bg-dark border border-darkgray z-1 shadow-sm rounded-2"
  (click)="visibilityToggled.emit()"
  placement="end"
  ngbTooltip="Open layer selection"
  tooltipClass="custom-tooltip"
  [openDelay]="1000"
  data-cy="layer-button"
>
  <span class="rb-ic rb-ic-layers-stack fs-3xl"></span>
</div>
