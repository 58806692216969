<app-layer-category class="border-bottom border-darkgray" description="Weather Predictions">
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_Wft) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_Wft"
    [key]="metrics.WeatherPrediction_Wft"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  >
  </app-layer-config-item>
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_Icy) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_Icy"
    [key]="metrics.WeatherPrediction_Icy"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  >
  </app-layer-config-item>
</app-layer-category>
