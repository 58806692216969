<div class="position-relative z-3" (clickOutside)="hideContent()">
  <a
    type="button"
    class="d-flex justify-content-center p-2 pt-0 text-white position-relative"
    (click)="toggleContent()"
    placement="start"
    ngbTooltip="Open app selection"
    tooltipClass="custom-tooltip"
    [openDelay]="1000"
    data-cy="app-selection"
  >
    <span class="rb-ic rb-ic-grid-view fs-2xl hover-effect"></span>
  </a>
  <div
    *ngIf="isContentVisible"
    class="p-4 bg-dark rounded-2 position-absolute end-0 row g-0 border border-darkgray text-darkgray min-vw-30"
  >
    <div class="col" *hasPermissionAny="[Permissions.Apps_Rc_Access, Permissions.Apps_All_Access]">
      <a
        class="d-flex flex-column align-items-center justify-content-center ho-menu-item p-2 rounded-2 text-reset"
        [class.sel-menu-item]="isRCSEnabled"
        [routerLink]="isRCSEnabled ? undefined : '/road-condition'"
        data-cy="roadConditionView"
      >
        <span class="rb-ic rb-ic-weather-cloud-sun-rain-snow fs-2xl"></span>
        <span class="fs-xs text-center text-decoration-none">Road Condition</span>
      </a>
    </div>
    <div class="col" *hasPermissionAny="[Permissions.Apps_Bwc_Access, Permissions.Apps_All_Access]">
      <a
        class="d-flex flex-column align-items-center justify-content-center ho-menu-item p-2 rounded-2 text-reset"
        [class.sel-menu-item]="isBWCEnabled"
        [routerLink]="isBWCEnabled ? undefined : '/bad-weather-condition'"
        data-cy="badWeatherConditionView"
      >
        <span class="rb-ic rb-ic-weather-cloud-sun-rain-snow fs-2xl"></span>
        <span class="fs-xs text-center text-decoration-none">BWC</span>
      </a>
    </div>
    <div
      class="col"
      *hasPermissionAny="[Permissions.Apps_Mobile_Access, Permissions.Apps_All_Access]"
    >
      <a
        class="d-flex flex-column align-items-center justify-content-center ho-menu-item p-2 rounded-2 text-reset"
        [class.sel-menu-item]="isMobileEnabled"
        [routerLink]="isMobileEnabled ? undefined : '/mobile'"
        data-cy="mobileView"
      >
        <span class="rb-ic rb-ic-car-smartphone-locator fs-2xl"></span>
        <span class="fs-xs text-center text-decoration-none">Mobile View</span>
      </a>
    </div>
    <div
      class="col align-self-stretch"
      *hasPermissionAny="[Permissions.Apps_Obstacle_Access, Permissions.Apps_All_Access]"
    >
      <a
        class="d-flex flex-column align-items-center justify-content-center ho-menu-item p-2 rounded-2 text-reset"
        [class.sel-menu-item]="isObstacleEnabled"
        [routerLink]="isObstacleEnabled ? undefined : '/obstacle'"
        data-cy="obstacleView"
      >
        <span class="rb-ic rb-ic-traffic-cone fs-2xl"></span>
        <span class="fs-xs text-center text-decoration-none">Obstacle</span>
      </a>
    </div>
    <div
      class="col align-self-stretch"
      *hasPermissionAny="[Permissions.Apps_Customer_Access, Permissions.Apps_All_Access]"
    >
      <a
        class="d-flex flex-column align-items-center justify-content-center ho-menu-item p-2 rounded-2 text-reset"
        [class.sel-menu-item]="isCustomerEnabled"
        [routerLink]="isCustomerEnabled ? undefined : '/customer'"
        data-cy="customerView"
      >
        <span class="rb-ic rb-ic-customer fs-2xl"></span>
        <span class="fs-xs text-center text-decoration-none">Customer</span>
      </a>
    </div>
    <div
      class="col align-self-stretch"
      *hasPermissionAny="[Permissions.Apps_Simulation_Access, Permissions.Apps_All_Access]"
    >
      <a
        class="d-flex flex-column align-items-center justify-content-center ho-menu-item p-2 rounded-2 text-reset"
        [class.sel-menu-item]="isSimulationEnabled"
        [routerLink]="isSimulationEnabled ? undefined : '/simulation'"
        data-cy="simulationView"
      >
        <span class="rb-ic rb-ic-plan-floorplan-edit fs-2xl"></span>
        <span class="fs-xs text-center text-decoration-none">Simulation</span>
      </a>
    </div>
  </div>
</div>
