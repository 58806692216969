import { Component } from '@angular/core'
import { BwcMetrics, getMetricFromMap } from '../../../enums/bwc-metrics.enum'
import { Layers } from 'src/app/shared//layers-config/layers'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { Permissions } from 'src/app/core/enums/permissions.enum'

@Component({
  selector: 'app-bwc-road-segment',
  templateUrl: './bwc-road-segment.component.html',
  styleUrls: ['./bwc-road-segment.component.scss'],
})
export class BwcRoadSegmentComponent {
  public layerIds = Layers
  public metrics = BwcMetrics
  public getMetricFromMap = getMetricFromMap
  public selectedRoadMetric: string = ''
  public isRoadSegmentLayerVisible: boolean | undefined
  public defaultMetric = BwcMetrics.RoadSegment_LowVisibility
  public Permissions = Permissions

  constructor(private appState: AppStateService) {
    const layers = this.appState.getState().layers
    if (layers) {
      this.isRoadSegmentLayerVisible = layers?.includes(Layers.RoadSegment.name)
    } else {
      this.isRoadSegmentLayerVisible = true
      this.selectedRoadMetric = this.defaultMetric
    }

    const roadMetrics = this.appState.selectMetrics(Layers.RoadSegment.name)
    if (roadMetrics && roadMetrics.length > 0) {
      this.selectedRoadMetric = roadMetrics[0] as BwcMetrics
    }

    this.updateQueryParameters()
  }

  setMetric(metric: string) {
    this.selectedRoadMetric = this.selectedRoadMetric == metric ? '' : metric
    this.isRoadSegmentLayerVisible = this.selectedRoadMetric === '' ? false : true
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    const layers = this.appState.selectLayers(Layers.RoadSegment.name, false)
    if (this.isRoadSegmentLayerVisible) {
      layers?.push(Layers.RoadSegment.name)
    }

    const metrics = this.appState.selectMetrics(Layers.RoadSegment.name, false)
    if (this.selectedRoadMetric) {
      metrics.push(this.selectedRoadMetric)
    }

    this.appState.setState(AppPaths.BadWeatherCondition, {
      layers: layers?.toString(),
      metrics: metrics?.toString(),
    })
  }
}
