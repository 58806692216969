import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { DetailsModel } from '../../../models/details.model'

@Component({
  selector: 'app-details',
  templateUrl: './details-base.component.html',
})
export class DetailsComponent {
  @Input() numberOfDetails: number = 0
  @Input() index: number = 0

  @Output() closed = new EventEmitter<void>()
  @Output() selectedItemChanged = new EventEmitter<number>()
}
