import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { RoadConditionPageComponent } from './road-condition/pages/road-condition-page/road-condition-page.component'
import { NotFoundComponent } from './core/pages/not-found/not-found.component'
import { BrowserUtils } from '@azure/msal-browser'
import { MsalGuard } from '@azure/msal-angular'
import { MobilePageComponent } from './road-condition/pages/mobile-page/mobile-page.component'
import { BadWeatherConditionPageComponent } from './bad-weather-condition/pages/bad-weather-condition-page/bad-weather-condition-page.component'
import { ObstaclePageComponent } from './obstacle/obstacle-page/obstacle-page.component'
import { AuthGuard } from './core/services/auth-guard.service'
import {
  MaintenanceEnabledGuard,
  MaintenanceDisabledGuard,
} from './core/services/maintenance-guard.service'
import { CustomerPageComponent } from './customer/customer-page/customer-page.component'
import { SimulationPageComponent } from './simulation/simulation-page/simulation-page.component'
import { MaintenancePageComponent } from './core/pages/maintenance/maintenance/maintenance.component'
import { Permissions } from './core/enums/permissions.enum'

export enum AppPaths {
  RoadCondition = 'road-condition',
  BadWeatherCondition = 'bad-weather-condition',
  Mobile = 'mobile',
  Obstacle = 'obstacle',
  Customer = 'customer',
  Simulation = 'simulation',
  Maintenance = 'maintenance',
}

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard],
    children: [
      {
        path: AppPaths.RoadCondition,
        component: RoadConditionPageComponent,
        canActivate: [MaintenanceEnabledGuard],
      },
      {
        path: AppPaths.Mobile,
        component: MobilePageComponent,
        canActivate: [AuthGuard, MaintenanceEnabledGuard],
        data: {
          requiredPermissions: [Permissions.Apps_Mobile_Access, Permissions.Apps_All_Access],
        },
      },
      {
        path: AppPaths.BadWeatherCondition,
        component: BadWeatherConditionPageComponent,
        canActivate: [AuthGuard, MaintenanceEnabledGuard],
        data: { requiredPermissions: [Permissions.Apps_Bwc_Access, Permissions.Apps_All_Access] },
      },
      {
        path: AppPaths.Obstacle,
        component: ObstaclePageComponent,
        canActivate: [AuthGuard, MaintenanceEnabledGuard],
        data: {
          requiredPermissions: [Permissions.Apps_Obstacle_Access, Permissions.Apps_All_Access],
        },
      },
      {
        path: AppPaths.Customer,
        component: CustomerPageComponent,
        canActivate: [AuthGuard, MaintenanceEnabledGuard],
        data: {
          requiredPermissions: [Permissions.Apps_Customer_Access, Permissions.Apps_All_Access],
        },
      },
      {
        path: AppPaths.Simulation,
        component: SimulationPageComponent,
        canActivate: [AuthGuard, MaintenanceEnabledGuard],
        data: {
          requiredPermissions: [Permissions.Apps_Simulation_Access, Permissions.Apps_All_Access],
        },
      },
      {
        path: AppPaths.Maintenance,
        component: MaintenancePageComponent,
        canActivate: [MaintenanceDisabledGuard],
      },
      { path: '', redirectTo: AppPaths.RoadCondition, pathMatch: 'full' },
      { path: '**', redirectTo: AppPaths.RoadCondition, pathMatch: 'full' },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
