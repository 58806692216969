<div
  class="position-absolute history translate-middle-x start-50 bg-dark border border-darkgray rounded-2"
>
  <div class="position-relative d-flex align-items-center gap-3 p-3">
    <span
      class="rb-ic rb-ic-close fs-lg position-absolute top-1 end-1 cursor-pointer hover-effect text-gray"
      (click)="handleOnHistoryClosed()"
    ></span>
    <ng-container *ngIf="isZoomLevelAllowedForHistory()">
      <app-datetime-picker
        [datetime]="currentTimestamp?.toISOString()"
        (datetimeChanged)="handleOnTimestampChanged($event)"
        [disabled]="isLoading"
      >
      </app-datetime-picker>
      <div
        class="d-flex position-relative align-self-stretch align-items-center btn btn-darkgray"
        (click)="handleReloadClick()"
      >
        <span class="fs-sm" [class.text-darkgray]="isLoading">Reload</span>
        <div class="position-absolute translate-middle start-50 top-50">
          <div
            class="spinner-border text-white spinner-border-sm"
            [class.d-none]="!isLoading"
            role="status"
          ></div>
        </div>
      </div>
      <app-range-picker
        [timespan]="currentTimespan"
        [timestamp]="currentTimestamp"
        (timeSpanToggled)="handleOnTimeSpanChanged($event)"
        [disabled]="isLoading"
      >
      </app-range-picker>
    </ng-container>

    <ng-container *ngIf="!isZoomLevelAllowedForHistory()">
      <div class="d-flex align-items-center gap-1 p-2">
        <span class="rb-ic rb-ic-alert-info text-warning fs-xl"></span>
        <span class="text-gray fs-sm"
          >To get historic data, zoom in to level {{ config.history_min_zoom_level }}</span
        >
      </div>
    </ng-container>
  </div>
</div>
