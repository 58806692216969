<div
  class="position-relative bg-dark text-gray p-5 border border-darkgray rounded-2 d-flex flex-column gap-2 shadow-lg mw-100"
>
  <span
    class="rb-ic rb-ic-close fs-2xl position-absolute top-2 end-2 cursor-pointer hover-effect"
    (click)="closed.emit()"
  ></span>
  <app-details-toggle
    *ngIf="numberOfDetails > 1"
    [numberOfDetails]="numberOfDetails"
    [index]="index"
    (indexChanged)="selectedItemChanged.emit($event)"
  ></app-details-toggle>
  <ng-content></ng-content>
</div>
