<div
  class="d-flex flex-column position-absolute translate-middle-y top-50 end-5 shadow-sm text-deactivated rounded-2 bg-dark border border-darkgray cursor-pointer"
>
  <span
    class="rb-ic rb-ic-arrow-up-frame fs-2xl hover-effect p-2"
    [ngStyle]="{ transform: 'rotate(' + rotateIcon + 'deg)' }"
    (click)="rotateNorth()"
    placement="start"
    ngbTooltip="Rotate back to north"
    tooltipClass="custom-tooltip"
    [openDelay]="1000"
  >
  </span>
  <span class="border-bottom border-darkgray"> </span>
  <span
    class="rb-ic rb-ic-locator fs-2xl hover-effect p-2"
    [class.sel-menu-item]="isLocationRefreshEnabled"
    (click)="toggleAutoRefresh()"
    ngbTooltip="Follow current location"
    tooltipClass="custom-tooltip"
    placement="start"
    [openDelay]="1000"
  >
  </span>
  <span class="border-bottom border-darkgray"> </span>
  <span
    class="rb-ic rb-ic-satellite fs-2xl hover-effect p-2"
    [class.sel-menu-item]="isSatelliteEnabled"
    (click)="changeMapStyle()"
    ngbTooltip="Change to satellite style"
    tooltipClass="custom-tooltip"
    placement="start"
    [openDelay]="1000"
  >
  </span>
  <ng-content></ng-content>
</div>
