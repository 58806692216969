import { Permissions } from '../../core/enums/permissions.enum'
import { LayersDefinition } from '../models/layers-definition.model'

export const Layers: LayersDefinition = {
  RoadSegment: {
    name: 'rs',
    permissions: [Permissions.RoadSegments_All_Read, Permissions.RoadSegments_Rc_Read],
  },
  WeatherPrediction: { name: 'wp', permissions: [Permissions.Weather_All_Read] },
  VehicleDataRain: { name: 'vdr', permissions: [Permissions.Hazards_All_Read] },
  VehicleDataFog: { name: 'vdf', permissions: [Permissions.Hazards_All_Read] },
  VehicleDataSlippery: { name: 'vds', permissions: [Permissions.Hazards_All_Read] },
  VehicleDataBdv: { name: 'vdbdv', permissions: [Permissions.Hazards_All_Read] },
  VehicleDataAccidents: { name: 'vda', permissions: [Permissions.Hazards_All_Read] },
  VAWarning: {
    name: 'vaw',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Va_Read],
  },
  BDVWarning: {
    name: 'bdvw',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Bdv_Read],
  },
  TLWarning: {
    name: 'tlw',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Tl_Read],
  },
  CSWarning: {
    name: 'csw',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Cs_Read],
  },
  LVWarning: {
    name: 'lvw-area',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Lv_Read],
  },
  HRWarning: {
    name: 'hrw-area',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Hr_Read],
  },
  HSWarning: {
    name: 'hsw-area',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Hs_Read],
  },
  SWWarning: {
    name: 'sww-area',
    permissions: [Permissions.Warnings_All_Read, Permissions.Warnings_Sw_Read],
  },
  SimWarning: { name: 'sw', permissions: [Permissions.Warnings_All_Read] },
  CreateSimAreaWarnings: {
    name: 'csaw',
    permissions: [
      Permissions.Warnings_All_Read,
      Permissions.Warnings_Lv_Write,
      Permissions.Warnings_Sw_Write,
      Permissions.Warnings_Hr_Write,
      Permissions.Warnings_Hs_Write,
    ],
  },
  CreateSimRoadWarnings: {
    name: 'csrw',
    permissions: [
      Permissions.Warnings_All_Read,
      Permissions.Warnings_Va_Write,
      Permissions.Warnings_Tl_Write,
      Permissions.Warnings_Bdv_Write,
      Permissions.Warnings_Cs_Write,
    ],
  },
  BingAccidents: { name: 'ba', permissions: [Permissions.Hazards_All_Read] },
  BingBdv: { name: 'bb', permissions: [Permissions.Hazards_All_Read] },
  Webcams: { name: 'wc', permissions: [] },
}
