import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DetailsComponent } from './components/details/details-base/details-base.component'
import { RangePickerComponent } from './components/range-picker/range-picker.component'
import { LegendComponent } from './components/legend/legend-base/legend-base.component'
import { LegendItemComponent } from './components/legend/legend-item/legend-item.component'
import { LayerControlItemComponent } from './components/layer-control/layer-control-item/layer-control-item.component'
import { LayerControlComponent } from './components/layer-control/layer-control-base/layer-control-base.component'
import { LayerConfigComponent } from './components/layer-config/layer-config-base/layer-config-base.component'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { MapNavigationComponent } from './components/map-navigation/map-navigation-base.component'
import { ButtonIconComponent } from './components/button-icon/button-icon.component'
import { LastBackendCallComponent } from './components/last-backend-call/last-backend-call.component'
import { LayerCategoryComponent } from './components/layer-config/layer-config-category/layer-config-category.component'
import { LayerConfigItemComponent } from './components/layer-config/layer-config-item/layer-config-item.component'
import { NgVar } from './directives/ng-var'
import { DetailsToggleComponent } from './components/details/details-toggle/details-toggle.component'
import { LegendCategoryComponent } from './components/legend/legend-category/legend-category.component'
import { DetailsItemHorizontalComponent } from './components/details/details-item-horizontal/details-item-horizontal.component'
import { DetailsItemComponent } from './components/details/details-item/details-item.component'
import { AutoRefreshComponent } from './components/auto-refresh/auto-refresh.component'
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component'
import { RbUiComponentsModule } from '@inst-iot/bosch-angular-ui-components'
import { FormsModule } from '@angular/forms'
import { LayerButtonComponent } from './components/layer-button/layer-button.component'
import { HasPermissionAnyDirective } from './directives/has-permission-any.directive'
import { DetailsCategoryComponent } from './components/details/details-category/details-category.component'
import { DetailsMetaCategoryComponent } from './components/details/details-meta-category/details-meta-category.component'
import { DetailsAccordionComponent } from './components/details/details-accordion/details-accordion'
import { ScaleControlComponent } from './components/scale-control/scale-control.component'
import { DetailsLinksComponent } from './components/details/details-links/details-links.component'
import { HasPermissionAllDirective } from './directives/has-permission-all.directive'
import { IsDemoEnabledDirective } from './directives/is-demo-enabled.directive'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    RangePickerComponent,
    LegendComponent,
    LegendItemComponent,
    DetailsComponent,
    ButtonIconComponent,
    LastBackendCallComponent,
    DetailsItemComponent,
    DetailsToggleComponent,
    DetailsItemHorizontalComponent,
    LayerControlItemComponent,
    LayerControlComponent,
    LayerConfigComponent,
    ClickOutsideDirective,
    HasPermissionAnyDirective,
    HasPermissionAllDirective,
    IsDemoEnabledDirective,
    LayerCategoryComponent,
    LayerConfigItemComponent,
    MapNavigationComponent,
    NgVar,
    LegendCategoryComponent,
    AutoRefreshComponent,
    DatetimePickerComponent,
    LayerButtonComponent,
    DetailsCategoryComponent,
    DetailsMetaCategoryComponent,
    ScaleControlComponent,
    DetailsLinksComponent,
  ],
  exports: [
    RangePickerComponent,
    ButtonIconComponent,
    LastBackendCallComponent,
    LegendComponent,
    LegendItemComponent,
    LegendCategoryComponent,
    DetailsComponent,
    DetailsToggleComponent,
    DetailsItemComponent,
    DetailsItemHorizontalComponent,
    LayerControlItemComponent,
    LayerControlComponent,
    LayerConfigComponent,
    ClickOutsideDirective,
    HasPermissionAnyDirective,
    HasPermissionAllDirective,
    IsDemoEnabledDirective,
    LayerCategoryComponent,
    LayerConfigItemComponent,
    MapNavigationComponent,
    NgVar,
    AutoRefreshComponent,
    DatetimePickerComponent,
    LayerButtonComponent,
    DetailsCategoryComponent,
    DetailsMetaCategoryComponent,
    ScaleControlComponent,
    DetailsLinksComponent,
  ],
  imports: [
    CommonModule,
    RbUiComponentsModule,
    DetailsAccordionComponent,
    FormsModule,
    NgbTooltipModule,
  ],
})
export class SharedModule {}
