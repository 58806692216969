import { MetricModel } from 'src/app/shared/models/metric.model'
import {
  createPaintExpression,
  getTractionLossPaint,
  roadConditionPaint,
  weatherIcyPaint,
  weatherWftPaint,
} from '../utils/rcs-paint-utils'
import {
  rcLegendRoadCondition,
  rcLegendTractionLossRoadCondition,
  rcLegendVehicleData,
  rcLegendWeatherIcy,
  rcLegendWeatherWft,
} from '../utils/rc-legend-utils'
import { HazardTypes } from 'src/app/shared/enums/hazard-types.enum'
import { defaultExpression, defaultLegend } from 'src/app/shared/utils/metric.utils'

export enum RcsMetrics {
  RoadSegment_RoadCondition = 'rs_rc',
  RoadSegment_Friction = 'rs_fr',
  RoadSegment_Wft = 'rs_wt',
  RoadSegment_TractionLoss = 'rs_tl',
  WeatherPrediction_Wft = 'wp_wt',
  VehicleData_HeavyRain = 'vd_hr',
  WeatherPrediction_Icy = 'wp_i',
}

export const getMetricFromMap = (metric: string): MetricModel => {
  return (
    RcsMetricMap.get(metric) || {
      displayName: 'Default',
      expression: defaultExpression,
      name: 'default',
      icon: 'question',
      key: 'default',
      legend: defaultLegend,
    }
  )
}

export const RcsMetricMap = new Map<string, MetricModel>()
RcsMetricMap.set(RcsMetrics.RoadSegment_RoadCondition, {
  name: 'roadCondition',
  expression: createPaintExpression('roadCondition', roadConditionPaint),
  icon: 'car-waves',
  key: RcsMetrics.RoadSegment_RoadCondition,
  displayName: 'Road Condition',
  legend: rcLegendRoadCondition,
})

RcsMetricMap.set(RcsMetrics.WeatherPrediction_Wft, {
  name: 'wft',
  expression: createPaintExpression('wft', weatherWftPaint),
  icon: 'water',
  key: RcsMetrics.WeatherPrediction_Wft,
  displayName: 'Waterfilm Thickness',
  legend: rcLegendWeatherWft,
})

RcsMetricMap.set(RcsMetrics.WeatherPrediction_Icy, {
  name: 'icy',
  expression: createPaintExpression('icyProb', weatherIcyPaint),
  icon: 'ice',
  key: RcsMetrics.WeatherPrediction_Icy,
  displayName: 'Icy',
  legend: rcLegendWeatherIcy,
})

RcsMetricMap.set(RcsMetrics.VehicleData_HeavyRain, {
  name: 'heavyRain',
  expression: ['==', ['get', 'hazardType'], HazardTypes.HeavyRain],
  icon: 'windshield-wiper',
  key: RcsMetrics.VehicleData_HeavyRain,
  displayName: 'Heavy Rain',
  legend: rcLegendVehicleData,
})

RcsMetricMap.set(RcsMetrics.RoadSegment_TractionLoss, {
  name: 'tractionLoss',
  expression: createPaintExpression('tractionLoss', getTractionLossPaint('tractionLoss')),
  icon: 'wheel-vehicle',
  displayName: 'Traction Loss',
  key: RcsMetrics.RoadSegment_TractionLoss,
  legend: rcLegendTractionLossRoadCondition,
})
