import { Component } from '@angular/core'
import { DetailsModel } from '../../../shared/models/details.model'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { debounceTime } from 'rxjs/operators'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { format } from 'date-fns'
import { Layers } from 'src/app/shared/layers-config/layers'
import { RawDataModel } from 'src/app/shared/models/raw-event.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-rc-raw-details',
  templateUrl: './rc-raw-details.component.html',
})
export class RcRawDetailsComponent {
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public globalId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    private appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : ''
        if (
          features[this.index].layer.id === Layers.VehicleDataSlippery.name ||
          features[this.index].layer.id === Layers.VehicleDataRain.name
        ) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    this.rawFeature = JSON.stringify(this.features[this.index], undefined, 2)
    const properties: RawDataModel = this.features[this.index].properties as RawDataModel
    this.zoomLevel = this.appState.getState().zoom
    this.mapRcRawEventsToDetails(properties)
    this.appState.setState(AppPaths.RoadCondition, {
      selectedFeatureId: properties.id,
    })
  }

  mapRcRawEventsToDetails(properties: RawDataModel) {
    this.details?.set('RawVehicleData', {
      title: 'Vehicle data',
      items: [
        {
          description: 'Type',
          value: properties.hazardType!,
        },
        {
          description: 'ID',
          value: properties.id?.toString() ?? '',
        },
        {
          description: `Valid time (${getTimeZoneLabel(new Date(properties['startTimestamp']))})`,
          value: `${
            properties['startTimestamp']
              ? format(new Date(properties['startTimestamp']), 'yyyy-MM-dd HH:mm')
              : '-'
          } - ${
            properties['endTimestamp'] ? format(new Date(properties['endTimestamp']), 'HH:mm') : '-'
          }`,
        },
        {
          description: `Timestamp (${getTimeZoneLabel(new Date(properties.timestamp))})`,
          value: properties.timestamp
            ? format(new Date(properties['timestamp']), 'yyyy-MM-dd HH:mm')
            : 'n/a',
        },
      ],
    })
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.RoadCondition, {
      selectedFeatureId: undefined,
    })
  }
}
