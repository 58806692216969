import { ExpressionSpecification } from 'maplibre-gl'

const dryProbExpression: ExpressionSpecification = [
  'get',
  'dry',
  ['object', ['feature-state', 'roadCondition']],
]
const wetProbExpression: ExpressionSpecification = [
  'get',
  'wet',
  ['object', ['feature-state', 'roadCondition']],
]
const veryWetProbExpression: ExpressionSpecification = [
  'get',
  'veryWet',
  ['object', ['feature-state', 'roadCondition']],
]
const icyProbExpression: ExpressionSpecification = [
  'get',
  'icy',
  ['object', ['feature-state', 'roadCondition']],
]

export const getWeatherWftExpression: ExpressionSpecification = [
  'get',
  'value',
  ['object', ['feature-state', 'wft']],
]

export const getWeatherIcyExpression: ExpressionSpecification = [
  'get',
  'value',
  ['object', ['feature-state', 'icyProb']],
]

export const getWeatherRstExpression: ExpressionSpecification = [
  'get',
  'value',
  ['object', ['feature-state', 'rst']],
]

export const maxProbExpression: ExpressionSpecification = [
  'max',
  dryProbExpression,
  wetProbExpression,
  veryWetProbExpression,
  icyProbExpression,
]

const dryEqualsWet: ExpressionSpecification = [
  'case',
  ['==', ['round', ['*', 100, dryProbExpression]], ['round', ['*', 100, wetProbExpression]]],
  true,
  false,
]

const dryEqualsVeryWet: ExpressionSpecification = [
  'case',
  ['==', ['round', ['*', 100, dryProbExpression]], ['round', ['*', 100, veryWetProbExpression]]],
  true,
  false,
]

const dryEqualsIcy: ExpressionSpecification = [
  'case',
  ['==', ['round', ['*', 100, dryProbExpression]], ['round', ['*', 100, icyProbExpression]]],
  true,
  false,
]

const dryGreaterWet: ExpressionSpecification = [
  'case',
  ['>=', dryProbExpression, wetProbExpression],
  true,
  false,
]

const dryGreaterVeryWet: ExpressionSpecification = [
  'case',
  ['>=', dryProbExpression, veryWetProbExpression],
  true,
  false,
]

const dryGreaterIcy: ExpressionSpecification = [
  'case',
  ['>=', dryProbExpression, icyProbExpression],
  true,
  false,
]

const wetGreaterVeryWet: ExpressionSpecification = [
  'case',
  ['>=', wetProbExpression, veryWetProbExpression],
  true,
  false,
]

const wetGreaterIcy: ExpressionSpecification = [
  'case',
  ['>=', wetProbExpression, icyProbExpression],
  true,
  false,
]

const veryWetGreaterIcy: ExpressionSpecification = [
  'case',
  ['>=', veryWetProbExpression, icyProbExpression],
  true,
  false,
]

export const probabilityExpression: ExpressionSpecification = [
  'case',
  ['all', dryEqualsWet, dryEqualsVeryWet, dryEqualsIcy],
  'unknown',
  ['all', dryGreaterWet, dryGreaterVeryWet, dryGreaterIcy],
  'dry',
  ['all', ['!=', dryGreaterWet, true], wetGreaterVeryWet, wetGreaterIcy, dryGreaterVeryWet],
  'wetdry',
  [
    'all',
    ['!=', dryGreaterWet, true],
    wetGreaterVeryWet,
    wetGreaterIcy,
    ['!=', dryGreaterVeryWet, true],
  ],
  'wetwet',
  ['all', ['!=', dryGreaterVeryWet, true], ['!=', wetGreaterVeryWet, true], veryWetGreaterIcy],
  'veryWet',
  'icy',
]
