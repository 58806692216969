import { LegendModel } from 'src/app/shared/models/legend.model'

export const rcLegendRoadCondition: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'dry',
      value: 'noncritical',
    },
    {
      description: 'wet',
      value: 'warning',
    },
    {
      description: 'very wet',
      value: 'dangerous',
    },
    {
      description: 'icy',
      value: 'critical',
    },
  ],
}

export const rcLegendTractionLossRoadCondition: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'ok',
      value: 'noncritical',
    },
    {
      description: 'warning',
      value: 'dangerous',
    },
  ],
}

export const rcLegendWeatherWft: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'thin',
      value: 'noncritical',
    },
    {
      description: 'middle',
      value: 'warning',
    },
    {
      description: 'high',
      value: 'dangerous',
    },
  ],
}

export const rcLegendWeatherIcy: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'thin',
      value: 'noncritical',
    },
    {
      description: 'middle',
      value: 'warning',
    },
    {
      description: 'high',
      value: 'dangerous',
    },
  ],
}

export const rcLegendVehicleData: LegendModel = {
  type: 'hazard',
  category: 'vehicle',
  items: [
    {
      description: 'Heavy Rain',
      value: 'dangerous',
    },
    {
      description: 'Slippery Road',
      value: 'slippery-hazard',
    },
  ],
}
