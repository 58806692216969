import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { MapService } from 'src/app/core/services/map.service'
import { CircleLayerSpecification, LineLayerSpecification } from 'maplibre-gl'
import { DataRawEventsService } from '../services/data-raw-events.service'

import { emptyGeoJsonSource } from 'src/app/core/utils/source-utils'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { generateHexagons } from 'src/app/road-condition/utils/h3-utils'
import { NavigationStart, Router } from '@angular/router'
import { MapEventsService } from 'src/app/core/services/map-events.service'

@Injectable()
export class LayerRawEventsService implements OnDestroy {
  public isLayerVisible = false
  private cleanUp$ = new Subject<void>()
  private cancelRequests$ = new Subject<void>()
  private timestamp: Date | undefined
  private timespan: number | undefined
  private h3Resolution = 6
  private maxZoom = 22
  private maxZoomAvailable = 15
  private minZoomAvailable = 4
  public selectedFeatureId: string | null = null

  public layerId = this.layer
  public sourceId = `${this.layerId}-src`
  public sourceLayerName = 'obstacles_layer'
  private spreadingLayerId = `${this.layerId}-spreading`
  private spreadingSourceId = `${this.spreadingLayerId}-src`

  private rawHazardsLayer: CircleLayerSpecification = {
    id: `${this.layer}`,
    source: this.sourceId,
    type: 'circle',
    paint: {
      'circle-color': this.circleColor[0],
      'circle-stroke-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        4,
        ['==', ['get', 'id'], this.appState.getState().selectedFeatureId ?? ''],
        4,
        2,
      ],
      'circle-stroke-color': this.circleColor[1],
      'circle-opacity': [
        'case',
        ['all', ['<=', ['get', 'age'], this.uncertaintyAgeEventsInMinutes]],
        1,
        0.3,
      ],
      'circle-stroke-opacity': [
        'case',
        ['all', ['<=', ['get', 'age'], this.uncertaintyAgeEventsInMinutes]],
        0.7,
        0.3,
      ],
    },
    minzoom: this.minZoom,
  }

  private rawHazardsSpreadingLayer: LineLayerSpecification = {
    id: this.spreadingLayerId,
    source: this.spreadingSourceId,
    type: 'line',
    paint: {
      'line-color': this.circleColor[0],
      'line-dasharray': [2.6, 2.6],
    },
    minzoom: this.minZoom,
    maxzoom: this.maxZoom,
  }

  constructor(
    private router: Router,
    private readonly mapService: MapService,
    private readonly mapEventsService: MapEventsService,
    private appState: AppStateService,
    @Inject(String) private layer: string,
    @Inject(Number) private minZoom: number,
    private dataService: DataRawEventsService,
    @Inject(Number) private uncertaintyAgeEventsInMinutes: number,
    @Inject(String) private spreadingSize: number | undefined,
    @Inject(String) private circleColor: string[],
  ) {
    if (mapService.isMapReady) {
      this.initializeLayer()
    } else {
      this.mapService.mapReadyChanged.subscribe(() => {
        this.initializeLayer()
      })
    }
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe((state) => {
      this.evaluateState()
    })

    // NgOnDestroy is not working on instances created by a factory, therefore we need this workaround
    this.router.events.pipe(takeUntil(this.cleanUp$)).forEach((event) => {
      if (event instanceof NavigationStart) {
        this.ngOnDestroy()
      }
    })
  }

  ngOnDestroy() {
    this.cancelRequests()
    this.cleanUp$.next()
    this.mapEventsService.detachMapEvents(this)
    this.mapService.map.removeLayer(this.layerId)
    this.mapService.map.removeLayer(this.spreadingLayerId)
    this.mapService.map.removeSource(this.sourceId)
    this.mapService.map.removeSource(this.spreadingSourceId)
  }

  private initializeLayer() {
    this.mapService.map.addSource(this.sourceId, { ...emptyGeoJsonSource, generateId: true })
    this.mapService.map.addLayer(this.rawHazardsLayer)
    this.mapService.map.addSource(this.spreadingSourceId, emptyGeoJsonSource)
    this.mapService.map.addLayer(this.rawHazardsSpreadingLayer)
    this.mapEventsService.registerForMapEvents(this, true)
    this.evaluateState()
  }

  public async reload() {
    this.cancelRequests()
    if (
      this.mapService.isMapReady &&
      this.isLayerVisible &&
      !this.appState.isLoading &&
      this.mapService.map.getZoom() >= this.minZoom
    ) {
      const xyzTiles = this.mapService.getXyzTiles(this.maxZoomAvailable, this.minZoomAvailable)
      await this.dataService.loadRawEvents(
        this.timestamp,
        this.mapService.map.getBounds(),
        xyzTiles,
      )
      this.updateLayer()
    }
  }

  public updateLayer() {
    if (this.mapService.isMapReady) {
      this.mapService.map.removeFeatureState({
        source: this.sourceId,
        sourceLayer: this.sourceId,
      })

      const queryTimestamp =
        this.timestamp && this.timespan ? addMinutes(this.timestamp, this.timespan) : this.timestamp

      const vdData = this.dataService.getRawEvents(queryTimestamp)
      vdData.features = vdData.features.filter((feature) => {
        const effectiveDate = queryTimestamp ? new Date(queryTimestamp).getTime() : Date.now()
        feature.properties.age = Math.abs(
          differenceInMinutes(
            queryTimestamp ? new Date(queryTimestamp) : new Date(Date.now()),
            new Date(feature.properties.timestamp),
          ),
        )
        return feature.properties.endTimestamp > effectiveDate
      })

      this.mapService.setGeoJsonData(this.sourceId, vdData)

      if (this.spreadingSize != undefined) {
        const hexesFog = generateHexagons(
          {
            type: 'FeatureCollection',
            features: vdData.features,
          },
          this.h3Resolution,
          this.spreadingSize,
        )
        this.mapService.setGeoJsonData(this.spreadingSourceId, hexesFog as any)
      }
    }
  }

  private evaluateState() {
    if (this.appState.getState().layers?.includes(this.layer)) {
      this.setVisibility(true)
    } else {
      this.setVisibility(false)
    }

    // if layer is not visible stop all events
    if (this.isLayerVisible === false) return

    if (!this.timestamp) this.timestamp = this.appState.selectTimestamp()
    if (!this.timespan) this.timespan = this.appState.getState().timespan

    if (!this.dataService.isDataInitialized) {
      this.reload()
    }

    if (
      this.appState.hasLayerVisibilityChanged(this.layer) &&
      !this.appState.getState().historyEnabled
    ) {
      this.reload()
    }

    if (this.appState.hasMapBoundsChanged() && !this.appState.getState().historyEnabled) {
      this.reload()
    }

    if (this.appState.hasTimestampChanged()) {
      this.timestamp = this.appState.selectTimestamp()
      this.reload()
    }
    if (this.appState.hasTimespanChanged()) {
      this.timespan = this.appState.state.timespan
      this.updateLayer()
    }

    if (this.appState.hasSelectedFeatureIdChanged()) {
      this.selectedFeatureId = this.appState.getState().selectedFeatureId || ''
      if (this.selectedFeatureId != undefined) {
        this.mapService.map.setPaintProperty(this.layerId, 'circle-stroke-width', [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          4,
          ['==', ['get', 'id'], this.selectedFeatureId],
          4,
          2,
        ])
      }
    }
  }

  setVisibility(isVisible: boolean): void {
    this.isLayerVisible = isVisible
    if (this.mapService.isMapReady) {
      if (this.mapService.getLayerVisibilty(this.layer) != this.isLayerVisible) {
        this.mapService.setLayerVisibility(this.layerId, isVisible)
        this.mapService.setLayerVisibility(this.spreadingLayerId, isVisible)
        if (!isVisible) {
          this.cancelRequests()
        }
      }
    }
  }

  private cancelRequests() {
    this.cancelRequests$.next()
    this.cancelRequests$.complete()
    this.cancelRequests$ = new Subject()
  }
}
