import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RbUiComponentsModule } from '@inst-iot/bosch-angular-ui-components'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HttpClientModule } from '@angular/common/http'
import { MsalRedirectComponent } from '@azure/msal-angular'
import { RoadConditionModule } from './road-condition/road-condition.module'
import { BadWeatherConditionModule } from './bad-weather-condition/bad-weather-condition.module'
import { ObstacleModule } from './obstacle/obstacle.module'
import { CustomerModule } from './customer/customer.module'
import { SimulationModule } from './simulation/simulation.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RbUiComponentsModule,
    CoreModule,
    NgbModule,
    HttpClientModule,
    RoadConditionModule,
    BadWeatherConditionModule,
    ObstacleModule,
    CustomerModule,
    SimulationModule,
  ],
  providers: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
