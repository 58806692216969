import { Component, Inject } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import { Subject, debounceTime, interval, takeUntil } from 'rxjs'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { Layers } from 'src/app/shared/layers-config/layers'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { getMetricFromMap } from '../enums/simulation-metrics.enum'

import { LayerSimulationWarningService } from 'src/app/shared/layers/layer-simulation-warning.service'

import { DataSimulationWarningService } from 'src/app/shared/services/data-simulation-warning.service'
import { AppConfigModel } from 'src/app/core/models'
import { APP_CONFIG } from 'src/app/app.config'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { AuthService } from 'src/app/core/services/auth.service'
import { WarningDatasources } from 'src/app/shared/enums/warning-types.enum'

@Component({
  selector: 'app-simulation-page',
  templateUrl: './simulation-page.component.html',

  providers: [
    LayerSimulationWarningService,
    DataSimulationWarningService,
    { provide: 'metrics', useValue: getMetricFromMap },
  ],
})
export class SimulationPageComponent {
  private stopTimer = new Subject<void>()

  public isInLiveMode = false
  public autoRefreshIntervalInMs = 0
  public layerIds = Layers
  public currentTimestamp: Date | undefined
  public currentTimespan: number | undefined
  public warningId = ''
  public Permissions = Permissions
  public isRoadWarningCreationVisible: boolean = false
  public isHexagonWarningCreationVisible: boolean = false
  public isWarningImportExportVisible: boolean = false
  public onDestroy: boolean = false
  public allowedFilters: WarningDatasources[] = []
  public currentFilter: string = ''

  constructor(
    @Inject(APP_CONFIG) public readonly config: AppConfigModel,
    private mapService: MapService,
    public appState: AppStateService,
    public AuthService: AuthService,
    private simulationWarningLayerService: LayerSimulationWarningService,
  ) {
    this.allowedFilters = this.AuthService.getUserClaims().userDataFilters
    this.currentFilter = this.allowedFilters[0]
    this.mapService.activateGeoLocationSearch()
    this.mapService.setPosition(
      this.appState.getState().lat,
      this.appState.getState().lon,
      this.appState.getState().zoom,
    )

    this.updateQueryParameters()
    this.mapService.mapViewChanged
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.updateQueryParameters()
      })

    this.evaluateQueryParameters()
    this.appState.stateChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.evaluateQueryParameters()
    })
  }

  ngOnDestroy() {
    this.mapService.deactivateGeoLocationSearch()
    this.stopTimer.next()
    this.stopTimer.complete()
  }

  setLiveModeRefreshInterval(seconds: number) {
    this.autoRefreshIntervalInMs = seconds * 1000
    this.stopLiveMode()
    if (this.autoRefreshIntervalInMs > 0) {
      this.startLiveMode()
    }
  }

  startLiveMode() {
    this.isInLiveMode = true
    interval(this.autoRefreshIntervalInMs)
      .pipe(takeUntil(this.stopTimer))
      .subscribe(() => {
        this.simulationWarningLayerService.reload()
      })
  }

  stopLiveMode() {
    this.isInLiveMode = false
    this.stopTimer.next()
  }

  setCurrentFilter(filter: WarningDatasources) {
    this.currentFilter = filter
    this.appState.hasWarningDatasourceChanged$.next(filter)
  }

  evaluateQueryParameters() {
    if (this.appState.getState().layers?.includes(Layers.CreateSimAreaWarnings.name)) {
      this.isHexagonWarningCreationVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.CreateSimRoadWarnings.name)) {
      this.isRoadWarningCreationVisible = true
    }
  }

  updateQueryParameters() {
    this.appState.setState(AppPaths.Simulation, {
      lat: this.mapService.map.getCenter().lat,
      lon: this.mapService.map.getCenter().lng,
      zoom: this.mapService.map.getZoom(),
    })
  }

  enableWarningImportExport() {
    this.isWarningImportExportVisible = !this.isWarningImportExportVisible
  }

  enableSetHexagonWarningMode() {
    this.isHexagonWarningCreationVisible = !this.isHexagonWarningCreationVisible
    const layers = this.appState
      .selectLayers()
      .filter((layer) => !layer.includes(Layers.CreateSimAreaWarnings.name))

    if (this.isHexagonWarningCreationVisible) {
      layers.push(Layers.CreateSimAreaWarnings.name)
    }

    this.appState.setState(AppPaths.Simulation, {
      layers: layers?.toString(),
    })
  }
  enableSetRoadWarningMode() {
    this.isRoadWarningCreationVisible = !this.isRoadWarningCreationVisible
    const layers = this.appState
      .selectLayers()
      .filter((layer) => !layer.includes(Layers.CreateSimRoadWarnings.name))

    if (this.isRoadWarningCreationVisible) {
      layers.push(Layers.CreateSimRoadWarnings.name)
    }

    this.appState.setState(AppPaths.Simulation, {
      layers: layers?.toString(),
    })
  }

  closeImportExport() {
    this.isWarningImportExportVisible = false
  }
}
