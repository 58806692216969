import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { AuthService } from 'src/app/core/services/auth.service'

@Directive({
  selector: '[hasPermissionAll]',
})
export class HasPermissionAllDirective {
  @Input()
  set hasPermissionAll(userPermission: Permissions[]) {
    if (this.authService.hasPermission(userPermission, 'all')) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}
}
