<div class="position-relative bg-dark text-gray shadow-lg w-100">
  <div class="d-flex align-items-center justify-content-center border-darkgray border-top">
    <span
      class="rb-ic rb-ic-down fs-xl text-white cursor-pointer hover-effect"
      [class.rotate-180]="isCollapsed"
      (click)="toggleInsights()"
    ></span>
  </div>
  <div
    class="p-2 fs-sm d-flex flex-column gap-2 max-vh-40 overflow-auto"
    [class.d-none]="isCollapsed"
  >
    <div class="row g-0">
      <span class="col text-white">Conditions</span>
      <div class="row border-bottom border-darkgray py-2 g-0">
        <app-details-item-horizontal
          class="col"
          description="Dry"
          [value]="roadSegment?.roadCondition?.dry | percent"
        ></app-details-item-horizontal>
        <app-details-item-horizontal
          class="col"
          description="Wet"
          [value]="roadSegment?.roadCondition?.wet | percent"
        ></app-details-item-horizontal>
        <app-details-item-horizontal
          class="col"
          description="Very Wet"
          [value]="roadSegment?.roadCondition?.veryWet | percent"
        ></app-details-item-horizontal>
        <app-details-item-horizontal
          class="col"
          description="Icy"
          [value]="roadSegment?.roadCondition?.icy | percent"
        ></app-details-item-horizontal>
      </div>
    </div>
    <div class="row g-0">
      <span class="col text-white">Friction</span>
      <div class="row border-bottom border-darkgray py-2 g-0">
        <app-details-item-horizontal
          class="col"
          description="Coefficient"
          [value]="roadSegment?.friction?.coefficient | number: '1.2-2'"
        ></app-details-item-horizontal>
        <app-details-item-horizontal
          class="col"
          description="Uncertainty"
          [value]="roadSegment?.friction?.uncertainty | number: '1.2-2'"
        ></app-details-item-horizontal>
      </div>
    </div>
    <div class="row g-0">
      <span class="col text-white">Waterfilm Thickness</span>
      <div class="row border-bottom border-darkgray py-2 g-0">
        <app-details-item-horizontal
          class="col"
          description="Value"
          [value]="roadSegment?.wft?.value | number: '1.2-2'"
        ></app-details-item-horizontal>
        <app-details-item-horizontal
          class="col"
          description="Uncertainty"
          [value]="roadSegment?.wft?.uncertainty | number: '1.2-2'"
        ></app-details-item-horizontal>
      </div>
    </div>
  </div>
  <div class="p-4 row align-items-center gap-2 g-0">
    <app-details-item
      class="col"
      description="WFT"
      [value]="roadSegment?.wft?.value | number: '1.2-2'"
    ></app-details-item>
    <app-details-item
      class="col"
      [description]="highestRoadCondtion.description ? highestRoadCondtion.description : 'Cond.'"
      [value]="highestRoadCondtion.value | percent"
    ></app-details-item>
    <app-details-item
      class="col"
      description="Friction"
      [value]="roadSegment?.friction?.coefficient | number: '1.2-2'"
    ></app-details-item>
  </div>
</div>
