import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { SimulationPageComponent } from './simulation-page/simulation-page.component'
import { SimulationWarningsDetailsComponent } from './components/simulation-warnings-components/simulation-warnings-details/simulation-warnings-details.component'
import { SimulationWarningsImportExportComponent } from './components/simulation-warnings-import-export/simulation-warnings-import-export.component'
import { SimulationCreateWarningsDetailsComponent } from './components/simulation-warnings-components/simulation-create-warnings-details/simulation-create-warnings-details.component'
import { SimulationCreateAreaWarningsDetailsComponent } from './components/simulation-warnings-components/simulation-create-area-warnings-details/simulation-create-area-warnings-details.component'
import { SimulationAreaWarningsDetailsComponent } from './components/simulation-warnings-components/simulation-area-warnings-details/simulation-area-warnings-details.component'
import { SimulationLayerConfigComponent } from './components/simulation-layer-config/simulation-layer-config.component'
import { SimulationDataComponent } from './components/simulation-layer-categories/simulation-data.component'
import { SharedModule } from '../shared/shared.module'
import { DetailsAccordionComponent } from '../shared/components/details/details-accordion/details-accordion'
import { NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    SimulationPageComponent,
    SimulationDataComponent,
    SimulationWarningsDetailsComponent,
    SimulationWarningsImportExportComponent,
    SimulationCreateAreaWarningsDetailsComponent,
    SimulationAreaWarningsDetailsComponent,
    SimulationCreateWarningsDetailsComponent,
    SimulationLayerConfigComponent,
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbDropdownModule,
    DragDropModule,
    FormsModule,
    SharedModule,
    DetailsAccordionComponent,
  ],
})
export class SimulationModule {}
