<div
  class="position-absolute bg-dark text-gray p-2 rounded-2 border border-darkgray start-5 z-3 top-26 max-vh-70 min-vh-55 min-wpx-350"
  cdkDrag
  cdkDragBoundary="app-root"
  [class.d-none]="!isVisible"
>
  <div class="d-flex justify-content-between">
    <a
      ngClass="w-100 btn  btn-hover border-darkgray text-lightgray mw-100"
      (click)="exportVisibleTestWarnings()"
      ngbTooltip="Exports all visible warnings"
      tooltipClass="custom-tooltip"
      >Export (visible)</a
    >
    <a
      ngClass="w-100 btn {{ requestStatus }}  {{
        shake
      }} border border-darkgray text-lightgray mw-100 "
      (click)="importTestWarnings()"
    >
      {{ importMessage }}</a
    >
    <span
      class="justify-content-center rb-ic rb-ic-close fs-2xl p-1 cursor-pointer hover-effect"
      (click)="closed.emit(); this.warningJson = ''; this.errorMessage = ''"
    ></span>
  </div>

  <textarea
    class="textarea flex-column p-2 form-control bg-dark border-darkgray text-lightgray mt-2 min-vh-50 max-vh-50 mw-100"
    rows="23"
    [ngModel]="warningJson"
    (ngModelChange)="setTestWarningsField($event)"
  ></textarea>

  <textarea
    class="textarea flex-column p-2 form-control bg-dark border-darkgray text-lightgray mt-2 min-vh-10 max-vh-10 mw-100"
    *ngIf="errorMessage.length"
    [ngModel]="errorMessage"
  ></textarea>
  <div
    cdkDragHandle
    class="rb-ic rb-ic-drag-handle flex-column text-gray fs-2xl z-3 position-absolute bottom-2 end-2 cursor-pointer hover-effect"
  ></div>
</div>
