import { Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { MapService } from 'src/app/core/services/map.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import {
  AreaTestWarningDataModel,
  TestWarningDataModel,
} from 'src/app/shared/models/test-warning-creation.model'
import { WarningDataModel } from 'src/app/shared/models/warning-event.model'
import { DataSimulationWarningService } from 'src/app/shared/services/data-simulation-warning.service'
import { firstValueFrom } from 'rxjs'
import { LayerSimulationWarningService } from 'src/app/shared/layers/layer-simulation-warning.service'
import { MapGeoJSONFeature } from 'maplibre-gl'

@Component({
  selector: 'app-simulation-warnings-import-export',
  templateUrl: './simulation-warnings-import-export.component.html',
})
export class SimulationWarningsImportExportComponent {
  @Input() isVisible: boolean = false
  @Output() closed = new EventEmitter<void>()

  public warningJson: any = ''
  public requestStatus: string = 'btn-hover'
  public importMessage: string = 'Import'
  public errorMessage: string = ''
  public shake = ''

  constructor(
    public appState: AppStateService,
    public mapService: MapService,
    private dataService: DataSimulationWarningService,
    private simulationTestLayerService: LayerSimulationWarningService,
  ) {}

  importTestWarnings() {
    try {
      const parsedWarnings = JSON.parse(this.warningJson)
      parsedWarnings.forEach(async (warning: any) => {
        if (warning.h3id) {
          await firstValueFrom(
            this.dataService.createAreaTestWarnings(warning as AreaTestWarningDataModel),
          )
        } else {
          await firstValueFrom(this.dataService.createTestWarnings(warning as TestWarningDataModel))
        }
      })
      this.requestStatus = 'btn-noncritical'
      this.importMessage = 'Initialized ...'
      setTimeout(() => {
        this.requestStatus = 'btn-hover'
        this.importMessage = 'Import'
      }, 1000)
      setTimeout(() => {
        this.simulationTestLayerService.reload()
      }, 5000)
      this.errorMessage = ''
    } catch (error: any) {
      this.shake = 'shake'
      this.requestStatus = 'btn-dangerous'
      this.importMessage = 'Error'
      this.errorMessage = error.toString()
      setTimeout(() => {
        this.shake = ''
        this.requestStatus = 'btn-hover'
        this.importMessage = 'Import'
      }, 1000)
    }
  }

  createImportableWarningJson(features: MapGeoJSONFeature[]) {
    // Query Features sometime returns duplicates due to tile borders. Therfore we need to filter out duplicates
    this.errorMessage = ''
    const uniqueFeatures = features.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((o) => {
          return (
            (o.properties as any).segmentId === (obj.properties as any).segmentId &&
            (o.properties as any).h3id === (obj.properties as any).h3id &&
            (o.properties as any).direction === (obj.properties as any).direction &&
            (o.properties as any).name === (obj.properties as any).name &&
            (o.properties as any).target === (obj.properties as any).target
          )
        }),
    )

    const testWarnings: (TestWarningDataModel | AreaTestWarningDataModel)[] = uniqueFeatures.map(
      (feature) => {
        const properties: WarningDataModel = feature.properties as WarningDataModel
        if (properties.h3id) {
          return {
            warningState: properties?.name,
            longitude: JSON.parse(properties.eventCoordinates)[0],
            latitude: JSON.parse(properties.eventCoordinates)[1],
            ttl: properties.expiryTime - properties.startTime,
            targets: [properties?.target],
            h3id: properties?.h3id,
            countryCode: properties?.countryCode,
          } as AreaTestWarningDataModel
        } else {
          return {
            segmentId: properties?.segmentId,
            countryCode: properties?.countryCode,
            warningState: properties?.name,
            longitude: JSON.parse(properties.eventCoordinates)[0],
            latitude: JSON.parse(properties.eventCoordinates)[1],
            ttl: properties.expiryTime - properties.startTime,
            class: properties?.roadClass,
            targets: [properties?.target],
            bidirectional: properties?.bidirectional,
            direction: properties?.direction,
          } as TestWarningDataModel
        }
      },
    )
    return JSON.stringify(testWarnings, undefined, 2)
  }

  exportVisibleTestWarnings() {
    const features = this.mapService.map.queryRenderedFeatures({
      layers: [`${Layers.SimWarning.name}-lines`, `${Layers.SimWarning.name}-hexagons`],
    })
    this.warningJson = this.createImportableWarningJson(features)
    this.errorMessage = ''
  }

  public setTestWarningsField(warningsJson: any) {
    this.warningJson = warningsJson
  }
}
