<div
  class="details position-absolute start-5 z-3 top-26 max-vh-70 overflow-auto"
  cdkDrag
  cdkDragBoundary="app-root"
  [class.d-none]="featureDetails.size <= 0"
>
  <app-details class="container-fluid p-0" (closed)="close()">
    <div class="p-2">
      <div class="row">
        <app-details-meta-category class="col gx-5" [category]="featureDetails.get('RoadSegment')">
        </app-details-meta-category>
      </div>

      <div class="row mt-2 gy-2 justify-content-start">
        <div class="col-12 text-white text-start mt-2">Select type:</div>
        <div class="d-flex justify-content-start gap-3">
          <ng-container *ngIf="isAccidentsLayerVisible">
            <ng-container *hasPermissionAny="Layers.VAWarning.permissions">
              <app-button-icon
                *ngVar="getMetricFromMap(metrics.Warnings_Accidents) as metric"
                [description]="metric.displayName"
                [isSelected]="selectedWarningTypeMetric === metric.key"
                [key]="metric.key"
                [icon]="metric.icon"
                (clicked)="setMetric($event)"
              ></app-button-icon>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isBdvLayerVisible">
            <ng-container *hasPermissionAny="Layers.BDVWarning.permissions">
              <app-button-icon
                *ngVar="getMetricFromMap(metrics.Warnings_BrokenDownVehicle) as metric"
                [description]="metric.displayName"
                [isSelected]="selectedWarningTypeMetric === metric.key"
                [key]="metric.key"
                [icon]="metric.icon"
                (clicked)="setMetric($event)"
              ></app-button-icon>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isTLLayerVisible">
            <ng-container *hasPermissionAny="Layers.TLWarning.permissions">
              <app-button-icon
                *ngVar="getMetricFromMap(metrics.Warnings_TractionLoss) as metric"
                [description]="metric.displayName"
                [isSelected]="selectedWarningTypeMetric === metric.key"
                [key]="metric.key"
                [icon]="metric.icon"
                (clicked)="setMetric($event)"
              ></app-button-icon>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isCSLayerVisible">
            <ng-container *hasPermissionAny="Layers.CSWarning.permissions">
              <app-button-icon
                *ngVar="getMetricFromMap(metrics.Warnings_ConstructionSite) as metric"
                [description]="metric.displayName"
                [isSelected]="selectedWarningTypeMetric === metric.key"
                [key]="metric.key"
                [icon]="metric.icon"
                (clicked)="setMetric($event)"
              ></app-button-icon>
            </ng-container>
          </ng-container>
        </div>
        <div class="col-12 text-white text-start mt-2">Select direction:</div>
        <div class="row mt-2 gy-2">
          <app-button-icon
            class="col-3 col-md-2"
            description="{{ directions.Along }}"
            [isSelected]="isDirectionAlong"
            key="{{ directions.Along }}"
            icon="rb-ic rb-ic-arrow-right-frame fs-2xl"
            (clicked)="toggleDirection(directions.Along)"
          >
          </app-button-icon>
          <app-button-icon
            class="col-3 col-md-2"
            description="{{ directions.Against }}"
            [isSelected]="isDirectionAgainst"
            key="{{ directions.Against }}"
            icon="rb-ic rb-ic-arrow-left-frame fs-2xl"
            (clicked)="toggleDirection(directions.Against)"
          ></app-button-icon>
        </div>
        <div class="col-12 text-white text-start mt-2">Duration (min):</div>
        <div class="input-group col-12 mt-2 p-1">
          <input
            type="number"
            class="form-control border-0 text-white bg-darkgray shadow-none"
            min="0"
            max="3600"
            [defaultValue]="15"
            [(ngModel)]="duration"
          />
        </div>
        <div class="col-12 text-white text-start mt-3">Target:</div>
        <div class="row justify-content-start" *ngFor="let target of allowedTargets">
          <div class="col-1 p-1">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="target.selected"
              (change)="this.validateScopes(target)"
            />
          </div>
          <div class="col-4 p-1">
            <label class="form-check-label">
              {{ target.name }}
            </label>
          </div>
        </div>
        <div class="col-12 p-1">
          <button
            ngClass="btn {{ requestStatus }}  {{ shake }}  text-lightgray miw-100 mt-2"
            (click)="createWarning()"
            [disabled]="this.disableButton"
            data-cy="create-warning-button"
          >
            {{ statusMessage }}
          </button>
        </div>
      </div>
    </div>
    <div
      cdkDragHandle
      class="rb-ic rb-ic-drag-handle text-gray fs-2xl z-3 position-absolute bottom-2 end-2 cursor-pointer hover-effect"
    ></div>
  </app-details>
</div>
