<div class="d-flex flex-column gap-2">
  <div class="d-flex gap-2 align-items-center">
    <span>{{ description }}</span>
    <!-- <span
      class="rb-ic rb-ic-user-watch-on"
      [class.rb-ic-watch-on]="isVisible"
    ></span> -->
  </div>
  <div class="d-flex flex-wrap column-gap-4 mb-2">
    <ng-content></ng-content>
  </div>
</div>
