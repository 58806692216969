import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { BadWeatherConditionPageComponent } from './pages/bad-weather-condition-page/bad-weather-condition-page.component'
import { SharedModule } from '../shared/shared.module'
import { BwcDetailsComponent } from './components/bwc-details/bwc-details.component'
import { BwcLegendComponent } from './components/bwc-legend/bwc-legend.component'
import { BwcLayerConfigComponent } from './components/bwc-layer-config/bwc-layer-config.component'
import { BwcRoadSegmentComponent } from './components/bwc-layer-categories/bwc-road-segment/bwc-road-segment.component'
import { BwcWeatherPredictionComponent } from './components/bwc-layer-categories/bwc-weather-prediction/bwc-weather-prediction.component'
import { BwcVehicleDataComponent } from './components/bwc-layer-categories/bwc-vehicle-data/bwc-vehicle-data.component'
import { BwcDetailsWeatherComponent } from './components/bwc-details-weather/bwc-details-weather.component'
import { BwcRawDetailsComponent } from './components/bwc-raw-details/bwc-raw-details.component'
import { DetailsAccordionComponent } from '../shared/components/details/details-accordion/details-accordion'
import { BwcHistoryControlComponent } from './components/bwc-history-control/bwc-history-control.component'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    BadWeatherConditionPageComponent,
    BwcHistoryControlComponent,
    BwcLegendComponent,
    BwcRawDetailsComponent,
    BwcDetailsComponent,
    BwcLayerConfigComponent,
    BwcRoadSegmentComponent,
    BwcWeatherPredictionComponent,
    BwcVehicleDataComponent,
    BwcDetailsWeatherComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    SharedModule,
    DetailsAccordionComponent,
    NgbTooltipModule,
  ],
})
export class BadWeatherConditionModule {}
