import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-details-toggle',
  templateUrl: './details-toggle.component.html',
})
export class DetailsToggleComponent {
  @Input() numberOfDetails: number = 0
  @Input() index: number = 0
  @Output() indexChanged = new EventEmitter<number>()

  toggleFeature(step: number) {
    if (this.numberOfDetails == this.index + step) {
      this.index = 0
    } else if (-1 == this.index + step) {
      this.index = this.numberOfDetails - 1
    } else {
      this.index += step
    }
    this.indexChanged.emit(this.index)
  }
}
