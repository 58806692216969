import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { AuthService } from 'src/app/core/services/auth.service'

@Component({
  selector: 'app-customer-data',
  templateUrl: './customer-data.component.html',
})
export class CustomerDataComponent {
  public Layers = Layers
  public isVALayerVisible: boolean | undefined
  public isBDVLayerVisible: boolean | undefined
  public isCSLayerVisible: boolean | undefined
  public isLVLayerVisible: boolean | undefined
  public isTLLayerVisible: boolean | undefined
  public isHRLayerVisible: boolean | undefined
  public isHSLayerVisible: boolean | undefined
  public isSWLayerVisible: boolean | undefined
  public onDestroy: boolean | undefined = false
  public Permissions = Permissions

  constructor(
    private appState: AppStateService,
    private authService: AuthService,
  ) {
    const layers = this.appState.getState().layers

    if (layers) {
      this.isVALayerVisible =
        layers?.includes(Layers.VAWarning.name) &&
        this.authService.hasPermission(Layers.VAWarning.permissions, 'any')
      this.isBDVLayerVisible =
        layers?.includes(Layers.BDVWarning.name) &&
        this.authService.hasPermission(Layers.BDVWarning.permissions, 'any')
      this.isCSLayerVisible =
        layers?.includes(Layers.CSWarning.name) &&
        this.authService.hasPermission(Layers.CSWarning.permissions, 'any')
      this.isLVLayerVisible =
        layers?.includes(Layers.LVWarning.name) &&
        this.authService.hasPermission(Layers.BDVWarning.permissions, 'any')
      this.isTLLayerVisible =
        layers?.includes(Layers.TLWarning.name) &&
        this.authService.hasPermission(Layers.TLWarning.permissions, 'any')
      this.isHRLayerVisible =
        layers?.includes(Layers.HRWarning.name) &&
        this.authService.hasPermission(Layers.HRWarning.permissions, 'any')
      this.isHSLayerVisible =
        layers?.includes(Layers.HSWarning.name) &&
        this.authService.hasPermission(Layers.HSWarning.permissions, 'any')
      this.isSWLayerVisible =
        layers?.includes(Layers.SWWarning.name) &&
        this.authService.hasPermission(Layers.SWWarning.permissions, 'any')
    } else {
      this.isVALayerVisible = this.authService.hasPermission(Layers.VAWarning.permissions, 'any')
      this.isBDVLayerVisible = this.authService.hasPermission(Layers.BDVWarning.permissions, 'any')
      //this.isCSLayerVisible = this.authService.hasPermission(Layers.CSWarning.permissions, 'any')
      this.isLVLayerVisible = this.authService.hasPermission(Layers.LVWarning.permissions, 'any')
      this.isTLLayerVisible = this.authService.hasPermission(Layers.TLWarning.permissions, 'any')
      this.isHRLayerVisible = this.authService.hasPermission(Layers.HRWarning.permissions, 'any')
      this.isHSLayerVisible = this.authService.hasPermission(Layers.HSWarning.permissions, 'any')
      this.isSWLayerVisible = this.authService.hasPermission(Layers.SWWarning.permissions, 'any')
    }

    this.updateQueryParameters()
    this.appState.stateChanged.pipe(takeUntilDestroyed()).subscribe(() => {})
  }

  toggleLayerVisbility(layerId: string) {
    switch (layerId) {
      case Layers.VAWarning.name:
        this.isVALayerVisible = !this.isVALayerVisible
        break
      case Layers.BDVWarning.name:
        this.isBDVLayerVisible = !this.isBDVLayerVisible
        break
      case Layers.CSWarning.name:
        this.isCSLayerVisible = !this.isCSLayerVisible
        break
      case Layers.LVWarning.name:
        this.isLVLayerVisible = !this.isLVLayerVisible
        break
      case Layers.TLWarning.name:
        this.isTLLayerVisible = !this.isTLLayerVisible
        break
      case Layers.HRWarning.name:
        this.isHRLayerVisible = !this.isHRLayerVisible
        break
      case Layers.HSWarning.name:
        this.isHSLayerVisible = !this.isHSLayerVisible
        break
      case Layers.SWWarning.name:
        this.isSWLayerVisible = !this.isSWLayerVisible
        break

      default:
        break
    }
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    const layers = this.appState
      .selectLayers()
      .filter(
        (layer) =>
          !layer.includes(Layers.BDVWarning.name) &&
          !layer.includes(Layers.VAWarning.name) &&
          !layer.includes(Layers.CSWarning.name) &&
          !layer.includes(Layers.TLWarning.name) &&
          !layer.includes(Layers.LVWarning.name) &&
          !layer.includes(Layers.HRWarning.name) &&
          !layer.includes(Layers.HSWarning.name) &&
          !layer.includes(Layers.SWWarning.name),
      )

    if (this.isVALayerVisible) {
      layers.push(Layers.VAWarning.name)
    }
    if (this.isBDVLayerVisible) {
      layers.push(Layers.BDVWarning.name)
    }
    if (this.isCSLayerVisible) {
      layers.push(Layers.CSWarning.name)
    }
    if (this.isLVLayerVisible) {
      layers.push(Layers.LVWarning.name)
    }
    if (this.isTLLayerVisible) {
      layers.push(Layers.TLWarning.name)
    }
    if (this.isHRLayerVisible) {
      layers.push(Layers.HRWarning.name)
    }
    if (this.isHSLayerVisible) {
      layers.push(Layers.HSWarning.name)
    }
    if (this.isSWLayerVisible) {
      layers.push(Layers.SWWarning.name)
    }
    this.appState.setState(AppPaths.Customer, {
      layers: layers?.toString(),
      metrics: undefined,
    })
  }
}
