import { MetricModel } from 'src/app/shared/models/metric.model'
import {
  createPaintExpression,
  getRoadMetricPaint,
  getVisibilityVoronoiPaint,
  getWindSpeedVoronoiPaint,
  getAirTempVoronoiPaint,
  getRainPercipitationVoronoiPaint,
  getSnowPercipitationVoronoiPaint,
} from '../utils/bwc-paint-utils'
import {
  bwcLegendRoadHazards,
  bwcLegendWeatherWindSpeed,
  bwcLegendWeatherRainFall,
  bwcLegendWeatherAirTemp,
  bwcLegendWeatherSnowFall,
  bwcLegendWeatherVisibility,
  bwcLegendVehicleData,
} from '../utils/bwc-legend-utils'
import { HazardTypes } from 'src/app/shared/enums/hazard-types.enum'
import { defaultExpression, defaultLegend } from 'src/app/shared/utils/metric.utils'

export enum BwcMetrics {
  RoadSegment_HeavySnow = 'rs_hs',
  RoadSegment_HeavyRain = 'rs_hr',
  RoadSegment_LowVisibility = 'rs_lv',
  RoadSegment_StrongWind = 'rs_sw',
  WeatherPrediction_PercipSnow = 'wp_ps',
  WeatherPrediction_PercipRain = 'wp_pr',
  WeatherPrediction_Visibility = 'wp_v',
  WeatherPrediction_WindSpeed = 'wp_ws',
  WeatherPrediction_AirTemp = 'wp_at',
  WeatherPrediction_Humidity = 'wp_rh',
  VehicleData_Rain = 'vd_hr',
  VehicleData_Fog = 'vd_f',
}

export const getMetricFromMap = (metric: BwcMetrics | string): MetricModel => {
  return (
    BwcMetricMap.get(metric) || {
      expression: defaultExpression,
      name: 'default',
      icon: 'question',
      key: 'default',
      displayName: 'Default',
      legend: defaultLegend,
    }
  )
}

export const BwcMetricMap = new Map<string, MetricModel>()
BwcMetricMap.set(BwcMetrics.RoadSegment_HeavySnow, {
  name: 'heavySnow',
  expression: createPaintExpression('heavySnow', getRoadMetricPaint('heavySnow')),
  icon: 'ice',
  key: BwcMetrics.RoadSegment_HeavySnow,
  displayName: 'Heavy Snow',
  legend: bwcLegendRoadHazards,
})
BwcMetricMap.set(BwcMetrics.RoadSegment_HeavyRain, {
  name: 'heavyRain',
  expression: createPaintExpression('heavyRain', getRoadMetricPaint('heavyRain')),
  icon: 'weather-rain-warning',
  key: BwcMetrics.RoadSegment_HeavyRain,
  displayName: 'Heavy Rain',
  legend: bwcLegendRoadHazards,
})
BwcMetricMap.set(BwcMetrics.RoadSegment_LowVisibility, {
  name: 'lowVisibility',
  expression: createPaintExpression('lowVisibility', getRoadMetricPaint('lowVisibility')),
  icon: 'weather-mist',
  displayName: 'Low Visibility',
  key: BwcMetrics.RoadSegment_LowVisibility,
  legend: bwcLegendRoadHazards,
})
BwcMetricMap.set(BwcMetrics.RoadSegment_StrongWind, {
  name: 'strongWind',
  expression: createPaintExpression('strongWind', getRoadMetricPaint('strongWind')),
  icon: 'wind-vane-moderate',
  key: BwcMetrics.RoadSegment_StrongWind,
  displayName: 'Strong Wind',
  legend: bwcLegendRoadHazards,
})
BwcMetricMap.set(BwcMetrics.WeatherPrediction_PercipSnow, {
  name: 'prs',
  expression: createPaintExpression('prs', getSnowPercipitationVoronoiPaint('prs')),
  icon: 'weather-snow',
  key: BwcMetrics.WeatherPrediction_PercipSnow,
  displayName: 'Prec. Snow',
  legend: bwcLegendWeatherSnowFall,
})

BwcMetricMap.set(BwcMetrics.WeatherPrediction_PercipRain, {
  name: 'prr ',
  expression: createPaintExpression('prr', getRainPercipitationVoronoiPaint('prr')),
  icon: 'weather-rain',
  key: BwcMetrics.WeatherPrediction_PercipRain,
  displayName: 'Prec. Rain ',
  legend: bwcLegendWeatherRainFall,
})

BwcMetricMap.set(BwcMetrics.WeatherPrediction_WindSpeed, {
  name: 'windSpeed',
  expression: createPaintExpression('windSpeed', getWindSpeedVoronoiPaint('windSpeed')),
  icon: 'weather-gale',
  key: BwcMetrics.WeatherPrediction_WindSpeed,
  displayName: 'Wind Speed',
  legend: bwcLegendWeatherWindSpeed,
})

BwcMetricMap.set(BwcMetrics.WeatherPrediction_Visibility, {
  name: 'visibility',
  expression: createPaintExpression('visibility', getVisibilityVoronoiPaint('visibility')),
  icon: 'car-street',
  key: BwcMetrics.WeatherPrediction_Visibility,
  displayName: 'Visibility',
  legend: bwcLegendWeatherVisibility,
})

BwcMetricMap.set(BwcMetrics.WeatherPrediction_AirTemp, {
  name: 'airTemp',
  expression: createPaintExpression('airTemp', getAirTempVoronoiPaint('airTemp')),
  icon: 'temperature',
  key: BwcMetrics.WeatherPrediction_AirTemp,
  displayName: 'Air Temp.',
  legend: bwcLegendWeatherAirTemp,
})

BwcMetricMap.set(BwcMetrics.VehicleData_Fog, {
  name: 'vehicleFog',
  expression: ['==', ['get', 'hazardType'], HazardTypes.Fog],
  icon: 'light-fog-warning',
  key: BwcMetrics.VehicleData_Fog,
  displayName: 'Fog',
  legend: bwcLegendVehicleData,
})

BwcMetricMap.set(BwcMetrics.VehicleData_Rain, {
  name: 'vehicleRain',
  expression: ['==', ['get', 'hazardType'], HazardTypes.HeavyRain],
  icon: 'windshield-wiper',
  key: BwcMetrics.VehicleData_Rain,
  displayName: 'Heavy Rain',
  legend: bwcLegendVehicleData,
})
