import { Component } from '@angular/core'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { MaintenanceService } from 'src/app/core/services/maintenance.service'
@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenancePageComponent {
  constructor(public appState: AppStateService) {}
}
