import { ExpressionSpecification } from 'maplibre-gl'

const getColor = (no: number): string => {
  const color = roadcondMap.get(no)
  return color ? color : '#a9a9a9'
}

const roadcondMap = new Map<number, string>()
roadcondMap.set(0, '#440154')
roadcondMap.set(1, '#ae2029')
roadcondMap.set(2, '#d40010')
roadcondMap.set(3, '#e80000')
roadcondMap.set(4, '#e04000')
roadcondMap.set(5, '#f06000')
roadcondMap.set(6, '#ff8030')
roadcondMap.set(7, '#F08000')
roadcondMap.set(8, '#F0A000')
roadcondMap.set(9, '#ffe000')
roadcondMap.set(10, '#b1d650')
roadcondMap.set(11, '#6ab256')
roadcondMap.set(12, '#2e8b57')
roadcondMap.set(13, '#fff')
roadcondMap.set(99, '#a9a9a9')

export const createPaintExpression = (
  metricName: string,
  paintExpression: ExpressionSpecification,
): ExpressionSpecification => {
  return [
    'case',
    ['to-boolean', ['feature-state', metricName]],
    paintExpression,
    '#b3b3b3', // if state is missing gray!
  ]
}

export const getRoadMetricPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'probability', ['object', ['feature-state', metricName]]], 0.7],
    getColor(1),
    getColor(12),
  ]
}

export const getSnowPercipitationVoronoiPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 1.5],
    getColor(1),
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 1.0],
    getColor(9),
    ['<', ['get', 'value', ['object', ['feature-state', metricName]]], 1.0],
    getColor(12),
    getColor(99),
  ]
}

export const getRainPercipitationVoronoiPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 5],
    getColor(1),
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 3],
    getColor(9),
    ['<', ['get', 'value', ['object', ['feature-state', metricName]]], 3],
    getColor(12),
    getColor(99),
  ]
}

export const getVisibilityVoronoiPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 1000],
    getColor(12),
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 500],
    getColor(9),
    ['<', ['get', 'value', ['object', ['feature-state', metricName]]], 500],
    getColor(1),
    getColor(99),
  ]
}

export const getWindSpeedVoronoiPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 16],
    getColor(1),
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 12],
    getColor(9),
    ['<', ['get', 'value', ['object', ['feature-state', metricName]]], 12],
    getColor(12),
    getColor(99),
  ]
}

export const getAirTempVoronoiPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], 4],
    getColor(12),
    ['>=', ['get', 'value', ['object', ['feature-state', metricName]]], -4],
    getColor(9),
    ['<', ['get', 'value', ['object', ['feature-state', metricName]]], -4],
    getColor(1),
    getColor(99),
  ]
}
