import { LegendModel } from 'src/app/shared/models/legend.model'

export const obstacleLegendVehicleData: LegendModel = {
  type: 'hazard',
  category: 'vehicle',
  items: [
    {
      description: 'Accidents',
      value: 'dangerous',
    },
    {
      description: 'BDV',
      value: 'slippery-hazard',
    },
  ],
}

export const obstacleLegendProviderData: LegendModel = {
  type: 'hazard',
  category: 'provider',
  items: [
    {
      description: 'Accidents',
      value: 'dangerous',
    },
    {
      description: 'BDV',
      value: 'slippery-hazard',
    },
  ],
}

export const roadHazardLegendObstacles: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'ok',
      value: 'noncritical',
    },
    {
      description: 'warning',
      value: 'dangerous',
    },
  ],
}
