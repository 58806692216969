import { Inject, Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { LngLat, LngLatLike, PointLike } from 'maplibre-gl'
import * as turf from '@turf/turf'
import { CurrentPositionModel } from '../models/current-position.model'
import { DebugService } from './debug.service'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from '../models'

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private geolocation: Geolocation | undefined
  private lastPosition: number[] | undefined
  private currentPosition: number[] | undefined
  private watchId: number | undefined
  public positionChanged$ = new Subject<CurrentPositionModel>()
  private positionOptions: PositionOptions

  constructor(
    private readonly debugService: DebugService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {
    this.positionOptions = {
      maximumAge: 5000,
      timeout: 5000,
      enableHighAccuracy: this.config.highAccuracyPositionEnabled,
    }
  }

  public initialize(): void {
    this.geolocation = navigator.geolocation
  }

  public configure(): void {
    this.stopWatchingPosition()
    this.startWatchingPosition()
  }

  public async getPosition(): Promise<CurrentPositionModel | undefined> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(this.getMappedPositionFromGeolocation(pos))
        },
        (errorResponse) => {
          this.debugService.writeToLog(`GET: ${errorResponse.message}`)
          reject(errorResponse)
        },
        this.positionOptions,
      )
    })
  }

  public startWatchingPosition(): void {
    if (!this.geolocation) {
      throw new Error('Please call initialize on the LocationService first')
    }

    this.watchId = navigator.geolocation.watchPosition(
      (pos) => {
        this.handleNewPosition(pos)
      },
      (err) => {
        this.handleNewPositionError(err)
      },
      this.positionOptions,
    )
  }

  public stopWatchingPosition(): void {
    this.currentPosition = []
    this.lastPosition = []
    if (this.watchId) {
      navigator.geolocation.clearWatch(this.watchId)
    }
  }

  private handleNewPosition(pos: any): void {
    this.debugService.writeToLog(
      `lon: ${pos.coords.longitude.toFixed(3)}..., lat ${pos.coords.latitude.toFixed(3)}..., acc ${
        pos.coords.accuracy
      }
      `,
    )
    this.lastPosition = this.currentPosition
    this.currentPosition = [pos.coords.longitude, pos.coords.latitude]
    this.positionChanged$.next(this.getMappedPositionFromGeolocation(pos))
  }

  private getMappedPositionFromGeolocation(geolocation: any): CurrentPositionModel {
    const { latitude, longitude } = geolocation.coords
    const heading = this.getHeading()
    const speed = null

    const point: PointLike = [longitude, latitude]
    const lngLat: LngLatLike = {
      lat: latitude,
      lng: longitude,
    }

    const buffered = turf.buffer(turf.point(point), 50, { units: 'meters' })
    const bbox = turf.bbox(buffered)
    return {
      bbox: bbox,
      exact: lngLat,
      heading: heading,
    }
  }

  private handleNewPositionError(error: GeolocationPositionError): void {
    console.log(error.message)
    this.debugService.writeToLog(`WATCH: ${error.message}`)
  }

  public getHeading(): number | undefined {
    if (
      this.currentPosition &&
      this.currentPosition.length > 0 &&
      this.lastPosition &&
      this.lastPosition.length > 0
    ) {
      return turf.bearing(this.lastPosition, this.currentPosition)
    }
    return undefined
  }
}
