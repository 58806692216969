import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { addSeconds, differenceInMinutes, isWithinInterval, subSeconds } from 'date-fns'
import { combineLatest, firstValueFrom } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { FeatureCollection, Point } from 'geojson'
import { XyzTileModel } from '../models/xyz-tile.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { WarningDatasources, WarningTypes } from '../enums/warning-types.enum'
import { WarningDataModel } from '../models/warning-event.model'
import { addMinutes } from 'date-fns'
import { Feature } from '@turf/turf'

@Injectable()
export class DataCustomerWarningService {
  private warningEventsData: FeatureCollection<any, WarningDataModel>[] = [
    // Points
    {
      type: 'FeatureCollection',
      features: [],
    },
    // Lines
    {
      type: 'FeatureCollection',
      features: [],
    },

    // Polygons
    {
      type: 'FeatureCollection',
      features: [],
    },
  ]
  public isDataInitialized = false

  constructor(
    private readonly http: HttpClient,
    private appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {}

  async loadWarningEvents(
    warningTypes: WarningTypes[],
    currentDatasource: WarningDatasources,
    timeStamp?: Date,
    xyzTiles?: XyzTileModel[],
  ) {
    if (this.appState.isLoading) return
    this.isDataInitialized = true
    this.warningEventsData = [
      {
        type: 'FeatureCollection',
        features: [],
      },
      {
        type: 'FeatureCollection',
        features: [],
      },
      {
        type: 'FeatureCollection',
        features: [],
      },
    ]

    if (xyzTiles) {
      const warningEventsRequests$ = xyzTiles.map(async (tile) => {
        let warnings: FeatureCollection<any, WarningDataModel> | undefined = undefined
        if (timeStamp) {
          if (this.appState.getState().zoom! >= this.config.history_min_zoom_level) {
            warnings = await this.loadHistoricWarningEvents(
              tile,
              warningTypes,
              timeStamp,
              currentDatasource,
            )
          }
        } else {
          warnings = await this.loadWarningEventsData(tile, warningTypes, currentDatasource)
        }
        return warnings
      })
      const warningEvents$ = combineLatest(warningEventsRequests$)
      const warningEvents = await firstValueFrom(warningEvents$)

      this.warningEventsData[0].features = []
      this.warningEventsData[1].features = []
      this.warningEventsData[2].features = []

      warningEvents.map((warnings) => {
        if (warnings) {
          this.warningEventsData[1].features = this.warningEventsData[1].features.concat(
            warnings.features.filter((feature) => {
              feature.properties.expireIn = Math.abs(
                differenceInMinutes(
                  new Date(Date.now()),
                  addMinutes(new Date(feature.properties.expiryTime), 5),
                ),
              )
              feature.id = Number(feature.id)
              return feature.geometry.type == 'LineString'
            }),
          )
          this.warningEventsData[2].features = this.warningEventsData[2].features.concat(
            warnings.features.filter((feature) => {
              feature.properties.expireIn = Math.abs(
                differenceInMinutes(
                  new Date(Date.now()),
                  addMinutes(new Date(feature.properties.expiryTime), 5),
                ),
              )
              feature.id = Number(feature.id)
              return feature.geometry.type == 'Polygon' && feature.properties.isCancelled == false
            }),
          )
        }
      })
      for (let feature of this.warningEventsData[1].features.concat(
        this.warningEventsData[2].features,
      )) {
        ;(feature.properties.featureId = Number(feature.id)),
          this.warningEventsData[0].features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: feature.properties.eventCoordinates as any,
            },
            id: Number(feature.id),

            properties: feature.properties,
          } as Feature<any, WarningDataModel>)
      }
    }
    return this
  }

  async loadWarningEventsData(
    xyzTiles: XyzTileModel,
    warningTypes: WarningTypes[],
    currentDatasource: WarningDatasources,
  ): Promise<FeatureCollection<any, WarningDataModel>> {
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'json',
      }),
    }

    const warningType = warningTypes.join('&warningTypes=')
    const url = `${this.config.backend_url}rcs/warnings/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}?warningTypes=${warningType}&warningDatasource=${currentDatasource}`
    const result = await firstValueFrom(
      this.http.get<FeatureCollection<Point, WarningDataModel>>(url.toString(), httpOptions),
    )
    return result
  }

  async loadHistoricWarningEvents(
    xyzTiles: XyzTileModel,
    warningTypes: WarningTypes[],
    timeStamp: Date,
    currentDatasource: WarningDatasources,
  ): Promise<FeatureCollection<Point, WarningDataModel>> {
    const timestampStart = subSeconds(timeStamp, 3600).toISOString()
    const timestampEnd = addSeconds(timeStamp, 3600).toISOString()
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'json',
      }),
    }
    const warningType = warningTypes.join('&warningTypes=')
    const url = `${this.config.backend_url}rcs/warnings/historic/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}?timestampStart=${timestampStart}&timestampEnd=${timestampEnd}&warningTypes=${warningType}&warningDatasource=${currentDatasource}`
    const result = await firstValueFrom(
      this.http.get<FeatureCollection<Point, WarningDataModel>>(url.toString(), httpOptions),
    )
    return result
  }

  getRawWarningEvents(timestamp?: Date): FeatureCollection<any, WarningDataModel>[] {
    if (timestamp === undefined) {
      return this.warningEventsData
    } else {
      let tempVehicleData: FeatureCollection<any, WarningDataModel>[] = [
        {
          type: 'FeatureCollection',
          features: [],
        },
        {
          type: 'FeatureCollection',
          features: [],
        },
        {
          type: 'FeatureCollection',
          features: [],
        },
      ]
      tempVehicleData[0].features = this.warningEventsData[0].features.filter((event) => {
        return isWithinInterval(new Date(timestamp), {
          start: new Date(event.properties.startTime),
          end: new Date(event.properties.expiryTime),
        })
      })
      tempVehicleData[1].features = this.warningEventsData[1].features.filter((event) => {
        return isWithinInterval(new Date(timestamp), {
          start: new Date(event.properties.startTime),
          end: new Date(event.properties.expiryTime),
        })
      })
      tempVehicleData[2].features = this.warningEventsData[2].features.filter((event) => {
        return isWithinInterval(new Date(timestamp), {
          start: new Date(event.properties.startTime),
          end: new Date(event.properties.expiryTime),
        })
      })
      return tempVehicleData
    }
  }
}
