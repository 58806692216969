<div class="position-absolute bottom-0 w-100">
  <div class="position-relative">
    <app-mobile-details [features]="features"></app-mobile-details>
    <app-layer-control
      class="position-absolute top-n3 start-3"
      [isLayerSelectionVisibile]="isLayerSelectionVisible"
      (visibilityToggled)="toggleLayerSwitchVisibility()"
      [isLayerConfigAvailable]="false"
      size="xl"
    >
      <ng-container layers>
        <app-layer-control-item
          *ngVar="getMetricFromMap(selectedRoadMetric) as metric"
          [description]="metric.displayName"
          [isLayerVisibile]="isRoadConditionLayerVisibile"
          [layerId]="layerIds.RoadSegment.name"
          [icon]="metric.icon"
          (visibilityToggled)="toggleLayerVisbility($event)"
        ></app-layer-control-item>
        <app-layer-control-item
          *ngVar="getMetricFromMap(selecteWeatherMetric) as metric"
          [description]="metric.displayName"
          [isLayerVisibile]="isWeatherLayerVisible"
          [layerId]="layerIds.WeatherPrediction.name"
          [icon]="metric.icon"
          (visibilityToggled)="toggleLayerVisbility($event)"
        ></app-layer-control-item>
        <app-layer-control-item
          description="Heavy Rain"
          [isLayerVisibile]="isVehicleLayerVisible"
          [layerId]="layerIds.VehicleDataRain.name"
          icon="windshield-wiper"
          (visibilityToggled)="toggleLayerVisbility($event)"
        ></app-layer-control-item>
        <app-layer-control-item
          description="Warnings"
          [isLayerVisibile]="isAreaWarningsVisible"
          layerId="warnings"
          icon="alert-warning"
          (visibilityToggled)="toggleLayerVisbility($event)"
        ></app-layer-control-item>
      </ng-container>
    </app-layer-control>
    <div
      class="position-absolute top-n3 end-3 bg-dark border border-darkgray p-2 rounded-2 d-flex align-items-center"
    >
      <span
        class="rb-ic rb-ic-route fs-xl"
        [ngClass]="isFollowPositionActive ? 'text-white' : 'text-gray'"
        (click)="toggleFollowPosition()"
      >
      </span>
    </div>
  </div>
</div>

<div class="position-absolute bottom-0 start-1">
  <span
    class="rb-ic rb-ic-dot fs-2xs"
    [ngClass]="isScreenLockActive ? 'text-gray' : 'text-dangerous'"
    (click)="debugService.toggleDebugVisibility()"
  ></span>
</div>

<div class="position-absolute bottom-0 end-1 fs-3xs">
  <a href="https://www.maptiler.com/copyright/" class="text-gray" target="_blank">© MapTiler</a>
  <a href="https://www.openstreetmap.org/copyright" class="text-gray" target="_blank"
    >© OpenStreetMap contributors</a
  >
</div>
