<div ngbAccordion class="">
  <div ngbAccordionItem [collapsed]="true">
    <h3 ngbAccordionHeader>
      <button class="fs-lg" ngbAccordionButton>Raw</button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <pre>{{ rawFeature }}</pre>
        </ng-template>
      </div>
    </div>
  </div>
</div>
