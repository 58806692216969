<div class="d-flex flex-column">
  <input
    type="range"
    class="form-range"
    min="{{ min }}"
    max="{{ max }}"
    step="{{ step }}"
    id="customRange"
    #range
    value="{{ timespan }}"
    (input)="timeSpanToggled.emit(range.value)"
    [disabled]="disabled"
  />
  <div class="position-relative">
    <div
      class="position-absolute translate-middle-x start-50 d-flex justify-content-center align-items-center text-gray fs-sm"
    >
      {{ calculateTimeStamp(range.value) }}
    </div>
  </div>
</div>
