<div
  class="position-absolute details start-5 z-3 top-26 mw-30 max-vh-70 overflow-auto"
  cdkDrag
  cdkDragBoundary="app-root"
  [class.d-none]="details.size <= 0"
>
  <app-details
    (closed)="close()"
    (selectedItemChanged)="toggleFeature($event)"
    [numberOfDetails]="features.length"
    [index]="index"
  >
    <app-details-category [category]="details.get('RawVehicleData')"></app-details-category>
    <details-accordion-basic [rawFeature]="rawFeature"></details-accordion-basic>
    <app-details-links [rawFeature]="rawFeature" [zoomLevel]="zoomLevel"></app-details-links>
  </app-details>
  <div
    cdkDragHandle
    class="rb-ic rb-ic-drag-handle text-gray fs-2xl z-3 position-absolute bottom-2 end-2 cursor-pointer hover-effect"
  ></div>
</div>
