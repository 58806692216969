import { Inject, Injectable } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import {
  VectorSourceSpecification,
  LineLayerSpecification,
  ExpressionSpecification,
} from 'maplibre-gl'
import { Subject, takeUntil } from 'rxjs'
import { LayerBaseServiceModel } from 'src/app/core/models'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { AppConfigModel } from 'src/app/core/models'
import { APP_CONFIG } from 'src/app/app.config'
import { DataRoadSegmentService } from '../services/data-road-segment.service'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from '../layers-config/layers'
import { MetricModel } from '../models/metric.model'
import { addMinutes } from 'date-fns'

@Injectable()
export class LayerRoadSegmentService implements LayerBaseServiceModel {
  public sourceId = 'static-segments-source'
  public layerId = Layers.RoadSegment.name
  public sourceLayerName = 'ways_layer'
  public isLayerVisible = false
  private cancelRequests$ = new Subject<void>()
  private cleanUp$ = new Subject<void>()
  private maxZoomAvailable = 15
  private minZoomAvailable = 4
  private minZoom = 4
  private colorExpression: ExpressionSpecification | string = '#a9a9a9'
  private timestamp: Date | undefined
  private timespan: number | undefined
  public selectedFeatureId: string = ''

  private roadSegmentVectorSource: VectorSourceSpecification = {
    type: 'vector',
    tiles: [`${this.config.road_condition.road_segment_tile_url}?key=${this.config.maptiler_key}`],
    maxzoom: this.maxZoomAvailable,
    minzoom: this.minZoomAvailable,
  }

  private roadSegmentDataLayerSpecification: LineLayerSpecification = {
    id: this.layerId,
    minzoom: this.minZoom,
    type: 'line',
    source: this.sourceId, // reference the data source
    'source-layer': this.sourceLayerName,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: 'none',
    },
    paint: {
      'line-color': this.colorExpression,
      'line-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        6,
        ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
        6,
        2,
      ],
    },
  }

  constructor(
    private mapService: MapService,
    private mapEventsService: MapEventsService,
    private mapDataRoadSegmentsService: DataRoadSegmentService,
    private appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    @Inject('metrics')
    private getMetricFromMap: (metric: string) => MetricModel,
  ) {
    if (mapService.isMapReady) {
      this.initializeLayer()
    } else {
      this.mapService.mapReadyChanged.subscribe(() => {
        this.initializeLayer()
      })
    }
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe(() => {
      this.evaluateState()
    })
  }

  ngOnDestroy() {
    this.mapService.map.removeFeatureState({
      source: this.sourceId,
      sourceLayer: this.sourceLayerName,
    })
    this.cancelRequests()
    this.cleanUp$.next()
    this.mapEventsService.detachMapEvents(this)

    this.mapService.map?.removeLayer(this.layerId)
    this.mapService.map?.removeSource(this.sourceId)
  }

  private async initializeLayer() {
    this.mapService.map?.addSource(this.sourceId, this.roadSegmentVectorSource)
    this.mapService.map?.addLayer(this.roadSegmentDataLayerSpecification)
    this.mapEventsService.registerForMapEvents(this, true)
    this.evaluateState()
  }

  public updateLayer() {
    if (this.mapService.isMapReady && this.isLayerVisible) {
      this.mapService.map.removeFeatureState({
        source: this.sourceId,
        sourceLayer: this.sourceLayerName,
      })

      const queryTimestamp =
        this.timestamp && this.timespan ? addMinutes(this.timestamp, this.timespan) : this.timestamp
      if (this.isLayerVisible) {
        this.mapDataRoadSegmentsService.getRoadSegments(queryTimestamp).forEach((roadSegments) => {
          if (this.isLayerVisible) {
            for (let roadSegment of roadSegments) {
              this.mapService.map.setFeatureState(
                {
                  source: this.sourceId,
                  sourceLayer: this.sourceLayerName,
                  id: roadSegment[0],
                },
                {
                  roadCondition: roadSegment[1].roadCondition,
                  friction: roadSegment[1].friction,
                  wft: roadSegment[1].wft,
                  timestamp: roadSegment[1].timestamp,
                  heavyRain: roadSegment[1].warnings?.find(
                    (warning) => warning.name == 'heavyRain',
                  ),
                  heavySnow: roadSegment[1].warnings?.find(
                    (warning) => warning.name == 'heavySnow',
                  ),
                  lowVisibility: roadSegment[1].warnings?.find(
                    (warning) => warning.name == 'lowVisibility',
                  ),
                  strongWind: roadSegment[1].warnings?.find(
                    (warning) => warning.name == 'strongWind',
                  ),
                  tractionLoss: roadSegment[1].warnings?.find(
                    (warning) => warning.name == 'tractionLoss',
                  ),
                },
              )
            }
          } else {
            return
          }
        })
      } else {
        return
      }
    }
  }

  private evaluateState() {
    if (this.appState.getState().layers?.includes(Layers.RoadSegment.name)) {
      this.setVisibility(true)
    } else {
      this.setVisibility(false)
    }

    // if layer is not visible stop all events
    if (this.isLayerVisible === false) return

    if (!this.timestamp) this.timestamp = this.appState.selectTimestamp()
    if (!this.timespan) this.timespan = this.appState.getState().timespan
    if (this.appState.getState().metrics) {
      const roadMetrics = this.appState.selectMetrics(Layers.RoadSegment.name)
      if (roadMetrics) {
        if (this.mapService.isMapReady) {
          this.setColorExpression(
            this.getMetricFromMap(roadMetrics[0]).expression as ExpressionSpecification,
          )
        }
      }
    }

    if (!this.mapDataRoadSegmentsService.isDataInitialized) {
      this.reload()
    }

    if (
      this.appState.hasLayerVisibilityChanged(Layers.RoadSegment.name) &&
      !this.appState.getState().historyEnabled
    ) {
      this.reload()
    }

    if (this.appState.hasMapBoundsChanged() && !this.appState.getState().historyEnabled) {
      this.reload()
    }

    if (this.appState.hasTimestampChanged()) {
      this.timestamp = this.appState.selectTimestamp()
      this.reload()
    }

    if (this.appState.hasTimespanChanged()) {
      this.timespan = this.appState.state.timespan
      this.updateLayer()
    }
    if (this.appState.hasSelectedFeatureIdChanged()) {
      this.selectedFeatureId = this.appState.getState().selectedFeatureId ?? ''
      this.mapService.map.setPaintProperty(this.layerId, 'line-width', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        6,
        ['==', ['id'], Number(this.selectedFeatureId)],
        6,
        2,
      ])
    }
  }

  public async reload() {
    this.cancelRequests()
    if (
      this.mapService.isMapReady &&
      this.isLayerVisible &&
      !this.appState.isLoading &&
      this.mapService.map.getZoom() >= this.minZoom
    ) {
      const xyzTiles = this.mapService.getXyzTiles(this.maxZoomAvailable, this.minZoomAvailable)
      await this.mapDataRoadSegmentsService.loadRoadSegments(xyzTiles, this.timestamp)
      this.updateLayer()
    }
  }

  setVisibility(isVisible: boolean): void {
    this.isLayerVisible = isVisible
    if (this.mapService.isMapReady) {
      if (this.mapService.getLayerVisibilty(this.layerId) != this.isLayerVisible) {
        this.mapService.setLayerVisibility(this.layerId, isVisible)
        if (!isVisible) {
          this.cancelRequests()
        }
      }
    }
  }

  public setColorExpression(expression: ExpressionSpecification | string) {
    this.colorExpression = expression
    if (this.mapService.isMapReady) {
      this.mapService.map.setPaintProperty(this.layerId, 'line-color', expression)
    }
  }

  private cancelRequests() {
    this.cancelRequests$.next()
    this.cancelRequests$.complete()
    this.cancelRequests$ = new Subject()
  }
}
