export enum MapIcons {
  VA = 'VehicleAccident',
  BDV = 'BrokenDownVehicle',
  CS = 'ConstructionSite',
  TL = 'TractionLoss',
  LV = 'LowVisibility',
  HR = 'HeavyRain',
  HS = 'HeavySnow',
  SW = 'StrongWind',
  TVA = 'TestVehicleAccident',
  TBDV = 'TestBrokenDownVehicle',
  TCS = 'TestConstructionSite',
  TTL = 'TestTractionLoss',
  TLV = 'TestLowVisibility',
  THR = 'TestHeavyRain',
  THS = 'TestHeavySnow',
  TSW = 'TestStrongWind',
  WC = 'Webcams',
}
