import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MapComponent } from './components/map/map.component'
import { RbLiveClipComponent } from './components/rb-live-clip/rb-live-clip.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular'
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './utils/msal-utils'
import { RbUiComponentsModule } from '@inst-iot/bosch-angular-ui-components'
import { AppRoutingModule } from '../app-routing.module'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AccountComponent } from './components/account/account.component'
import { AppSelectionComponent } from './components/app-selection/app-selection.component'
import { APP_CONFIG } from '../app.config'
import { DebugComponent } from './components/debug/debug.component'
import { SharedModule } from '../shared/shared.module'
import { LoaderInterceptor } from './interceptor'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { DemoService } from './services/demo.service'

@NgModule({
  declarations: [
    MapComponent,
    RbLiveClipComponent,
    NotFoundComponent,
    AccountComponent,
    AppSelectionComponent,
    DebugComponent,
  ],
  imports: [CommonModule, AppRoutingModule, RbUiComponentsModule, SharedModule, NgbTooltipModule],
  providers: [
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    DemoService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  exports: [
    MapComponent,
    RbLiveClipComponent,
    NotFoundComponent,
    AccountComponent,
    AppSelectionComponent,
    DebugComponent,
  ],
})
export class CoreModule {}
