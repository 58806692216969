import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { addSeconds, isWithinInterval, subSeconds } from 'date-fns'
import { combineLatest, firstValueFrom } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { LngLatBounds } from 'maplibre-gl'
import { FeatureCollection, Point } from 'geojson'
import { XyzTileModel } from '../models/xyz-tile.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { HazardTypes } from '../enums/hazard-types.enum'
import { HazardSources } from '../enums/hazard-categories.enum'
import { RawDataModel } from '../models/raw-event.model'

@Injectable()
export class DataRawEventsService {
  private rawEventsData: FeatureCollection<Point, RawDataModel> = {
    type: 'FeatureCollection',
    features: [],
  }
  public isDataInitialized = false

  constructor(
    private readonly http: HttpClient,
    private appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private hazardType: HazardTypes,
    private hazardSource: HazardSources,
  ) {}

  async loadRawEvents(timeStamp?: Date, boundingBox?: LngLatBounds, xyzTiles?: XyzTileModel[]) {
    if (this.appState.isLoading) return

    this.isDataInitialized = true
    this.rawEventsData = { type: 'FeatureCollection', features: [] }

    if (timeStamp && xyzTiles) {
      this.rawEventsData.features = []
      const rawEventaRequests$ = xyzTiles.map(async (tile) => {
        let data: FeatureCollection<Point, RawDataModel> | undefined = undefined
        if (this.appState.getState().zoom! >= this.config.history_min_zoom_level) {
          data = await this.loadHistoricRawEvents(tile, this.hazardType, timeStamp)
        }
        return data
      })
      const rawEvents$ = combineLatest(rawEventaRequests$)
      const rawEvents = await firstValueFrom(rawEvents$)
      rawEvents.map((res) => {
        if (res) {
          this.rawEventsData.features = this.rawEventsData?.features.concat(res.features)
        }
      })
    } else {
      if (boundingBox) {
        const data = await this.loadRawDataEvents(boundingBox, this.hazardType)
        if (data) {
          this.rawEventsData = data
        }
      }
    }
    return this
  }

  async loadHistoricRawEvents(
    xyzTiles: XyzTileModel,
    hazardType: string,
    timeStamp: Date,
  ): Promise<FeatureCollection<Point, RawDataModel>> {
    const timestampStart = subSeconds(timeStamp, 3600).toISOString()
    const timestampEnd = addSeconds(timeStamp, 3600).toISOString()
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'json',
      }),
    }

    const url = `${this.config.backend_url}rcs/hazards/${this.hazardSource}/historic/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}?timestampStart=${timestampStart}&timestampEnd=${timestampEnd}&hazardTypes=${hazardType}`
    const result = await firstValueFrom(this.http.get<any>(url.toString(), httpOptions))
    return result
  }

  async loadRawDataEvents(
    boundingBox: LngLatBounds,
    hazardType: string,
  ): Promise<FeatureCollection<Point, RawDataModel>> {
    const minLat = boundingBox.getSouth()
    const maxLat = boundingBox.getNorth()
    const minLon = boundingBox.getWest()
    const maxLon = boundingBox.getEast()
    const url = new URL(`${this.config.backend_url}rcs/hazards/${this.hazardSource}`)
    url.searchParams.set('minLat', minLat.toString())
    url.searchParams.set('maxLat', maxLat.toString())
    url.searchParams.set('minLon', minLon.toString())
    url.searchParams.set('maxLon', maxLon.toString())
    url.searchParams.append('hazardTypes', hazardType)

    // const url = `${location.origin}/assets/testdata/rcs-heavy-rain.json`
    const result = await firstValueFrom(
      this.http.get<FeatureCollection<Point, any>>(url.toString()),
    )
    return result
  }

  getRawEvents(timestamp?: Date): FeatureCollection<Point, RawDataModel> {
    if (timestamp === undefined) {
      return this.rawEventsData
    } else {
      let tempVehicleData: FeatureCollection<Point, RawDataModel> = {
        type: 'FeatureCollection',
        features: [],
      }
      tempVehicleData.features = this.rawEventsData.features.filter((event) => {
        return isWithinInterval(new Date(timestamp), {
          start: new Date(event.properties.startTimestamp),
          end: new Date(event.properties.endTimestamp),
        })
      })
      return tempVehicleData
    }
  }
}
