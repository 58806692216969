import { ExpressionSpecification } from 'maplibre-gl'
import { LegendModel } from '../models/legend.model'

export const defaultLegend: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'default',
      value: 'dangerous',
    },
  ],
}

export const defaultExpression: ExpressionSpecification = ['case', true, '#a9a9a9', '#a9a9a9']
