import { ExpressionSpecification } from 'maplibre-gl'

export const createPaintExpression = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['to-boolean', ['feature-state', metricName]],
    '#ae2029',
    '#2e8b57', // if state is missing gray!
  ]
}
