export enum WarningTypes {
  LowVisibility = 'LV',
  BrokendownVehicle = 'BDV',
  VehicleAccident = 'VA',
  TractionLoss = 'TL',
  ConstructionSite = 'CS',
  HeavyRain = 'HR',
  HeavySnow = 'HS',
  StrongWind = 'SW',
}

export enum WarningDatasources {
  Rcs = 'warnings.rcs',
}

export enum TestWarningTypes {
  area = 'area',
  roadSegment = 'roadSegment',
}
