import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { addMinutes, startOfToday, subMinutes, toDate } from 'date-fns'
import { combineLatest, firstValueFrom, from } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { RoadSegmentModel } from '../models/road-segment.model'
import { Data } from '@angular/router'
import { isEqual } from 'lodash'
import { XyzTileModel } from '../models/xyz-tile.model'
import { AppStateService } from 'src/app/core/services/app-state.service'

@Injectable()
export class DataRoadSegmentService {
  private roadSegmentData = new Map<XyzTileModel, RoadSegmentModel[]>()
  public isDataInitialized = false

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private appState: AppStateService,
  ) {}

  async loadRoadSegments(xyzTiles: XyzTileModel[], timeStamp?: Date) {
    if (this.appState.isLoading) return

    this.isDataInitialized = true
    this.roadSegmentData = new Map<XyzTileModel, RoadSegmentModel[]>()

    const roadSegmentRequests$ = xyzTiles.map(async (tile) => {
      let data: RoadSegmentModel[] = []
      if (timeStamp) {
        if (this.appState.getState().zoom! >= this.config.history_min_zoom_level) {
          data = await this.loadHistoricRoadSegmentsForTile(tile, timeStamp)
        }
      } else {
        data = await this.loadRoadSegmentsForTile(tile)
      }
      return {
        key: tile,
        data: data,
      }
    })
    const roadSegments$ = combineLatest(roadSegmentRequests$)
    const roadSegments = await firstValueFrom(roadSegments$)
    roadSegments.map((res) => {
      if (res && res.data != null) {
        this.roadSegmentData.set(res.key, res.data)
      }
    })
    return this
  }

  async loadHistoricRoadSegmentsForTile(
    xyzTiles: XyzTileModel,
    timeStamp: Date,
  ): Promise<RoadSegmentModel[]> {
    const timestampStart = subMinutes(timeStamp, 30).toISOString()
    const timestampEnd = addMinutes(timeStamp, 30).toISOString()
    const url = `${this.config.backend_url}rcs/data/historic/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}?timestampStart=${timestampStart}&timestampEnd=${timestampEnd}`
    const result = await firstValueFrom(this.http.get<RoadSegmentModel[]>(url))
    return result
  }

  async loadRoadSegmentsForTile(xyzTiles: XyzTileModel): Promise<RoadSegmentModel[]> {
    const url = `${this.config.backend_url}rcs/data/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}`
    const result = await firstValueFrom(this.http.get<RoadSegmentModel[]>(url))
    return result
  }

  getRoadSegments(timestamp?: Data): Map<XyzTileModel, RoadSegmentModel[]> {
    const resultMap = new Map<XyzTileModel, RoadSegmentModel[]>()
    if (timestamp === undefined) {
      return this.roadSegmentData
    }
    this.roadSegmentData.forEach((roadSegment, tile) => {
      const filteredData = roadSegment.filter((roadResponse) =>
        isEqual(toDate(roadResponse[2] ? new Date(roadResponse[2]) : startOfToday()), timestamp),
      )
      resultMap.set(tile, filteredData)
    })
    return resultMap
  }
}
