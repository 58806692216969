import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { CustomerPageComponent } from './customer-page/customer-page.component'
import { CustomerWarningsDetailsComponent } from './components/customer-warnings-details/customer-warnings-details.component'
import { CustomerLayerConfigComponent } from './components/customer-layer-config/customer-layer-config.component'
import { CustomerDataComponent } from './components/customer-layer-categories/customer-data/customer-data.component'
import { SharedModule } from '../shared/shared.module'
import { DetailsAccordionComponent } from '../shared/components/details/details-accordion/details-accordion'
import { NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    CustomerPageComponent,
    CustomerDataComponent,
    CustomerWarningsDetailsComponent,
    CustomerLayerConfigComponent,
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbDropdownModule,
    DragDropModule,
    FormsModule,
    SharedModule,
    DetailsAccordionComponent,
  ],
})
export class CustomerModule {}
