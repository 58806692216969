<div
  class="details position-absolute start-5 z-3 top-26 max-vh-70 overflow-auto"
  cdkDrag
  cdkDragBoundary="app-root"
  [class.d-none]="details.size <= 0"
  data-cy="warning-details"
>
  <app-details
    class="container-fluid p-0"
    (closed)="close()"
    (selectedItemChanged)="toggleFeature($event)"
    [numberOfDetails]="features.length"
    [index]="index"
  >
    <div class="p-2">
      <div class="row">
        <app-details-meta-category class="col gx-5" [category]="details.get('RawMetric')">
        </app-details-meta-category>

        <details-accordion-basic
          class="col-12 p-1 mt-2"
          [rawFeature]="rawFeature"
        ></details-accordion-basic>
        <div class="col-12 p-1">
          <a
            ngClass="btn {{ requestStatus }}  {{ shake }} text-lightgray miw-100 mt-2"
            (click)="deleteWarning()"
            data-cy="warning-details-delete-button"
            >{{ statusMessage }}</a
          >
        </div>
        <div class="col-12 text-white text-start mt-2">Report:</div>

        <div class="col-12 p-1">
          <a
            class="btn btn-card text-lightgray rb-ic rb-ic-mail-out text-gray fs-2xl miw-100 z-3 position-relative cursor-pointer hover-effect"
            [href]="mailToLink"
            target="_blank"
          >
          </a>
        </div>
      </div>
    </div>
    <div
      cdkDragHandle
      class="rb-ic rb-ic-drag-handle text-gray fs-2xl z-3 position-absolute bottom-2 end-2 cursor-pointer hover-effect"
    ></div>
  </app-details>
</div>
