export enum Permissions {
  Apps_All_Access = 'app.all.acc',
  Apps_Rc_Access = 'app.rc.acc',
  Apps_Bwc_Access = 'app.bwc.acc',
  Apps_Mobile_Access = 'app.mob.acc',
  Apps_Obstacle_Access = 'app.obs.acc',
  Apps_Customer_Access = 'app.cust.acc',
  Apps_Simulation_Access = 'app.sim.acc',
  Warnings_All_Read = 'warn.all.r',
  Warnings_Bdv_Read = 'warn.bdv.r',
  Warnings_Va_Read = 'warn.va.r',
  Warnings_Lv_Read = 'warn.lv.r',
  Warnings_Tl_Read = 'warn.tl.r',
  Warnings_Sw_Read = 'warn.sw.r',
  Warnings_Hr_Read = 'warn.hr.r',
  Warnings_Hs_Read = 'warn.hs.r',
  Warnings_Cs_Read = 'warn.cs.r',
  Warnings_Bdv_Write = 'warn.bdv.w',
  Warnings_Va_Write = 'warn.va.w',
  Warnings_Lv_Write = 'warn.lv.w',
  Warnings_Tl_Write = 'warn.tl.w',
  Warnings_Sw_Write = 'warn.sw.w',
  Warnings_Hr_Write = 'warn.hr.w',
  Warnings_Hs_Write = 'warn.hs.w',
  Warnings_Cs_Write = 'warn.cs.w',
  Hazards_All_Read = 'hz.all.r',
  RoadSegments_All_Read = 'rs.all.r',
  RoadSegments_Rc_Read = 'rs.rc.r',
  Historic_All_Read = 'historic.all.r',
  Weather_All_Read = 'wp.all.r',
}
