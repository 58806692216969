import { Component } from '@angular/core'
import { DetailsModel } from '../../../shared/models/details.model'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { debounceTime } from 'rxjs/operators'
import { RoadSegmentPropertiesModel } from 'src/app/road-condition/model/road-segment-properties.model'
import { MapGeoJSONFeature } from 'maplibre-gl'
import {
  RoadCondition,
  Friction,
  Wft,
  RoadSegmentValues,
  Warning,
} from 'src/app/shared/models/road-segment.model'
import { Layers } from 'src/app/shared/layers-config/layers'
import { RcsMetrics, getMetricFromMap } from '../../enums/rcs-metrics.enum'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { format } from 'date-fns'
import { calculatePercentageValues } from '../../../shared/utils/calculation.utils'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-rc-details',
  templateUrl: './rc-details.component.html',
})
export class RcDetailsComponent {
  public Permissions = Permissions
  public details = new Map<string, DetailsModel>()
  public Metrics = RcsMetrics
  public isBadWeatherIcon = ''
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public selectedFeatureId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    public appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (features[this.index].layer.id === Layers.RoadSegment.name) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails(): void {
    const properties: RoadSegmentPropertiesModel = this.features[this.index]
      .properties as RoadSegmentPropertiesModel
    const roadSegment: RoadSegmentValues = (this.features[this.index] as any).state
    this.selectedFeatureId = this.features[this.index]?.id?.toString() || ''
    const warnings: any = (this.features[this.index] as any).state
    this.isBadWeatherIcon = this.calculateBadWeather(roadSegment.roadCondition)
    this.mapMetaToDetails(properties.highway, roadSegment.timestamp)
    this.mapRoadConditionToDetails(roadSegment.roadCondition)
    this.mapFrictionToDetails(roadSegment.friction)
    this.mapWftToDetails(roadSegment.wft)
    const tractionLoss = warnings[
      getMetricFromMap(RcsMetrics.RoadSegment_TractionLoss).name
    ] as Warning
    this.mapRoadConditionWarningsToDetails(tractionLoss)
    this.appState.setState(AppPaths.RoadCondition, {
      selectedFeatureId: this.selectedFeatureId,
    })
  }

  private mapMetaToDetails(highway: string, timestamp: number) {
    this.details.set('Meta', {
      title: 'Road Segment',
      items: [
        {
          description: 'Type',
          value: highway,
        },
        {
          description: 'Segment ID',
          value: this.selectedFeatureId,
        },
        {
          description: `Last Updated (${getTimeZoneLabel(new Date(timestamp * 1000))})`,
          value: timestamp ? format(new Date(timestamp * 1000), 'yyyy-MM-dd HH:mm:ss') : '-',
        },
      ],
    })
  }

  private mapRoadConditionToDetails(roadCondition: RoadCondition) {
    if (roadCondition) {
      const conditionValues = [
        roadCondition.dry * 100,
        roadCondition.wet * 100,
        roadCondition.veryWet * 100,
        roadCondition.icy * 100,
      ]
      const roundedValues = calculatePercentageValues(conditionValues, 1)
      this.details.set(RcsMetrics.RoadSegment_RoadCondition, {
        title: 'Conditions',
        items: [
          {
            description: 'Dry',
            value: roundedValues[0].toString(),
            unit: '%',
          },
          {
            description: 'Wet',
            value: roundedValues[1].toString(),
            unit: '%',
          },
          {
            description: 'Very Wet',
            value: roundedValues[2].toString(),
            unit: '%',
          },
          {
            description: 'Icy',
            value: roundedValues[3].toString(),
            unit: '%',
          },
        ],
      })
    }
  }

  private mapFrictionToDetails(friction: Friction) {
    if (friction) {
      this.details.set(RcsMetrics.RoadSegment_Friction, {
        title: 'Friction',
        items: [
          {
            description: 'Coefficient',
            value: friction.coefficient.toFixed(2),
          },
          {
            description: 'Uncertainty',
            value: friction.uncertainty.toFixed(2),
          },
        ],
      })
    }
  }

  private mapWftToDetails(wft: Wft) {
    if (wft) {
      this.details.set(RcsMetrics.RoadSegment_Wft, {
        title: 'Waterfilm Thickness',
        items: [
          {
            description: 'Value',
            value: wft.value.toFixed(2),
            unit: 'mm',
          },
          {
            description: 'Uncertainty',
            value: wft.uncertainty.toFixed(2),
            unit: 'mm',
          },
        ],
      })
    }
  }

  private mapRoadConditionWarningsToDetails(tractionLoss: Warning) {
    if (tractionLoss) {
      this.details.set(RcsMetrics.RoadSegment_TractionLoss, {
        title: 'Traction Loss',
        items: [
          {
            description: 'Probability',
            value: (tractionLoss.probability * 100).toFixed(1),
            unit: '%',
          },
        ],
      })
    }
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.RoadCondition, {
      selectedFeatureId: undefined,
    })
  }

  calculateBadWeather(roadCondition: RoadCondition) {
    if (
      roadCondition.wet + roadCondition.veryWet >= 0.7 ||
      roadCondition.icy >= 0.7 ||
      roadCondition.wet + roadCondition.veryWet + roadCondition.icy >= 0.7
    ) {
      return 'rb-ic rb-ic-weather-rain-snow fs-2xl'
    }
    return ''
  }
}
