// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { MaintenanceService } from './maintenance.service'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from './app-state.service'

@Injectable({ providedIn: 'root' })
export class MaintenanceEnabledGuard {
  constructor(
    public router: Router,
    public readonly maintenanceService: MaintenanceService,
  ) {}
  async canActivate(): Promise<boolean> {
    if (await this.maintenanceService.checkMaintenanceMode()) {
      this.router.navigate([AppPaths.Maintenance])
      return false
    } else {
      return true
    }
  }
}

@Injectable({ providedIn: 'root' })
export class MaintenanceDisabledGuard {
  constructor(
    public router: Router,
    public appState: AppStateService,
    public readonly maintenanceService: MaintenanceService,
  ) {}
  async canActivate(): Promise<boolean> {
    if (await this.maintenanceService.checkMaintenanceMode()) {
      return true
    } else {
      this.router.navigate([`/${AppPaths.RoadCondition}`])
      this.appState.setState(AppPaths.RoadCondition, { selectedFeatureId: undefined })
      return false
    }
  }
}
