import { Component, Input } from '@angular/core'
import { MetricModel } from '../../../models/metric.model'

@Component({
  selector: 'app-legend-category',
  templateUrl: './legend-category.component.html',
})
export class LegendCategoryComponent {
  @Input() name: string = ''
  @Input() metric: MetricModel | undefined
}
