import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { firstValueFrom, timer } from 'rxjs'
import { AppConfigModel } from '../models'
import { APP_CONFIG } from 'src/app/app.config'
import { Router } from '@angular/router'
import { AppPaths } from 'src/app/app-routing.module'

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  public oldMaintenanceEnabled = false
  constructor(
    private readonly http: HttpClient,
    private route: Router,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {
    timer(0, 60000).subscribe(async () => {
      let newMaintenanceEnabled = await this.checkMaintenanceMode()
      if (newMaintenanceEnabled) {
        newMaintenanceEnabled != this.oldMaintenanceEnabled
          ? this.route.navigate([AppPaths.Maintenance])
          : ''
      } else {
        newMaintenanceEnabled != this.oldMaintenanceEnabled
          ? this.route.navigate([AppPaths.RoadCondition])
          : ''
      }
      this.oldMaintenanceEnabled = newMaintenanceEnabled
    })
  }

  public async checkMaintenanceMode() {
    try {
      const result = await firstValueFrom(
        this.http.get<any>(`${this.config.backend_url}maintenance`),
      )
      return result.inMaintenance
    } catch (error) {
      return false
    }
  }
}
