<app-layer-category class="border-bottom border-darkgray" description="Road Hazards">
  <ng-container *hasPermissionAny="Layers.RoadSegment.permissions">
    <app-layer-config-item
      *ngVar="getMetricFromMap(metrics.RoadSegment_RoadCondition) as metric"
      [description]="metric.displayName"
      [isSelected]="selectedRoadMetric === metric.key"
      [key]="metric.key"
      [icon]="metric.icon"
      (clicked)="setMetric($event)"
    ></app-layer-config-item>
  </ng-container>
  <ng-container *hasPermissionAny="[Permissions.RoadSegments_All_Read]">
    <app-layer-config-item
      *ngVar="getMetricFromMap(metrics.RoadSegment_TractionLoss) as metric"
      [description]="metric.displayName"
      [isSelected]="selectedRoadMetric === metric.key"
      [key]="metric.key"
      [icon]="metric.icon"
      (clicked)="setMetric($event)"
    ></app-layer-config-item>
  </ng-container>
</app-layer-category>
