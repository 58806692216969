import { MetricModel } from 'src/app/shared/models/metric.model'
import { roadHazardLegendObstacles } from '../utils/customer-legend-utils'
import { defaultExpression, defaultLegend } from 'src/app/shared/utils/metric.utils'
import { createPaintExpression } from '../utils/customer-paint-utils'

export enum CustomerMetrics {
  Warnings_LowVisibility = 'lv',
  Warnings_Accidents = 'va',
  Warnings_BrokenDownVehicle = 'bdv',
  Warnings_TractionLoss = 'tl',
  Warnings_HeavyRain = 'hr',
  Warnings_HeavySnow = 'hs',
  Warnings_StrongWind = 'sw',
}

export const getMetricFromMap = (metric: string): MetricModel => {
  return (
    CustomerMetricMap.get(metric) || {
      displayName: 'Default',
      expression: defaultExpression,
      name: 'default',
      icon: 'question',
      key: 'default',
      legend: defaultLegend,
    }
  )
}

export const CustomerMetricMap = new Map<string, MetricModel>()

CustomerMetricMap.set(CustomerMetrics.Warnings_Accidents, {
  name: 'accident',
  expression: createPaintExpression('accident'),
  icon: 'car-crash',
  key: CustomerMetrics.Warnings_Accidents,
  displayName: 'Accidents',
  legend: roadHazardLegendObstacles,
})

CustomerMetricMap.set(CustomerMetrics.Warnings_BrokenDownVehicle, {
  name: 'brokenDownVehicle',
  expression: createPaintExpression('brokenDownVehicle'),
  icon: 'car-mechanic',
  key: CustomerMetrics.Warnings_BrokenDownVehicle,
  displayName: 'BDV',
  legend: roadHazardLegendObstacles,
})

CustomerMetricMap.set(CustomerMetrics.Warnings_LowVisibility, {
  name: 'lowVisibility',
  expression: createPaintExpression('lowVisibility'),
  icon: 'weather-mist',
  key: CustomerMetrics.Warnings_LowVisibility,
  displayName: 'Low Visibility',
  legend: roadHazardLegendObstacles,
})

CustomerMetricMap.set(CustomerMetrics.Warnings_TractionLoss, {
  name: 'tractionLoss',
  expression: createPaintExpression('tractionLoss'),
  icon: 'wheel-vehicle',
  key: CustomerMetrics.Warnings_TractionLoss,
  displayName: 'Traction Loss',
  legend: roadHazardLegendObstacles,
})

CustomerMetricMap.set(CustomerMetrics.Warnings_HeavyRain, {
  name: 'heavyRain',
  expression: createPaintExpression('heavyRain'),
  icon: 'weather-rain-warning',
  key: CustomerMetrics.Warnings_HeavyRain,
  displayName: 'Heavy Rain',
  legend: roadHazardLegendObstacles,
})

CustomerMetricMap.set(CustomerMetrics.Warnings_HeavySnow, {
  name: 'heavySnow',
  expression: createPaintExpression('heavySnow'),
  icon: 'ice',
  key: CustomerMetrics.Warnings_HeavySnow,
  displayName: 'Heavy Snow',
  legend: roadHazardLegendObstacles,
})

CustomerMetricMap.set(CustomerMetrics.Warnings_StrongWind, {
  name: 'strongWind',
  expression: createPaintExpression('strongWind'),
  icon: 'wind-vane-moderate',
  key: CustomerMetrics.Warnings_StrongWind,
  displayName: 'Strong Wind',
  legend: roadHazardLegendObstacles,
})
