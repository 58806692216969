import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'

@Injectable()
export class DataWebcamsService {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {}

  async loadWebcamsJson() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${this.config.backend_url}rcs/webcams`
    const result = await firstValueFrom(this.http.get<any>(url.toString(), httpOptions))
    return result
  }
}
