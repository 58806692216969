<app-layer-button
  class="position-absolute bottom-7 start-5 z-2"
  (visibilityToggled)="toggleLayerSwitchVisibility()"
>
</app-layer-button>

<div class="position-absolute bottom-7 start-5 z-2 pt-2" [class.d-none]="!isLayerSelectionVisible">
  <app-layer-config (closed)="close()">
    <div class="d-flex flex-column gap-3 categories">
      <app-simulation-data> </app-simulation-data>
    </div>
  </app-layer-config>
</div>
