import { Component, Inject } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import { Subject, debounceTime, interval, takeUntil } from 'rxjs'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { Layers } from 'src/app/shared/layers-config/layers'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { getMetricFromMap } from '../enums/obstacle-metrics.enum'
import {
  bingAccidentLayerProvider,
  bingBDVLayerProvider,
  vehicleAccidentLayerProvider,
  vehicleBDVLayerProvider,
} from 'src/app/shared/providers/layer-service-provider'
import { LayerRawEventsService } from 'src/app/shared/layers/layer-raw-events.service'
import { LayerCustomerWarningService } from 'src/app/shared/layers/layer-customer-warning.service'
import { DataCustomerWarningService } from 'src/app/shared/services/data-customer-warning.service'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { obstacleWarningLayerProvider } from 'src/app/shared/providers/warning-layers-provider'

@Component({
  selector: 'app-obstacle-page',
  templateUrl: './obstacle-page.component.html',
  providers: [
    LayerCustomerWarningService,
    DataCustomerWarningService,
    { provide: 'metrics', useValue: getMetricFromMap },
    bingAccidentLayerProvider,
    bingBDVLayerProvider,
    vehicleBDVLayerProvider,
    vehicleAccidentLayerProvider,
    obstacleWarningLayerProvider,
  ],
})
export class ObstaclePageComponent {
  public Permissions = Permissions
  private stopTimer = new Subject<void>()
  public isObstacleLayerVisibile = false
  public isVehicleDataBdvLayerVisible = false
  public isVehicleDataAccidentsLayerVisible = false
  public isBingBdvLayerVisible = false
  public isBingAccidentLayerVisible = false
  public isBdvWarningLayerVisible = false
  public isAccidentWarningLayerVisible = false
  public isConstructionSiteWarningLayerVisible = false
  public isInLiveMode = false
  public autoRefreshIntervalInMs = 0
  public layerIds = Layers
  public currentTimestamp: Date | undefined
  public currentTimespan: number | undefined
  public isHistoryVisible = false
  public isLegendVisible = false

  constructor(
    private mapService: MapService,
    public appState: AppStateService,
    @Inject('bingAccidentLayer') public bingAccidentLayerService: LayerRawEventsService,
    @Inject('bingBDVLayer') public bingBDVLayerService: LayerRawEventsService,
    @Inject('vehicleBDVLayer') public vehicleBDVLayerService: LayerRawEventsService,
    @Inject('vehicleAccidentLayer') public vehicleAccidentLayerService: LayerRawEventsService,
    @Inject('obstacleWarningLayer')
    public obstacleWarningLayerService: LayerCustomerWarningService,
  ) {
    this.mapService.activateGeoLocationSearch()
    this.mapService.setPosition(
      this.appState.getState().lat,
      this.appState.getState().lon,
      this.appState.getState().zoom,
    )
    if (this.appState.getState().historyEnabled) {
      this.isHistoryVisible = true
    }
    this.updateQueryParameters()
    this.mapService.mapViewChanged
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateQueryParameters()
      })

    this.evaluateQueryParameters()
    this.appState.stateChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.evaluateQueryParameters()
    })
  }

  ngOnDestroy() {
    this.mapService.deactivateGeoLocationSearch()
    this.stopTimer.next()
    this.stopTimer.complete()
  }

  setLiveModeRefreshInterval(seconds: number) {
    this.autoRefreshIntervalInMs = seconds * 1000
    this.stopLiveMode()
    if (this.autoRefreshIntervalInMs > 0) {
      this.startLiveMode()
    }
  }

  startLiveMode() {
    this.isInLiveMode = true
    interval(this.autoRefreshIntervalInMs)
      .pipe(takeUntil(this.stopTimer))
      .subscribe(() => {
        this.bingAccidentLayerService.reload()
        this.bingBDVLayerService.reload()
        this.vehicleAccidentLayerService.reload()
        this.vehicleBDVLayerService.reload()
        this.obstacleWarningLayerService.reload()
      })
  }

  stopLiveMode() {
    this.isInLiveMode = false
    this.stopTimer.next()
  }

  toggleHistory = () => {
    this.isHistoryVisible = !this.isHistoryVisible
    this.updateQueryParameters()
  }

  toggleLegend = () => {
    this.isLegendVisible = !this.isLegendVisible
  }

  handleHistoryClosed = () => {
    this.isHistoryVisible = false
    this.updateQueryParameters()
  }

  evaluateQueryParameters() {
    if (this.appState.getState().historyEnabled) {
      this.isHistoryVisible = true
    }

    if (this.appState.getState().layers?.includes(Layers.VehicleDataAccidents.name)) {
      this.isVehicleDataAccidentsLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.VehicleDataBdv.name)) {
      this.isVehicleDataBdvLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.BingAccidents.name)) {
      this.isBingAccidentLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.BingBdv.name)) {
      this.isBingBdvLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.BDVWarning.name)) {
      this.isBdvWarningLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.VAWarning.name)) {
      this.isAccidentWarningLayerVisible = true
    }
    if (this.appState.getState().layers?.includes(Layers.CSWarning.name)) {
      this.isConstructionSiteWarningLayerVisible = true
    }
  }

  updateQueryParameters() {
    this.appState.setState(AppPaths.Obstacle, {
      lat: this.mapService.map.getCenter().lat,
      lon: this.mapService.map.getCenter().lng,
      zoom: this.mapService.map.getZoom(),
      historyEnabled: this.isHistoryVisible ? true : undefined,
    })
  }
}
