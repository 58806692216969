// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { AuthService } from './auth.service'
import { Permissions } from '../enums/permissions.enum'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    public authService: AuthService,
    public router: Router,
  ) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const neededPermissions = route.data['requiredPermissions'] as Permissions[]

    if (this.authService.hasPermission(neededPermissions, 'any')) {
      return true
    } else {
      this.router.navigate(['/'])
      return false
    }
  }
}
