<app-layer-button
  class="position-absolute bottom-7 start-5 z-2"
  (visibilityToggled)="toggleLayerSwitchVisibility()"
>
</app-layer-button>

<div class="position-absolute bottom-7 start-5 z-2" [class.d-none]="!isLayerSelectionVisible">
  <app-layer-config (closed)="close()">
    <div class="d-flex flex-column gap-3 categories">
      <app-rc-road-segment> </app-rc-road-segment>
      <app-rc-weather-prediction
        *hasPermissionAny="[Permissions.Weather_All_Read]"
      ></app-rc-weather-prediction>
      <app-rc-vehicle-data *hasPermissionAll="[Permissions.Hazards_All_Read]"></app-rc-vehicle-data>
    </div>
  </app-layer-config>
</div>
