import * as turf from '@turf/turf'
import { DirectionsModel } from '../models/directions.model'
import { MapGeoJSONFeature } from 'maplibre-gl'

export function calculateDirections(feature: MapGeoJSONFeature): DirectionsModel {
  let coordinates = (feature.geometry as any).coordinates
  let directions: DirectionsModel = {
    start: {
      coordinates: coordinates[0],
      bearing: calculateBearingStart(coordinates),
    },
    end: {
      coordinates: coordinates[coordinates.length - 1],
      bearing: calculateBearingEnd(coordinates),
    },
  }
  return directions
}

function calculateBearingStart(coordinates: any) {
  const point1 = turf.point(coordinates[0])
  const point2 = turf.point(coordinates[1])
  return turf.bearing(point2, point1).toFixed(0)
}

function calculateBearingEnd(coordinates: any) {
  const point1 = turf.point(coordinates[coordinates.length - 1])
  const point2 = turf.point(coordinates[coordinates.length - 2])
  return turf.bearing(point2, point1).toFixed(0)
}
