import { ExpressionSpecification } from 'maplibre-gl'
import {
  getWeatherIcyExpression,
  getWeatherWftExpression,
  maxProbExpression,
  probabilityExpression,
} from './rcs-expression-utils'

const getColor = (no: number): string => {
  const color = roadcondMap.get(no)
  return color ? color : '#a9a9a9'
}

const roadcondMap = new Map<number, string>()
roadcondMap.set(0, '#7e1a96')
roadcondMap.set(1, '#440154')
roadcondMap.set(2, '#ae2029')
roadcondMap.set(3, '#d40010')
roadcondMap.set(4, '#e80000')
roadcondMap.set(5, '#e04000')
roadcondMap.set(6, '#f06000')
roadcondMap.set(7, '#ff8030')
roadcondMap.set(8, '#F08000')
roadcondMap.set(9, '#F0A000')
roadcondMap.set(10, '#ffe000')
roadcondMap.set(11, '#b1d650')
roadcondMap.set(12, '#6ab256')
roadcondMap.set(13, '#2e8b57')
roadcondMap.set(14, '#fff')
roadcondMap.set(99, '#a9a9a9')

export const createPaintExpression = (
  metricName: string,
  paintExpression: ExpressionSpecification,
): ExpressionSpecification => {
  return [
    'case',
    ['to-boolean', ['feature-state', metricName]],
    paintExpression,
    '#b3b3b3', // if state is missing gray!
  ]
}

export const roadConditionPaint: ExpressionSpecification = [
  'case',
  ['all', ['==', probabilityExpression, 'unknown']],
  getColor(14),
  ['all', ['==', probabilityExpression, 'dry'], ['<=', maxProbExpression, 0.6]],
  getColor(11),
  ['all', ['==', probabilityExpression, 'dry'], ['<=', maxProbExpression, 0.8]],
  getColor(12),
  ['all', ['==', probabilityExpression, 'dry'], ['>', maxProbExpression, 0.8]],
  getColor(13),
  ['all', ['==', probabilityExpression, 'wetdry'], ['<=', maxProbExpression, 0.6]],
  getColor(10),
  ['all', ['==', probabilityExpression, 'wetdry'], ['<=', maxProbExpression, 0.8]],
  getColor(9),
  ['all', ['==', probabilityExpression, 'wetdry'], ['>', maxProbExpression, 0.8]],
  getColor(8),
  ['all', ['==', probabilityExpression, 'wetwet'], ['<=', maxProbExpression, 0.6]],
  getColor(5),
  ['all', ['==', probabilityExpression, 'wetwet'], ['<=', maxProbExpression, 0.8]],
  getColor(6),
  ['all', ['==', probabilityExpression, 'wetwet'], ['>', maxProbExpression, 0.8]],
  getColor(7),
  ['all', ['==', probabilityExpression, 'veryWet'], ['<=', maxProbExpression, 0.6]],
  getColor(4),
  ['all', ['==', probabilityExpression, 'veryWet'], ['<=', maxProbExpression, 0.8]],
  getColor(3),
  ['all', ['==', probabilityExpression, 'veryWet'], ['>', maxProbExpression, 0.8]],
  getColor(2),
  ['all', ['==', probabilityExpression, 'icy'], ['>=', maxProbExpression, 0.7]],
  getColor(1),
  ['all', ['==', probabilityExpression, 'icy'], ['<', maxProbExpression, 0.7]],
  getColor(0),
  /* other */ getColor(99),
]

export const weatherWftPaint: ExpressionSpecification = [
  'interpolate',
  ['linear'],
  getWeatherWftExpression,
  0,
  getColor(12),
  0.1,
  getColor(11),
  0.2,
  getColor(10),
  0.3,
  getColor(9),
  0.4,
  getColor(8),
  0.5,
  getColor(7),
  0.6,
  getColor(6),
  0.7,
  getColor(5),
  0.8,
  getColor(4),
  0.9,
  getColor(3),
  1,
  getColor(2),
  10,
  getColor(1),
]

export const weatherIcyPaint: ExpressionSpecification = [
  'interpolate',
  ['linear'],
  getWeatherIcyExpression,
  0,
  getColor(12),
  0.1,
  getColor(11),
  0.2,
  getColor(10),
  0.3,
  getColor(9),
  0.4,
  getColor(8),
  0.5,
  getColor(7),
  0.6,
  getColor(6),
  0.7,
  getColor(5),
  0.8,
  getColor(4),
  0.9,
  getColor(3),
  1,
  getColor(2),
  10,
  getColor(1),
]

export const getTractionLossPaint = (metricName: string): ExpressionSpecification => {
  return [
    'case',
    ['>=', ['get', 'probability', ['object', ['feature-state', metricName]]], 0.7],
    getColor(2),
    getColor(13),
  ]
}
