<div class="d-flex">
  <div
    class="d-flex flex-column justify-content-center p-2 text-deactivated"
    ngbTooltip="Auto refresh"
    tooltipClass="custom-tooltip"
    [openDelay]="1000"
    placement="top"
  >
    <span class="rb-ic rb-ic-refresh fs-2xl" [class.sel-menu-item]="isActiveTemp"></span>
  </div>

  <div
    class="d-flex flex-column text-gray align-items-center justify-content-center fs-sm"
    (clickOutside)="closeDropdown()"
  >
    <div class="position-relative w-100">
      <div
        class="position-absolute bg-dark border border-darkgray rounded-2 p-2 bottom-1 end-0 d-flex flex-column gap-1"
        [class.d-none]="isDropdownVisible === false"
      >
        <span
          class="ho-layer-text"
          [class.sel-menu-item]="intervalInSeconds === 0"
          (click)="setInterval(0)"
          >Off</span
        >
        <span
          class="ho-layer-text"
          [class.sel-menu-item]="intervalInSeconds === 2"
          (click)="setInterval(2)"
          >2s</span
        >
        <span
          class="ho-layer-text"
          [class.sel-menu-item]="intervalInSeconds === 5"
          (click)="setInterval(5)"
          >5s</span
        >
        <span
          class="ho-layer-text"
          [class.sel-menu-item]="intervalInSeconds === 10"
          (click)="setInterval(10)"
          >10s</span
        >
      </div>
    </div>
    <div
      (click)="toggleDropdownVisibility()"
      class="pe-1 ho-layer-text d-flex align-items-center gap-1"
      [class.d-none]="isDisabledTemp"
    >
      <span>{{ intervalInSeconds > 0 ? intervalInSeconds + 's' : '' }} </span>
      <span class="rb-ic rb-ic-down"> </span>
    </div>
  </div>
</div>
