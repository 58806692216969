import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-layer-control-item',
  templateUrl: './layer-control-item.component.html',
  styleUrls: ['./layer-control-item.component.scss'],
})
export class LayerControlItemComponent {
  @Input() isLayerVisibile: boolean | undefined
  @Input() layerId: string | undefined
  @Input() description: string | undefined
  @Input() icon: string | undefined

  @Output() visibilityToggled = new EventEmitter<string>()

  public getIcon(): string {
    if (this.icon) {
      return `rb-ic rb-ic-${this.icon}`
    }
    return ''
  }
}
