<app-layer-category class="border-bottom border-darkgray" description="Weather Predictions">
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_PercipRain) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_PercipRain"
    [key]="metrics.WeatherPrediction_PercipRain"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  >
  </app-layer-config-item>
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_PercipSnow) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_PercipSnow"
    [key]="metrics.WeatherPrediction_PercipSnow"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  ></app-layer-config-item>
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_WindSpeed) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_WindSpeed"
    [key]="metrics.WeatherPrediction_WindSpeed"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  ></app-layer-config-item>
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_Visibility) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_Visibility"
    [key]="metrics.WeatherPrediction_Visibility"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  ></app-layer-config-item>
  <app-layer-config-item
    *ngVar="getMetricFromMap(metrics.WeatherPrediction_AirTemp) as metric"
    [description]="metric.displayName"
    [isSelected]="selectedWeatherMetric === metrics.WeatherPrediction_AirTemp"
    [key]="metrics.WeatherPrediction_AirTemp"
    [icon]="metric.icon"
    (clicked)="setMetric($event)"
  ></app-layer-config-item>
</app-layer-category>
