<app-layer-category description="Standard Vehicle">
  <app-layer-control-item
    description="Heavy Rain"
    [isLayerVisibile]="isHeavyRainLayerVisible"
    [layerId]="layerIds.VehicleDataRain.name"
    icon="windshield-wiper"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Fog"
    [isLayerVisibile]="isFogLayerVisible"
    [layerId]="layerIds.VehicleDataFog.name"
    icon="light-fog-warning"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
</app-layer-category>
