import { Component, OnDestroy } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { BwcMetrics, getMetricFromMap } from '../../enums/bwc-metrics.enum'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { MetricModel } from 'src/app/shared/models/metric.model'
import { Layers } from 'src/app/shared/layers-config/layers'

@Component({
  selector: 'app-bwc-legend',
  templateUrl: './bwc-legend.component.html',
})
export class BwcLegendComponent implements OnDestroy {
  private cleanUp$ = new Subject<void>()
  public metrics: string | undefined
  public isRoadMetricLayerVisible = false
  public isWeatherPredictionLayerVisible = false
  public isVehicleDataFogLayerVisible = false
  public isVehicleDataRainLayerVisible = false
  public isVehicleDataSlipperyLayerVisible = false
  public isLayerSelectionVisible = false

  public roadMetric: MetricModel | undefined = undefined
  public weatherPreditionMetric: MetricModel | undefined = undefined
  public vehicleDataMetric: MetricModel | undefined = undefined

  constructor(private appState: AppStateService) {
    this.evaluateQueryParameters()

    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe(() => {
      this.evaluateQueryParameters()
    })
  }
  ngOnDestroy(): void {
    this.cleanUp$.next()
    this.cleanUp$.complete()
  }

  isOneVehicleDataLayerVisible() {
    if (
      this.isVehicleDataRainLayerVisible ||
      this.isVehicleDataFogLayerVisible ||
      this.isVehicleDataSlipperyLayerVisible
    ) {
      return true
    } else {
      return false
    }
  }

  evaluateQueryParameters() {
    this.appState.getState().layers?.includes(Layers.WeatherPrediction.name)
      ? (this.isWeatherPredictionLayerVisible = true)
      : (this.isWeatherPredictionLayerVisible = false)
    this.appState.getState().layers?.includes(Layers.RoadSegment.name)
      ? (this.isRoadMetricLayerVisible = true)
      : (this.isRoadMetricLayerVisible = false)
    this.appState.getState().layers?.includes(Layers.VehicleDataFog.name)
      ? (this.isVehicleDataFogLayerVisible = true)
      : (this.isVehicleDataFogLayerVisible = false)
    this.appState.getState().layers?.includes(Layers.VehicleDataRain.name)
      ? (this.isVehicleDataRainLayerVisible = true)
      : (this.isVehicleDataRainLayerVisible = false)
    this.appState.getState().layers?.includes(Layers.VehicleDataSlippery.name)
      ? (this.isVehicleDataSlipperyLayerVisible = true)
      : (this.isVehicleDataSlipperyLayerVisible = false)

    const roadMetricKeys = this.appState.selectMetrics(Layers.RoadSegment.name)
    if (roadMetricKeys && roadMetricKeys.length > 0) {
      this.roadMetric = getMetricFromMap(roadMetricKeys[0])
    }

    const weatherPredictionKeys = this.appState.selectMetrics(Layers.WeatherPrediction.name)
    if (weatherPredictionKeys && weatherPredictionKeys.length > 0) {
      this.weatherPreditionMetric = getMetricFromMap(weatherPredictionKeys[0])
    }

    this.vehicleDataMetric = getMetricFromMap(BwcMetrics.VehicleData_Fog)
  }
}
