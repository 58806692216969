import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { LocationService } from 'src/app/core/services/location.service'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { MapService } from 'src/app/core/services/map.service'
import { LayerRoadSegmentService } from '../../../shared/layers/layer-road-segment.service'
import { LayerWeatherService } from '../../../shared/layers/layer-weather.service'
import { Subject, debounceTime, takeUntil } from 'rxjs'
import { CurrentPositionModel } from 'src/app/core/models/current-position.model'
import { MapGeoJSONFeature, Marker } from 'maplibre-gl'
import { ScreenLockService } from 'src/app/core/services/screen-lock.service'
import { DebugService } from 'src/app/core/services/debug.service'
import { DataWeatherPredictionService } from 'src/app/shared/services/data-weather-prediction.service'
import { DataRoadSegmentService } from 'src/app/shared/services/data-road-segment.service'

import { RcsMetrics, getMetricFromMap } from '../../enums/rcs-metrics.enum'
import { Layers } from 'src/app/shared/layers-config/layers'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { vehicleHRLayerProvider } from 'src/app/shared/providers/layer-service-provider'
import { LayerRawEventsService } from 'src/app/shared/layers/layer-raw-events.service'
import { LayerCustomerWarningService } from 'src/app/shared/layers/layer-customer-warning.service'
import { DataCustomerWarningService } from 'src/app/shared/services/data-customer-warning.service'
import { mobileWarningLayerProvider } from 'src/app/shared/providers/warning-layers-provider'

@Component({
  selector: 'app-mobile-page',
  templateUrl: './mobile-page.component.html',
  styleUrls: ['./mobile-page.component.scss'],
  providers: [
    LayerWeatherService,
    LayerRoadSegmentService,
    LayerCustomerWarningService,
    DataWeatherPredictionService,
    DataCustomerWarningService,
    DataRoadSegmentService,
    { provide: 'metrics', useValue: getMetricFromMap },
    vehicleHRLayerProvider,
    mobileWarningLayerProvider,
  ],
})
export class MobilePageComponent implements OnDestroy, OnInit {
  public isRoadConditionLayerVisibile: boolean | undefined
  public isWeatherLayerVisible: boolean | undefined
  public isVehicleLayerVisible: boolean | undefined
  public isAreaWarningsVisible: boolean | undefined
  private isTLLayerVisible: boolean | undefined
  private isVALayerVisible: boolean | undefined
  private isBDVLayerVisible: boolean | undefined
  private isLVLayerVisible: boolean | undefined
  public isLayerSelectionVisible = false
  private cleanUp$ = new Subject<void>()
  public features: MapGeoJSONFeature[] = []
  public isFollowPositionActive = true
  public isScreenLockActive = false
  private marker: Marker
  public layerIds = Layers
  public metrics = RcsMetrics
  public getMetricFromMap = getMetricFromMap
  public selectedFeatureId = ''

  public selectedRoadMetric = RcsMetrics.RoadSegment_RoadCondition
  public selecteWeatherMetric = RcsMetrics.WeatherPrediction_Wft

  constructor(
    private mapService: MapService,
    private locationService: LocationService,
    private mapEventService: MapEventsService,
    private screenLockService: ScreenLockService,
    public debugService: DebugService,
    private appState: AppStateService,
    public rcLayer: LayerRoadSegmentService,
    public wpLayer: LayerWeatherService,
    @Inject('vehicleHRLayer') public vehicleHRLayerService: LayerRawEventsService,
    @Inject('mobileWarningLayer')
    public mobileWarningLayerService: LayerCustomerWarningService,
  ) {
    const layers = this.appState.getState().layers
    this.isRoadConditionLayerVisibile = layers?.includes(Layers.RoadSegment.name)
    this.isVehicleLayerVisible = layers?.includes(Layers.VehicleDataRain.name)
    this.isWeatherLayerVisible = layers?.includes(Layers.WeatherPrediction.name)
    this.isTLLayerVisible = layers?.includes(Layers.TLWarning.name)
    this.isBDVLayerVisible = layers?.includes(Layers.BDVWarning.name)
    this.isLVLayerVisible = layers?.includes(Layers.LVWarning.name)
    this.isVALayerVisible = layers?.includes(Layers.VAWarning.name)
    this.isAreaWarningsVisible =
      this.isTLLayerVisible ||
      this.isBDVLayerVisible ||
      this.isLVLayerVisible ||
      this.isVALayerVisible

    // const roadMetrics = this.appState.selectMetrics(Layers.RoadSegment)
    // if (roadMetrics && roadMetrics.length > 0) {
    //   this.selectedRoadMetric = roadMetrics[0] as RcsMetrics
    // }

    // const weatherMetric = this.appState.selectMetrics(Layers.WeatherPrediction)
    // if (weatherMetric && weatherMetric.length > 0) {
    //   console.log(weatherMetric)
    //   this.selecteWeatherMetric = weatherMetric[0] as RcsMetrics
    // }

    this.updateQueryParameters()

    this.mapService.mapViewChanged
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.updateQueryParameters()
      })

    this.locationService.initialize()
    this.locationService.configure()
    this.locationService.positionChanged$
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this.cleanUp$))
      .subscribe((position) => {
        this.handlePositionChange(position)
      })
    this.screenLockService.screenLockChanged$
      .pipe(takeUntil(this.cleanUp$))
      .subscribe((isActive) => {
        this.isScreenLockActive = isActive
      })
    this.screenLockService.startScreenLock()

    const el = document.createElement('div')
    el.className = 'position-marker'
    el.id = 'marker1'
    this.marker = new Marker(el)
  }

  async ngOnInit(): Promise<void> {
    this.mapService.map.setZoom(15)

    this.marker.setLngLat({ lat: 0, lon: 0 }).addTo(this.mapService.map)

    try {
      const pos = await this.locationService.getPosition()
      if (pos) {
        this.mapService.map.setCenter(pos.exact)
        this.handlePositionChange(pos)
      }
    } catch (error) {
      console.log(error)
    }
  }

  ngOnDestroy(): void {
    this.isFollowPositionActive = false
    this.marker.remove()
    this.screenLockService.stopScreenLock()
    this.cleanUp$.next()
    this.cleanUp$.complete()
  }

  handlePositionChange(currentPosition: CurrentPositionModel): void {
    if (this.mapService.isMapReady && this.isFollowPositionActive) {
      const currentFeatures = this.mapService.getFeatures(
        Layers.RoadSegment.name,
        currentPosition.bbox,
      )
      this.features = currentFeatures
      if (this.mapService.map.isStyleLoaded()) {
        this.mapService.map.easeTo({
          center: currentPosition.exact,
          pitch: 45,
          bearing: currentPosition.heading ? currentPosition.heading : 0,
        })
        if (this.features.length > 0) {
          this.appState.setState(AppPaths.Mobile, {
            selectedFeatureId: this.features[0].id?.toString() ?? '',
          })
        }
        this.marker.setLngLat(currentPosition.exact)
      }
    }
  }

  toggleLayerVisbility(layerId: string) {
    switch (layerId) {
      case Layers.VehicleDataRain.name:
        this.isVehicleLayerVisible = !this.isVehicleLayerVisible
        break
      case Layers.RoadSegment.name:
        this.isRoadConditionLayerVisibile = !this.isRoadConditionLayerVisibile
        break
      case Layers.WeatherPrediction.name:
        this.isWeatherLayerVisible = !this.isWeatherLayerVisible
        break
      case 'warnings':
        this.isAreaWarningsVisible = !this.isAreaWarningsVisible
        this.isTLLayerVisible = this.isAreaWarningsVisible
        this.isBDVLayerVisible = this.isAreaWarningsVisible
        this.isLVLayerVisible = this.isAreaWarningsVisible
        this.isVALayerVisible = this.isAreaWarningsVisible
        break
      default:
        break
    }
    this.updateQueryParameters()
  }

  toggleLayerSwitchVisibility() {
    this.isLayerSelectionVisible = !this.isLayerSelectionVisible
  }

  toggleFollowPosition() {
    this.isFollowPositionActive = !this.isFollowPositionActive
  }

  updateQueryParameters() {
    const layers = []
    const metrics = []

    if (this.isVehicleLayerVisible) {
      layers.push(Layers.VehicleDataRain.name)
      metrics.push(RcsMetrics.VehicleData_HeavyRain)
    }
    if (this.isRoadConditionLayerVisibile) {
      layers.push(Layers.RoadSegment.name)
      metrics.push(RcsMetrics.RoadSegment_RoadCondition)
    }
    if (this.isWeatherLayerVisible) {
      layers.push(Layers.WeatherPrediction.name)
      metrics.push(RcsMetrics.WeatherPrediction_Wft)
    }
    if (this.isTLLayerVisible) {
      layers.push(Layers.TLWarning.name)
    }
    if (this.isBDVLayerVisible) {
      layers.push(Layers.BDVWarning.name)
    }
    if (this.isLVLayerVisible) {
      layers.push(Layers.LVWarning.name)
    }
    if (this.isVALayerVisible) {
      layers.push(Layers.VAWarning.name)
    }

    this.appState.setState(AppPaths.Mobile, {
      layers: layers?.toString(),
      metrics: metrics?.toString(),
      lat: this.mapService.map.getCenter().lat,
      lon: this.mapService.map.getCenter().lng,
      zoom: this.mapService.map.getZoom(),
    })
  }
}
