import { MetricModel } from 'src/app/shared/models/metric.model'
import {
  obstacleLegendProviderData,
  obstacleLegendVehicleData,
  roadHazardLegendObstacles,
} from '../utils/obstacle-legend-utils'
import { defaultExpression, defaultLegend } from 'src/app/shared/utils/metric.utils'
import { createPaintExpression } from '../utils/obstacle-paint-utils'

export enum ObstaclesMetrics {
  VehicleData_Obstacles = 'vd_o',
  Obstacles_Accidents = 'ob_a',
  Obstacles_BDV = 'ob_bdv',
  Obstacles_Providers = 'ob_p',
}

export const getMetricFromMap = (metric: string): MetricModel => {
  return (
    ObstacleMetricMap.get(metric) || {
      displayName: 'Default',
      expression: defaultExpression,
      name: 'default',
      icon: 'question',
      key: 'default',
      legend: defaultLegend,
    }
  )
}

export const ObstacleMetricMap = new Map<string, MetricModel>()

ObstacleMetricMap.set(ObstaclesMetrics.VehicleData_Obstacles, {
  name: 'obstacles',
  expression: ['==', ['get', 'obstacles'], ObstaclesMetrics.VehicleData_Obstacles],
  icon: 'car-crash',
  key: ObstaclesMetrics.VehicleData_Obstacles,
  displayName: 'obstacles',
  legend: obstacleLegendVehicleData,
})

ObstacleMetricMap.set(ObstaclesMetrics.Obstacles_Accidents, {
  name: 'accident',
  expression: createPaintExpression('accident'),
  icon: 'car-crash',
  key: ObstaclesMetrics.Obstacles_Accidents,
  displayName: '',
  legend: roadHazardLegendObstacles,
})

ObstacleMetricMap.set(ObstaclesMetrics.Obstacles_BDV, {
  name: 'brokenDownVehicle',
  expression: createPaintExpression('brokenDownVehicle'),
  icon: 'car-mechanic',
  key: ObstaclesMetrics.Obstacles_BDV,
  displayName: '',
  legend: roadHazardLegendObstacles,
})

ObstacleMetricMap.set(ObstaclesMetrics.Obstacles_Providers, {
  name: 'providerObstacles',
  expression: ['==', ['get', 'obstacles'], ObstaclesMetrics.Obstacles_Providers],
  icon: 'car-crash',
  key: ObstaclesMetrics.Obstacles_Providers,
  displayName: 'Provider Obstacles',
  legend: obstacleLegendProviderData,
})
