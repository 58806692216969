<div class="row rounded-2 bg-card">
  <div class="col text-start mt-2 mb-2">
    <span class="fs-lg text-white">{{ category?.title }}</span>
    <i *ngIf="icon" class="{{ icon }}" [ngStyle]="{ transform: 'rotate(' + bearing + 'deg)' }"></i>
  </div>
</div>
<div class="row mt-2">
  <app-details-item
    class="col-12 col-md-6 gy-2 gx-5"
    *ngFor="let content of category?.items"
    [description]="content.description"
    [value]="content.value"
    [unit]="content.unit"
  ></app-details-item>
</div>
