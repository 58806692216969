import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { MapService } from 'src/app/core/services/map.service'
import maplibregl, {
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from 'maplibre-gl'
import { emptyGeoJsonSource } from 'src/app/core/utils/source-utils'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { NavigationStart, Router } from '@angular/router'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from '../layers-config/layers'
import { DataCustomerWarningService } from '../services/data-customer-warning.service'
import { addMinutes } from 'date-fns'
import { WarningDatasources, WarningTypes } from '../enums/warning-types.enum'
import { AuthService } from 'src/app/core/services/auth.service'
import { MapIcons } from 'src/app/core/enums/map-icons.enum'
import {
  colorByPropertyName,
  strokeByPropertyName,
  warningFilter,
} from '../utils/data-driven-property'
import { createDonutChart } from '../utils/paint.utils'

@Injectable()
export class LayerCustomerWarningService {
  private layer = this.layerPrefix
  public layerLinesId = `${this.layer}-lines`
  public layerHexagonsId = `${this.layer}-hexagons`
  public layerSymbolsId = `${this.layer}-symbols`
  public layerWarningDirectionId = `${this.layer}-direction`
  public sourceLineId = `${this.layer}-line-source`
  private sourceHexagonId = `${this.layer}-hexagon-source`
  public sourceSymbolsId = `${this.layer}-symbol-source`
  public sourceWarningDirectionId = `${this.layer}-direction-source`
  public isLayerVisible = false
  private cleanUp$ = new Subject<void>()
  private cancelRequests$ = new Subject<void>()
  private timestamp: Date | undefined
  private timespan: number | undefined
  public selectedFeatureId: string = ''
  public selectedSegmentId: string = ''
  public layerInitialized: boolean = false
  public onDestroy: boolean = false
  private currentDatasource: WarningDatasources = WarningDatasources.Rcs
  private activeMetrics: WarningTypes[] = []
  private updateMarkerCallback = () => {}
  public clusterMarkers: maplibregl.Marker[] = []

  private warningEventsLayerSpecification: SymbolLayerSpecification = {
    id: this.layerSymbolsId,
    source: this.sourceSymbolsId,
    minzoom: this.minZoom,
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-image': [
        'case',
        warningFilter.accidentFilter,
        MapIcons.VA,
        warningFilter.bdvFilter,
        MapIcons.BDV,
        warningFilter.csFilter,
        MapIcons.CS,
        warningFilter.lvFilter,
        MapIcons.LV,
        warningFilter.tlFilter,
        MapIcons.TL,
        warningFilter.swFilter,
        MapIcons.SW,
        warningFilter.hrFilter,
        MapIcons.HR,
        warningFilter.hsFilter,
        MapIcons.HS,
        '',
      ],
      'icon-size': [
        'case',
        ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
        0.5,
        0.35,
      ],
    },
    paint: {
      'icon-opacity': ['case', ['all', ['<', ['get', 'expireIn'], 5]], 0.4, 1],
    },
  }

  private roadSegmentWarningLayerSpecification: LineLayerSpecification = {
    id: this.layerLinesId,
    type: 'line',
    minzoom: 8,
    source: this.sourceLineId, // reference the data source
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-opacity': ['case', ['all', ['<', ['get', 'expireIn'], 5]], 0.2, 1],
      'line-color': colorByPropertyName,
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          6,
          ['==', ['get', 'segmentId'], Number(this.appState.getState().selectedFeatureId ?? '')],
          6,
          2,
        ],
        18,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          14,
          ['==', ['get', 'segmentId'], Number(this.appState.getState().selectedFeatureId ?? '')],
          14,
          10,
        ],
        22,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          18,
          ['==', ['get', 'segmentId'], Number(this.appState.getState().selectedFeatureId ?? '')],
          18,
          14,
        ],
      ],
    },
  }

  private areaWarningLayerSpecification: FillLayerSpecification = {
    id: this.layerHexagonsId,
    minzoom: 7,
    type: 'fill',
    source: this.sourceHexagonId, // reference the data source
    paint: {
      'fill-color': colorByPropertyName,
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
        0.5,
        0.2,
      ],
      'fill-outline-color': strokeByPropertyName,
    },
    layout: {
      visibility: 'none',
    },
  }

  private warningDirectionLayerSpecification: SymbolLayerSpecification = {
    id: this.layerWarningDirectionId,
    source: this.sourceLineId,
    minzoom: this.minZoom,
    type: 'symbol',
    filter: ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-size': 0.8,
      'icon-image': ['case', ['==', ['get', 'direction'], 'both'], 'arrow-both', 'arrow'],
      'icon-rotate': 180,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  }

  constructor(
    private router: Router,
    private readonly mapService: MapService,
    private readonly mapEventsService: MapEventsService,
    private appState: AppStateService,
    private authService: AuthService,
    @Inject(String) private layerPrefix: string,
    @Inject(WarningTypes) private warningTypes: WarningTypes[],
    @Inject(Boolean) private enableOnlySymbols: boolean,
    @Inject(Number) private minZoomAvailable: number,
    @Inject(Number) private maxZoomAvailable: number,
    @Inject(Number) private minZoom: number,
    private dataService: DataCustomerWarningService,
    @Inject(WarningDatasources) dataSource?: WarningDatasources,
  ) {
    this.activeMetrics = warningTypes
    dataSource
      ? (this.currentDatasource = dataSource)
      : (this.currentDatasource = this.authService.getUserClaims().userDataFilters[0])

    this.selectedFeatureId = this.appState.getState().selectedFeatureId ?? ''
    if (mapService.isMapReady) {
      this.initializeLayer()
    } else {
      this.mapService.mapReadyChanged.subscribe(() => {
        this.initializeLayer()
      })
    }
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe((state) => {
      if (this.layerInitialized) {
        this.evaluateState()
      }
    })
    this.appState.hasWarningDatasourceChanged$
      .pipe(takeUntil(this.cleanUp$))
      .subscribe((datasource) => {
        this.currentDatasource = datasource
        this.removeMarkers()
        this.reload()
      })

    // NgOnDestroy is not working on instances created by a factory, therefore we need this workaround
    this.router.events.pipe(takeUntil(this.cleanUp$)).forEach((event) => {
      if (event instanceof NavigationStart) {
        this.ngOnDestroy()
      }
    })
  }

  private async initializeLayer() {
    if (!this.enableOnlySymbols) {
      this.mapService.map.addSource(this.sourceLineId, {
        ...emptyGeoJsonSource,
      })
      this.mapService.map.addSource(this.sourceHexagonId, {
        ...emptyGeoJsonSource,
      })
      this.mapService.map.addLayer(this.roadSegmentWarningLayerSpecification)
      this.mapService.map.addLayer(this.areaWarningLayerSpecification)
      this.mapService.map.addLayer(this.warningDirectionLayerSpecification)
      this.mapEventsService.registerForMapEvents({ layerId: this.layerLinesId }, true)
      this.mapEventsService.registerForMapEvents({ layerId: this.layerHexagonsId }, true)
    }

    this.mapService.map?.addSource(this.sourceSymbolsId, {
      ...emptyGeoJsonSource,
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50,
      promoteId: 'featureId',
      clusterProperties: {
        // keep separate counts for each warning category in a cluster
        [WarningTypes.VehicleAccident]: ['+', ['case', warningFilter.accidentFilter, 1, 0]],
        [WarningTypes.BrokendownVehicle]: ['+', ['case', warningFilter.bdvFilter, 1, 0]],
        [WarningTypes.TractionLoss]: ['+', ['case', warningFilter.tlFilter, 1, 0]],
        [WarningTypes.ConstructionSite]: ['+', ['case', warningFilter.csFilter, 1, 0]],
        [WarningTypes.HeavyRain]: ['+', ['case', warningFilter.hrFilter, 1, 0]],
        [WarningTypes.HeavySnow]: ['+', ['case', warningFilter.hsFilter, 1, 0]],
        [WarningTypes.StrongWind]: ['+', ['case', warningFilter.swFilter, 1, 0]],
        [WarningTypes.LowVisibility]: ['+', ['case', warningFilter.lvFilter, 1, 0]],
      },
    })

    // callback for updating markers when source data changes
    this.updateMarkerCallback = () => {
      if (
        this.mapService.isMapReady &&
        this.mapService.map.getSource(this.sourceSymbolsId) &&
        this.mapService.map.isSourceLoaded(this.sourceSymbolsId)
      ) {
        this.updateMarkers()
      }
    }
    this.mapEventsService.registerForMapSourceEvents(this.updateMarkerCallback)
    this.mapService.map?.addLayer(this.warningEventsLayerSpecification)

    this.layerInitialized = true
    this.evaluateState()
  }

  ngOnDestroy() {
    this.onDestroy = true

    if (!this.enableOnlySymbols) {
      this.mapService.map.removeLayer(this.layerLinesId)
      this.mapService.map.removeLayer(this.layerHexagonsId)
      this.mapService.map.removeLayer(this.layerWarningDirectionId)
      this.mapService.map.removeSource(this.sourceLineId)
      this.mapService.map.removeSource(this.sourceHexagonId)
      this.mapEventsService.detachMapEvents({ layerId: this.layerLinesId })
      this.mapEventsService.detachMapEvents({ layerId: this.layerHexagonsId })
    }
    this.mapEventsService.detachMapSourceEvents(this.updateMarkerCallback)
    this.mapService.map.removeLayer(this.layerSymbolsId)
    this.mapService.map.removeSource(this.sourceSymbolsId)

    this.cancelRequests()
    this.cleanUp$.next()
    this.removeMarkers()
  }

  public async reload() {
    this.cancelRequests()
    if (this.mapService.isMapReady && this.mapService.map.getZoom() >= this.minZoom) {
      const xyzTiles = this.mapService.getXyzTiles(this.maxZoomAvailable, this.minZoomAvailable)

      await this.dataService.loadWarningEvents(
        this.activeMetrics,
        this.currentDatasource,
        this.timestamp,
        xyzTiles,
      )
      this.updateLayer()
    } else {
      this.removeMarkers()
    }
  }

  public async updateLayer() {
    if (this.mapService.isMapReady) {
      const queryTimestamp =
        this.timestamp && this.timespan ? addMinutes(this.timestamp, this.timespan) : this.timestamp
      const warningData = this.dataService.getRawWarningEvents(queryTimestamp)
      warningData[0].features = warningData[0].features.filter((feature) => {
        const effectiveDate = queryTimestamp ? new Date(queryTimestamp).getTime() : Date.now()
        return addMinutes(feature.properties.expiryTime, 5).getTime() > effectiveDate
      })
      warningData[1].features = warningData[1].features.filter((feature) => {
        const effectiveDate = queryTimestamp ? new Date(queryTimestamp).getTime() : Date.now()
        return addMinutes(feature.properties.expiryTime, 5).getTime() > effectiveDate
      })
      warningData[2].features = warningData[2].features.filter((feature) => {
        const effectiveDate = queryTimestamp ? new Date(queryTimestamp).getTime() : Date.now()
        return addMinutes(feature.properties.expiryTime, 5).getTime() > effectiveDate
      })

      this.mapService.setGeoJsonData(this.sourceSymbolsId, warningData[0])
      this.enableOnlySymbols
        ? ''
        : this.mapService.setGeoJsonData(this.sourceLineId, warningData[1])
      this.enableOnlySymbols
        ? ''
        : this.mapService.setGeoJsonData(this.sourceHexagonId, warningData[2])

      this.changeSelectAndHoverStateOfSelectedFeature()
    }
  }

  private evaluateState() {
    if (
      this.appState.getState().layers?.includes(Layers.VAWarning.name) ||
      this.appState.getState().layers?.includes(Layers.BDVWarning.name) ||
      this.appState.getState().layers?.includes(Layers.TLWarning.name) ||
      this.appState.getState().layers?.includes(Layers.CSWarning.name) ||
      this.appState.getState().layers?.includes(Layers.HRWarning.name) ||
      this.appState.getState().layers?.includes(Layers.HSWarning.name) ||
      this.appState.getState().layers?.includes(Layers.LVWarning.name) ||
      this.appState.getState().layers?.includes(Layers.SWWarning.name)
    ) {
      this.setVisibility(true)
    } else {
      this.setVisibility(false)
    }

    // if layer is not visible stop all events
    if (this.isLayerVisible === false) {
      this.activeMetrics = []
      return
    }

    // TODO We should definitly change this to metrics and filters be the same
    this.activeMetrics = []

    if (this.appState.getState().layers?.includes(Layers.VAWarning.name)) {
      this.activeMetrics.push(WarningTypes.VehicleAccident)
    }
    if (this.appState.getState().layers?.includes(Layers.BDVWarning.name)) {
      this.activeMetrics.push(WarningTypes.BrokendownVehicle)
    }
    if (this.appState.getState().layers?.includes(Layers.TLWarning.name)) {
      this.activeMetrics.push(WarningTypes.TractionLoss)
    }
    if (this.appState.getState().layers?.includes(Layers.CSWarning.name)) {
      this.activeMetrics.push(WarningTypes.ConstructionSite)
    }
    if (this.appState.getState().layers?.includes(Layers.HRWarning.name)) {
      this.activeMetrics.push(WarningTypes.HeavyRain)
    }
    if (this.appState.getState().layers?.includes(Layers.HSWarning.name)) {
      this.activeMetrics.push(WarningTypes.HeavySnow)
    }
    if (this.appState.getState().layers?.includes(Layers.LVWarning.name)) {
      this.activeMetrics.push(WarningTypes.LowVisibility)
    }
    if (this.appState.getState().layers?.includes(Layers.SWWarning.name)) {
      this.activeMetrics.push(WarningTypes.StrongWind)
    }

    if (
      this.appState.hasLayerVisibilityChanged(Layers.VAWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.BDVWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.TLWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.CSWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.HRWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.HSWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.LVWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.SWWarning.name)
    ) {
      this.reload()
    }

    if (!this.dataService.isDataInitialized) {
      this.reload()
    }

    if (this.appState.hasMapBoundsChanged() && !this.appState.getState().historyEnabled) {
      this.reload()
    }

    if (!this.timestamp) this.timestamp = this.appState.selectTimestamp()

    if (!this.timespan) this.timespan = this.appState.getState().timespan

    if (this.appState.hasTimespanChanged()) {
      this.timespan = this.appState.state.timespan
      this.updateLayer()
    }

    if (this.appState.hasTimestampChanged()) {
      this.timestamp = this.appState.selectTimestamp()
      this.reload()
    }

    if (
      this.appState.hasSelectedFeatureIdChanged() ||
      this.appState.getState().selectedFeatureId !== ''
    ) {
      this.selectedFeatureId = this.appState.getState().selectedFeatureId ?? ''
      this.changeSelectAndHoverStateOfSelectedFeature()
    }
  }

  setVisibility(isVisible: boolean): void {
    this.isLayerVisible = isVisible
    if (this.mapService.isMapReady) {
      this.mapService.setLayerVisibility(this.layerSymbolsId, isVisible)

      this.enableOnlySymbols ? '' : this.mapService.setLayerVisibility(this.layerLinesId, isVisible)

      this.enableOnlySymbols
        ? ''
        : this.mapService.setLayerVisibility(this.layerHexagonsId, isVisible)
      this.enableOnlySymbols
        ? ''
        : this.mapService.setLayerVisibility(this.layerWarningDirectionId, isVisible)

      if (!isVisible) {
        this.cancelRequests()
      }
    }
  }

  private cancelRequests() {
    this.cancelRequests$.next()
    this.cancelRequests$.complete()
    this.cancelRequests$ = new Subject()
  }

  private changeSelectAndHoverStateOfSelectedFeature() {
    if (this.onDestroy) return
    this.mapService.map.setLayoutProperty(this.layerSymbolsId, 'icon-size', [
      'case',
      ['==', ['id'], Number(this.selectedFeatureId)],
      0.5,
      0.35,
    ])

    if (!this.enableOnlySymbols) {
      this.mapService.map.setFilter(this.layerWarningDirectionId, [
        '==',
        ['id'],
        Number(this.appState.getState().selectedFeatureId ?? ''),
      ])

      let selectedSegmentId =
        this.mapService.map.getFeatureState({
          source: this.sourceLineId,
          id: this.selectedFeatureId,
        })?.selectedGeoId || ''

      this.mapService.map.setPaintProperty(this.layerLinesId, 'line-width', [
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          6,
          ['==', ['get', 'segmentId'], selectedSegmentId],
          6,
          2,
        ],
        18,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          14,
          ['==', ['get', 'segmentId'], selectedSegmentId],
          14,
          10,
        ],
        22,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          16,
          ['==', ['get', 'segmentId'], selectedSegmentId],
          16,
          14,
        ],
      ])
      this.mapService.map.setPaintProperty(this.layerHexagonsId, 'fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        ['==', ['id'], Number(this.selectedFeatureId)],
        0.5,
        0.2,
      ])
    }
  }

  removeMarkers() {
    for (let marker of this.clusterMarkers) {
      marker.remove()
    }
    this.clusterMarkers = []
  }

  private async updateMarkers() {
    this.removeMarkers()
    let features = this.mapService.map.querySourceFeatures(this.sourceSymbolsId, {
      filter: ['all', ['has', 'point_count']],
    })
    features = features.filter((feature) => {
      return this.activeMetrics.some((activeMetric) => feature.properties[activeMetric] > 0)
    })

    // for every cluster on the screen, create an HTML marker for it
    for (let feature of features) {
      const coords = (feature.geometry as any).coordinates
      const props = feature.properties as any
      if (!props.cluster) continue
      const id = props.cluster_id
      const el = createDonutChart(props, this.activeMetrics)
      this.clusterMarkers.push(
        new maplibregl.Marker({
          element: el,
        }).setLngLat(coords),
      )
    }
    for (let marker of this.clusterMarkers) {
      marker.addTo(this.mapService.map)
    }
  }
}
