<div class="d-flex flex-column py-1 mb-5">
  <div>{{ name }}</div>
  <div class="fs-2xs mb-2">{{ metric?.legend?.type == 'hazard' ? '' : metric?.displayName }}</div>
  <div class="d-flex gap-2">
    <app-legend-item
      class="flex-grow-1"
      *ngFor="let content of metric?.legend?.items"
      [description]="content.description"
      [value]="content.value"
      [type]="metric?.legend?.type"
      [category]="metric?.legend?.category"
    >
    </app-legend-item>
  </div>
</div>
