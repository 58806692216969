import { Component } from '@angular/core'
import { MetricModel } from 'src/app/shared/models/metric.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { ObstaclesMetrics, getMetricFromMap } from '../../../obstacle/enums/obstacle-metrics.enum'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-obstacle-layer-legend',
  templateUrl: './obstacle-layer-legend.component.html',
})
export class ObstacleLayerLegendComponent {
  private cleanUp$ = new Subject<void>()
  public metrics: string | undefined
  public isVehicleDataBdvLayerVisible = false
  public isVehicleDataAccidentsLayerVisible = false
  public isBingBDVLayerVisible = false
  public isBingAccidentLayerVisible = false
  public isLayerSelectionVisible = false
  public isVAWarningLayerVisible = false
  public isBDVWarningLayerVisible = false

  public obstacleMetric: MetricModel | undefined = undefined
  public vehicleDataMetric: MetricModel | undefined = undefined
  public providerMetric: MetricModel | undefined = undefined

  constructor(private appState: AppStateService) {
    this.evaluateQueryParameters()

    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe(() => {
      this.evaluateQueryParameters()
    })
  }
  ngOnDestroy(): void {
    this.cleanUp$.next()
    this.cleanUp$.complete()
  }

  isOneVehicleDataLayerVisible() {
    if (this.isVehicleDataBdvLayerVisible || this.isVehicleDataAccidentsLayerVisible) {
      return true
    } else {
      return false
    }
  }

  isOneProviderLayerVisible() {
    if (this.isBingAccidentLayerVisible || this.isBingBDVLayerVisible) {
      return true
    } else {
      return false
    }
  }

  isOneWarningLayerVisible() {
    if (this.isVAWarningLayerVisible || this.isBDVWarningLayerVisible) {
      return true
    } else {
      return false
    }
  }

  evaluateQueryParameters() {
    this.appState.getState().layers?.includes(Layers.VehicleDataAccidents.name)
      ? (this.isVehicleDataAccidentsLayerVisible = true)
      : (this.isVehicleDataAccidentsLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.VehicleDataBdv.name)
      ? (this.isVehicleDataBdvLayerVisible = true)
      : (this.isVehicleDataBdvLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.BingAccidents.name)
      ? (this.isBingAccidentLayerVisible = true)
      : (this.isBingAccidentLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.BingBdv.name)
      ? (this.isBingBDVLayerVisible = true)
      : (this.isBingBDVLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.VAWarning.name)
      ? (this.isVAWarningLayerVisible = true)
      : (this.isVAWarningLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.BDVWarning.name)
      ? (this.isBDVWarningLayerVisible = true)
      : (this.isBDVWarningLayerVisible = false)

    this.obstacleMetric = this.isBDVWarningLayerVisible
      ? getMetricFromMap(ObstaclesMetrics.Obstacles_BDV)
      : getMetricFromMap(ObstaclesMetrics.Obstacles_Accidents)
    this.vehicleDataMetric = getMetricFromMap(ObstaclesMetrics.VehicleData_Obstacles)
    this.providerMetric = getMetricFromMap(ObstaclesMetrics.Obstacles_Providers)
  }
}
