import { Component, Inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { debounceTime } from 'rxjs/operators'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { format } from 'date-fns'
import { WarningDataModel } from 'src/app/shared/models/warning-event.model'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { firstValueFrom } from 'rxjs/internal/firstValueFrom'
import { AreaTestWarningDataModel } from 'src/app/shared/models/test-warning-creation.model'
import { Feature, LineString } from 'geojson'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'
import { AppConfigModel } from 'src/app/core/models/app-config.model'
import { APP_CONFIG } from 'src/app/app.config'
import { getEmailLink } from '../../../utils/simulation-warning-details-utils'
import { DataSimulationWarningService } from 'src/app/shared/services/data-simulation-warning.service'
import { LayerSimulationWarningService } from 'src/app/shared/layers/layer-simulation-warning.service'

@Component({
  selector: 'app-simulation-area-warnings-details',
  templateUrl: './simulation-area-warnings-details.component.html',
})
export class SimulationAreaWarningsDetailsComponent {
  public selectedFeatureId = ''
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public isTestWarning: boolean = false
  public showRaw: boolean = true
  public bearing = ''
  public requestStatus: string = 'btn-dangerous'
  public statusMessage: string = 'Delete warning'
  public shake = ''
  public refreshIntervalId: NodeJS.Timeout | undefined
  public mailToLink = ''

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private readonly mapEventService: MapEventsService,
    public appState: AppStateService,
    private dataService: DataSimulationWarningService,
    private simulationTestLayer: LayerSimulationWarningService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.isTestWarning = false
        this.showRaw = true
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (features[this.index].layer.id === `${Layers.SimWarning.name}-hexagons`) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    if (
      this.selectedFeatureId &&
      (this.selectedFeatureId == this.features[this.index].id?.toString() ?? '')
    ) {
      this.selectedFeatureId = ''
      this.close()
      return
    }
    this.mailToLink = getEmailLink(this.features[this.index])
    this.selectedFeatureId = this.features[this.index].id?.toString() ?? ''
    const properties: WarningDataModel = this.features[this.index].properties as WarningDataModel
    this.rawFeature = JSON.stringify(this.features[this.index], undefined, 2)
    this.mapWarningsToDetails(properties)
    this.appState.setState(AppPaths.Simulation, {
      selectedFeatureId: this.selectedFeatureId,
    })
  }

  mapWarningsToDetails(properties: WarningDataModel) {
    let rawMetric = {
      title: 'Hazards',
      items: [
        {
          description: 'Global ID',
          value: this.selectedFeatureId,
        },
        {
          description: 'H3 ID',
          value: properties.h3id,
        },
        {
          description: 'Country Code',
          value: properties.countryCode,
        },
        {
          description: 'Type',
          value: properties.name,
        },
        {
          description: `Valid time (${getTimeZoneLabel(new Date(properties['startTime']))})`,
          value: `${
            properties['startTime']
              ? format(new Date(properties['startTime']), 'yyyy-MM-dd HH:mm')
              : '-'
          } - ${
            properties['expiryTime'] ? format(new Date(properties['expiryTime']), 'HH:mm') : '-'
          }`,
        },
      ],
    }
    properties.target
      ? rawMetric.items.push({ description: 'Target', value: properties.target })
      : null

    this.details?.set('RawMetric', rawMetric)
  }

  close() {
    this.appState.setState(AppPaths.Simulation, {
      selectedFeatureId: undefined,
    })
    this.selectedFeatureId = ''
    this.details.clear()
  }

  async deleteWarning() {
    let feature = this.features[this.index] as unknown as Feature<LineString, WarningDataModel>

    let newWarning: AreaTestWarningDataModel = {
      warningState: feature.properties.name,
      longitude: JSON.parse(feature.properties.eventCoordinates)[0],
      latitude: JSON.parse(feature.properties.eventCoordinates)[1],
      ttl: -1,
      targets: feature.properties.target ? [feature.properties.target] : [],
      h3id: feature.properties.h3id,
      countryCode: feature.properties.countryCode,
    }

    try {
      const result: any = await firstValueFrom(this.dataService.createAreaTestWarnings(newWarning))
      this.requestStatus = 'btn-noncritical'
      this.statusMessage = 'Deletion initialized ...'
      setTimeout(() => {
        this.requestStatus = 'btn-dangerous'
        this.statusMessage = 'Delete warning'
        this.close()
      }, 1000)

      clearInterval(this.refreshIntervalId)
      this.refreshIntervalId = setInterval(() => this.simulationTestLayer.reload(), 2000)

      setTimeout(
        (function (id_local) {
          return function () {
            clearInterval(id_local)
          }
        })(this.refreshIntervalId),
        30000,
      )
    } catch (error) {
      this.shake = 'shake'
      this.requestStatus = 'btn-dangerous'
      this.statusMessage = 'Error'
      setTimeout(() => {
        this.shake = ''
        this.requestStatus = 'btn-dangerous'
        this.statusMessage = 'Delete warning'
      }, 1000)
    }
  }
}
