<div
  class="d-flex bg-dark border border-darkgray z-1 shadow-sm rounded-2 align-items-center text-gray"
>
  <div
    class="d-flex flex-column align-items-center justify-content-center p-2 hover-effect cursor-pointer"
    (click)="visibilityToggled.emit()"
  >
    <span class="rb-ic rb-ic-layers-stack" [ngClass]="'fs-' + size"></span>
  </div>

  <div class="position-relative text-deactivated" [ngClass]="'hf-' + size">
    <div
      class="z-1 bg-dark rounded-2 position-absolute bottom-n1 start-2 d-flex gap-3 py-2 ps-3 pe-2 border border-darkgray"
      [class.d-none]="isLayerSelectionVisibile === false"
    >
      <ng-content select="[layers]"></ng-content>
      <span
        class="rb-ic rb-ic-options-vertical ho-layer-item"
        [ngClass]="'fs-2xl'"
        (click)="layerConfigVisibiltyToggled.emit()"
        [class.d-none]="isLayerConfigAvailable === false"
      ></span>
    </div>
  </div>
</div>
