import { Subject } from 'rxjs'
import { Listener, Map, MapGeoJSONFeature } from 'maplibre-gl'
import { MapService } from './map.service'
import { LayerBaseServiceModel } from '../models'
import { Injectable } from '@angular/core'

interface HoveredFeature {
  featureId: any
  layer?: string
  source: string
}

@Injectable({
  providedIn: 'root',
})
export class MapEventsService {
  private mapgl!: Map
  // private hoveredStateId: any
  private registeredLayers: string[] = []
  private hoveredFeatures: any[] = []
  public featureClick: Subject<MapGeoJSONFeature[]> = new Subject<MapGeoJSONFeature[]>()

  constructor(private readonly mapService: MapService) {
    this.mapgl = mapService.map
    this.handleHoverStateOnMove = this.handleHoverStateOnMove.bind(this)
    this.handleHoverStateOnLeave = this.handleHoverStateOnLeave.bind(this)
    this.handleClickEvent = this.handleClickEvent.bind(this)
  }

  public registerForMapEvents(layer: LayerBaseServiceModel, withHover: boolean): void {
    if (!this.registeredLayers.includes(layer.layerId)) {
      if (withHover) {
        this.mapgl.on('mousemove', layer.layerId, this.handleHoverStateOnMove)
        this.mapgl.on('mouseleave', layer.layerId, this.handleHoverStateOnLeave)
      }
      this.mapgl.on('click', layer.layerId, this.handleClickEvent)
      this.registeredLayers.push(layer.layerId)
    }
  }

  public detachMapEvents(layer: LayerBaseServiceModel): void {
    this.mapgl.off('mousemove', layer.layerId, this.handleHoverStateOnMove)
    this.mapgl.off('mouseleave', layer.layerId, this.handleHoverStateOnLeave)
    this.mapgl.off('click', layer.layerId, this.handleClickEvent)
    this.registeredLayers = this.registeredLayers.filter(
      (registeredLayer) => registeredLayer !== layer.layerId,
    )
  }

  public registerForMapSourceEvents(callback: any): void {
    this.mapgl.on('sourcedata', callback)
  }

  public detachMapSourceEvents(callback: any): void {
    this.mapgl.off('sourcedata', callback)
  }

  private handleClickEvent(ev: { lngLat: any; features?: any[] }): void {
    if (ev && ev.features && ev.features.length > 0) {
      let features: MapGeoJSONFeature[] = ev.features
      // filter duplicates due to tile borders
      features = features.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.properties['segmentId'] === value.properties['segmentId'] &&
              t.id === value.id &&
              t.properties['startTime'] == value.properties['startTime'] &&
              t.properties['expiryTime'] == value.properties['expiryTime'],
          ),
      )
      features.forEach((feature) => {
        this.mapgl.setFeatureState(feature, {
          selectedLat: ev.lngLat.lat.toFixed(6),
          selectedLng: ev.lngLat.lng.toFixed(6),
          // Warnings line segments have segmentId in properties and static segments have it in id
          selectedGeoId: feature.properties['segmentId'] || feature.id || '',
        })
        feature.state['selectedLat'] = ev.lngLat.lat.toFixed(6)
        feature.state['selectedLng'] = ev.lngLat.lng.toFixed(6)
      })
      this.featureClick.next(features)
    }
  }

  private handleHoverStateOnMove(ev: { features?: MapGeoJSONFeature[] }): void {
    if (ev && ev.features && ev.features.length > 0) {
      const oldHoveredFeatures = this.hoveredFeatures.filter(
        (featureOld) => !ev.features?.find((featuresNew) => featureOld.id === featuresNew.id),
      )
      for (const feature of oldHoveredFeatures) {
        const f = feature.sourceLayer
          ? {
              id: Number(feature.id),
              sourceLayer: feature.sourceLayer,
              source: feature.source,
            }
          : {
              id: Number(feature.id),
              source: feature.source,
            }
        this.mapgl.setFeatureState(f, { hover: false })
      }
      this.mapgl.getCanvas().style.cursor = 'pointer'
      this.hoveredFeatures = []
      for (const feature of ev.features) {
        if (!feature.state['hover']) {
          const f = feature.sourceLayer
            ? {
                id: Number(feature.id),
                sourceLayer: feature.sourceLayer,
                source: feature.source,
              }
            : {
                id: Number(feature.id),
                source: feature.source,
              }
          this.mapgl.setFeatureState(f, { hover: true })
        }
        this.hoveredFeatures.push(feature)
      }
    }
  }

  private handleHoverStateOnLeave(): void {
    this.mapgl.getCanvas().style.cursor = ''
    for (const feature of this.hoveredFeatures) {
      const f = feature.sourceLayer
        ? {
            id: Number(feature.id),
            sourceLayer: feature.sourceLayer,
            source: feature.source,
          }
        : {
            id: Number(feature.id),
            source: feature.source,
          }

      this.mapgl.setFeatureState(f, { hover: false })
    }
    this.hoveredFeatures = []
  }
}
