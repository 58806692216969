import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { MapService } from 'src/app/core/services/map.service'
import maplibregl, {
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'maplibre-gl'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from '../layers-config/layers'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { DataSimulationWarningService } from '../services/data-simulation-warning.service'
import { AppConfigModel } from 'src/app/core/models'
import { MapIcons } from 'src/app/core/enums/map-icons.enum'
import { emptyGeoJsonSource } from 'src/app/core/utils/source-utils'
import { addMinutes } from 'date-fns'
import { WarningDatasources, WarningTypes } from '../enums/warning-types.enum'
import { AuthService } from 'src/app/core/services/auth.service'
import {
  colorByPropertyName,
  strokeByPropertyName,
  warningFilter,
} from '../utils/data-driven-property'
import { createDonutChart } from '../utils/paint.utils'
import { APP_CONFIG } from '../../app.config'

@Injectable()
export class LayerSimulationWarningService implements OnDestroy {
  private layerStaticSegmentId = Layers.CreateSimRoadWarnings.name
  private layerStaticHexagonId = Layers.CreateSimAreaWarnings.name
  private layerStaticDirectionWarningId = `${Layers.CreateSimRoadWarnings.name}-direction`
  private layerLinesWarningId = `${Layers.SimWarning.name}-lines`
  private layerHexagonsWarningId = `${Layers.SimWarning.name}-hexagons`
  private layerSymbolsWarningId = `${Layers.SimWarning.name}-symbol`
  private layerDirectionWarningId = `${Layers.SimWarning.name}-direction`
  private isLayerVisible = false
  private cleanUp$ = new Subject<void>()
  private cancelRequests$ = new Subject<void>()
  private sourceSegmentsId = 'test-warnings-static-segments-source'
  private sourceStaticHexagonId = `test-warnings-static-hexagon-source`
  private sourceLinesWarningId = `${Layers.SimWarning.name}-line-source`
  private sourceSymbolsWarningId = `${Layers.SimWarning.name}-symbol-source`
  private sourceHexagonWarningId = `${Layers.SimWarning.name}-hexagon-source`
  private sourceWaysLayerName = 'ways_layer'
  private sourceHexgonLayerName = 'hexagon_layer'

  private maxZoom = 15
  private minZoom = 4
  private minZoomAvailable = 6
  private maxZoomAvailable = 6
  private activeMetrics: WarningTypes[] = []
  private selectedFeatureId = ''
  private layerInitialized: boolean = false
  private onDestroy: boolean = false
  private currentDatasource: WarningDatasources = WarningDatasources.Rcs
  private updateMarkerCallback = () => {}
  public clusterMarkers: maplibregl.Marker[] = []

  private roadSegmentVectorSource: VectorSourceSpecification = {
    type: 'vector',
    tiles: [`${this.config.road_condition.road_segment_tile_url}?key=${this.config.maptiler_key}`],
    maxzoom: this.maxZoom,
    minzoom: 8,
  }

  private staticSegmentDataLayerSpecification: LineLayerSpecification = {
    id: this.layerStaticSegmentId,
    type: 'line',
    source: this.sourceSegmentsId, // reference the data source
    'source-layer': this.sourceWaysLayerName,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: 'none',
    },
    paint: {
      'line-color': '#a9a9a9',
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          6,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          6,
          2,
        ],
        18,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          14,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          14,
          10,
        ],
        22,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          18,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          18,
          14,
        ],
      ],
    },
  }

  private staticHexagonVectorSource: VectorSourceSpecification = {
    type: 'vector',
    tiles: [`${this.config.road_condition.hexagon_tile_url}?key=${this.config.maptiler_key}`],
    maxzoom: 12,
    minzoom: 6,
  }

  private staticHexagonDataLayerSpecification: FillLayerSpecification = {
    id: this.layerStaticHexagonId,
    minzoom: 6,
    'source-layer': this.sourceHexgonLayerName,
    type: 'fill',
    source: this.sourceStaticHexagonId, // reference the data source
    paint: {
      'fill-color': '#ffffff',
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.3,
        ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
        0.3,
        0.1,
      ],
      'fill-outline-color': '#ffffff',
    },
    layout: {
      visibility: 'none',
    },
  }

  private warningSymbolsDataLayerSpecification: SymbolLayerSpecification = {
    id: this.layerSymbolsWarningId,
    source: this.sourceSymbolsWarningId,
    minzoom: this.minZoom,
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-image': [
        'case',
        warningFilter.accidentFilter,
        MapIcons.TVA,
        warningFilter.bdvFilter,
        MapIcons.TBDV,
        warningFilter.csFilter,
        MapIcons.TCS,
        warningFilter.lvFilter,
        MapIcons.TLV,
        warningFilter.tlFilter,
        MapIcons.TTL,
        warningFilter.swFilter,
        MapIcons.TSW,
        warningFilter.hrFilter,
        MapIcons.THR,
        warningFilter.hsFilter,
        MapIcons.THS,
        '',
      ],
      'icon-size': [
        'case',
        ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
        0.5,
        0.35,
      ],
    },
    paint: {
      'icon-opacity': ['case', ['all', ['<', ['get', 'expireIn'], 5]], 0.4, 1],
    },
  }

  private warningLinesLayerSpecification: LineLayerSpecification = {
    id: this.layerLinesWarningId,
    type: 'line',
    minzoom: this.minZoom,
    source: this.sourceLinesWarningId, // reference the data source
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-opacity': ['case', ['all', ['<', ['get', 'expireIn'], 5]], 0.5, 1],
      'line-color': colorByPropertyName,
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          6,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          6,
          2,
        ],
        18,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          14,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          14,
          10,
        ],
        22,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          18,
          ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
          18,
          14,
        ],
      ],
    },
  }

  private areaWarningLayerSpecification: FillLayerSpecification = {
    id: this.layerHexagonsWarningId,
    minzoom: 7,
    type: 'fill',
    source: this.sourceHexagonWarningId, // reference the data source
    paint: {
      'fill-color': colorByPropertyName,
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
        0.5,
        0.2,
      ],
      'fill-outline-color': strokeByPropertyName,
    },
    layout: {
      visibility: 'none',
    },
  }

  private warningDirectionLayerSpecification: SymbolLayerSpecification = {
    id: this.layerDirectionWarningId,
    source: this.sourceLinesWarningId,
    minzoom: this.minZoom,
    type: 'symbol',
    filter: ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-size': 0.8,
      'icon-image': ['case', ['==', ['get', 'direction'], 'both'], 'arrow-both', 'arrow'],
      'icon-rotate': 180,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  }

  private warningSegmentDirectionLayerSpecification: SymbolLayerSpecification = {
    id: this.layerStaticDirectionWarningId,
    source: this.sourceSegmentsId,
    'source-layer': this.sourceWaysLayerName,
    minzoom: this.minZoom,
    type: 'symbol',
    filter: ['==', ['id'], Number(this.appState.getState().selectedFeatureId ?? '')],
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-size': 1,
      'icon-image': ['case', ['==', ['get', 'bidirectional'], true], 'arrow-both', 'arrow'],
      'icon-rotate': 180,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  }

  constructor(
    private readonly mapService: MapService,
    private readonly mapEventsService: MapEventsService,
    private appState: AppStateService,
    private dataService: DataSimulationWarningService,
    private authService: AuthService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {
    this.activeMetrics = Object.values(WarningTypes)
    this.currentDatasource = this.authService.getUserClaims().userDataFilters[0]
    this.selectedFeatureId = this.appState.getState().selectedFeatureId ?? ''

    if (mapService.isMapReady) {
      this.initializeLayer()
    } else {
      this.mapService.mapReadyChanged.subscribe(() => {
        this.initializeLayer()
      })
    }
    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe((state) => {
      if (this.layerInitialized) {
        this.evaluateState()
      }
    })
    this.appState.hasWarningDatasourceChanged$
      .pipe(takeUntil(this.cleanUp$))
      .subscribe((datasource) => {
        this.currentDatasource = datasource
        this.removeMarkers()
        this.reload()
      })
  }

  private initializeLayer() {
    // add sources
    this.mapService.map?.addSource(this.sourceSegmentsId, this.roadSegmentVectorSource)
    this.mapService.map?.addSource(this.sourceStaticHexagonId, this.staticHexagonVectorSource)
    this.mapService.map.addSource(this.sourceHexagonWarningId, {
      ...emptyGeoJsonSource,
    })
    this.mapService.map.addSource(this.sourceLinesWarningId, {
      ...emptyGeoJsonSource,
      tolerance: 0,
    })
    this.mapService.map.addSource(this.sourceSymbolsWarningId, {
      ...emptyGeoJsonSource,
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50,
      promoteId: 'featureId',
      clusterProperties: {
        // keep separate counts for each warning category in a cluster
        [WarningTypes.VehicleAccident]: ['+', ['case', warningFilter.accidentFilter, 1, 0]],
        [WarningTypes.BrokendownVehicle]: ['+', ['case', warningFilter.bdvFilter, 1, 0]],
        [WarningTypes.TractionLoss]: ['+', ['case', warningFilter.tlFilter, 1, 0]],
        [WarningTypes.ConstructionSite]: ['+', ['case', warningFilter.csFilter, 1, 0]],
        [WarningTypes.HeavyRain]: ['+', ['case', warningFilter.hrFilter, 1, 0]],
        [WarningTypes.HeavySnow]: ['+', ['case', warningFilter.hsFilter, 1, 0]],
        [WarningTypes.StrongWind]: ['+', ['case', warningFilter.swFilter, 1, 0]],
        [WarningTypes.LowVisibility]: ['+', ['case', warningFilter.lvFilter, 1, 0]],
      },
    })

    // static hexagons
    this.mapService.map?.addLayer(this.staticHexagonDataLayerSpecification)
    this.mapEventsService.registerForMapEvents({ layerId: this.layerStaticHexagonId }, true)

    // warning hexgons
    this.mapService.map.addLayer(this.areaWarningLayerSpecification)
    this.mapEventsService.registerForMapEvents({ layerId: this.layerHexagonsWarningId }, true)

    // warning directions
    this.mapService.map.addLayer(this.warningSegmentDirectionLayerSpecification)
    this.mapService.map.addLayer(this.warningDirectionLayerSpecification)

    // static segments
    this.mapService.map?.addLayer(this.staticSegmentDataLayerSpecification)
    this.mapEventsService.registerForMapEvents({ layerId: this.layerStaticSegmentId }, true)

    // warning lines
    this.mapService.map.addLayer(this.warningLinesLayerSpecification)
    this.mapEventsService.registerForMapEvents({ layerId: this.layerLinesWarningId }, true)

    // warning symbols
    this.mapService.map.addLayer(this.warningSymbolsDataLayerSpecification)

    // callback for updating markers when source data changes
    this.updateMarkerCallback = () => {
      if (
        this.mapService.isMapReady &&
        this.mapService.map.getSource(this.sourceSymbolsWarningId) &&
        this.mapService.map.isSourceLoaded(this.sourceSymbolsWarningId)
      ) {
        this.updateMarkers()
      }
    }
    this.mapEventsService.registerForMapSourceEvents(this.updateMarkerCallback)

    this.layerInitialized = true
    this.evaluateState()
  }

  ngOnDestroy() {
    this.onDestroy = true

    this.cleanUp$.next()

    // warning directions
    this.mapService.map.removeLayer(this.layerDirectionWarningId)
    this.mapService.map.removeLayer(this.layerStaticDirectionWarningId)

    // static segments
    this.mapService.map.removeLayer(this.layerStaticSegmentId)
    this.mapService.map.removeSource(this.sourceSegmentsId)
    this.mapEventsService.detachMapEvents({ layerId: this.layerStaticSegmentId })

    // static hexagons
    this.mapService.map.removeLayer(this.layerStaticHexagonId)
    this.mapService.map.removeSource(this.sourceStaticHexagonId)
    this.mapEventsService.detachMapEvents({ layerId: this.layerStaticHexagonId })

    // warning lines
    this.mapService.map.removeLayer(this.layerLinesWarningId)
    this.mapService.map.removeSource(this.sourceLinesWarningId)
    this.mapEventsService.detachMapEvents({ layerId: this.layerLinesWarningId })

    // warning hexgons
    this.mapService.map.removeLayer(this.layerHexagonsWarningId)
    this.mapService.map.removeSource(this.sourceHexagonWarningId)
    this.mapEventsService.detachMapEvents({ layerId: this.layerHexagonsWarningId })

    // warning symbols
    this.mapService.map.removeLayer(this.layerSymbolsWarningId)
    this.mapService.map.removeSource(this.sourceSymbolsWarningId)
    this.cancelRequests()
  }

  public async reload() {
    if (
      this.mapService.isMapReady &&
      this.isLayerVisible &&
      this.mapService.map.getZoom() >= this.minZoom
    ) {
      const xyzTiles = this.mapService.getXyzTiles(this.maxZoomAvailable, this.minZoomAvailable)
      await this.dataService.loadWarningEvents(this.activeMetrics, this.currentDatasource, xyzTiles)
      this.updateLayer()
    }
  }

  public updateLayer() {
    if (this.mapService.isMapReady) {
      const warningData = this.dataService.getRawTestWarningEvents()
      warningData[0].features = warningData[0].features.filter((feature) => {
        return addMinutes(feature.properties.expiryTime, 5).getTime() > Date.now()
      })

      warningData[1].features = warningData[1].features.filter((feature) => {
        return addMinutes(feature.properties.expiryTime, 5).getTime() > Date.now()
      })

      warningData[2].features = warningData[2].features.filter((feature) => {
        return addMinutes(feature.properties.expiryTime, 5).getTime() > Date.now()
      })

      this.mapService.setGeoJsonData(this.sourceSymbolsWarningId, warningData[0])
      this.mapService.setGeoJsonData(this.sourceLinesWarningId, warningData[1])
      this.mapService.setGeoJsonData(this.sourceHexagonWarningId, warningData[2])

      this.changeSelectAndHoverStateOfSelectedFeature()
    }
  }

  private evaluateState() {
    if (
      this.appState.getState().layers?.includes(Layers.VAWarning.name) ||
      this.appState.getState().layers?.includes(Layers.BDVWarning.name) ||
      this.appState.getState().layers?.includes(Layers.TLWarning.name) ||
      this.appState.getState().layers?.includes(Layers.CSWarning.name) ||
      this.appState.getState().layers?.includes(Layers.HRWarning.name) ||
      this.appState.getState().layers?.includes(Layers.HSWarning.name) ||
      this.appState.getState().layers?.includes(Layers.LVWarning.name) ||
      this.appState.getState().layers?.includes(Layers.SWWarning.name) ||
      this.appState.getState().layers?.includes(Layers.CreateSimAreaWarnings.name) ||
      this.appState.getState().layers?.includes(Layers.CreateSimRoadWarnings.name)
    ) {
      this.setVisibility(true)
    } else {
      this.setVisibility(false)
    }

    // if layer is not visible stop all events
    if (this.isLayerVisible === false) {
      this.activeMetrics = []
      return
    }
    let checkForActiveMetrics = []

    if (this.appState.getState().layers?.includes(Layers.VAWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.VehicleAccident)
    }
    if (this.appState.getState().layers?.includes(Layers.BDVWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.BrokendownVehicle)
    }
    if (this.appState.getState().layers?.includes(Layers.TLWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.TractionLoss)
    }
    if (this.appState.getState().layers?.includes(Layers.CSWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.ConstructionSite)
    }
    if (this.appState.getState().layers?.includes(Layers.HRWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.HeavyRain)
    }
    if (this.appState.getState().layers?.includes(Layers.HSWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.HeavySnow)
    }
    if (this.appState.getState().layers?.includes(Layers.LVWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.LowVisibility)
    }
    if (this.appState.getState().layers?.includes(Layers.SWWarning.name)) {
      checkForActiveMetrics.push(WarningTypes.StrongWind)
    }
    this.activeMetrics = checkForActiveMetrics

    if (
      this.appState.hasLayerVisibilityChanged(Layers.VAWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.BDVWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.TLWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.CSWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.HRWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.HSWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.LVWarning.name) ||
      this.appState.hasLayerVisibilityChanged(Layers.SWWarning.name)
    ) {
      this.reload()
    }

    if (!this.dataService.isDataInitialized) {
      this.reload()
    }

    if (this.appState.hasMapBoundsChanged()) {
      this.reload()
    }

    if (
      this.appState.hasSelectedFeatureIdChanged() ||
      this.appState.getState().selectedFeatureId !== ''
    ) {
      this.selectedFeatureId = this.appState.getState().selectedFeatureId ?? ''
      this.changeSelectAndHoverStateOfSelectedFeature()
    }
  }

  setVisibility(isVisible: boolean): void {
    this.isLayerVisible = isVisible

    if (this.mapService.isMapReady) {
      if (this.isLayerVisible) {
        // if static layer is visible
        if (this.appState.getState().layers?.includes(Layers.CreateSimRoadWarnings.name)) {
          this.mapService.setLayerVisibility(this.layerStaticSegmentId, true)
          this.mapService.setLayerVisibility(this.layerStaticDirectionWarningId, true)
        } else {
          this.mapService.setLayerVisibility(this.layerStaticDirectionWarningId, false)
          this.mapService.setLayerVisibility(this.layerStaticSegmentId, false)
        }
        if (this.appState.getState().layers?.includes(Layers.CreateSimAreaWarnings.name)) {
          this.mapService.setLayerVisibility(this.layerStaticHexagonId, true)
        } else {
          this.mapService.setLayerVisibility(this.layerStaticHexagonId, false)
        }
        // if one layer is active
        if (this.isOneOfLayersActive()) {
          this.mapService.setLayerVisibility(this.layerLinesWarningId, true)
          this.mapService.setLayerVisibility(this.layerHexagonsWarningId, true)
          this.mapService.setLayerVisibility(this.layerSymbolsWarningId, true)
          this.mapService.setLayerVisibility(this.layerDirectionWarningId, true)
        } else {
          this.mapService.setLayerVisibility(this.layerLinesWarningId, false)
          this.mapService.setLayerVisibility(this.layerHexagonsWarningId, false)
          this.mapService.setLayerVisibility(this.layerSymbolsWarningId, false)
          this.mapService.setLayerVisibility(this.layerDirectionWarningId, false)
        }
      } else {
        this.mapService.setLayerVisibility(this.layerStaticSegmentId, false)
        this.mapService.setLayerVisibility(this.layerStaticHexagonId, false)
        this.mapService.setLayerVisibility(this.layerStaticDirectionWarningId, false)
        this.mapService.setLayerVisibility(this.layerLinesWarningId, false)
        this.mapService.setLayerVisibility(this.layerHexagonsWarningId, false)
        this.mapService.setLayerVisibility(this.layerSymbolsWarningId, false)
        this.mapService.setLayerVisibility(this.layerDirectionWarningId, false)
        this.cancelRequests()
      }
    }
  }

  private isOneOfLayersActive() {
    return (
      this.appState.getState().layers?.includes(Layers.BDVWarning.name) ||
      this.appState.getState().layers?.includes(Layers.VAWarning.name) ||
      this.appState.getState().layers?.includes(Layers.TLWarning.name) ||
      this.appState.getState().layers?.includes(Layers.CSWarning.name) ||
      this.appState.getState().layers?.includes(Layers.LVWarning.name) ||
      this.appState.getState().layers?.includes(Layers.HRWarning.name) ||
      this.appState.getState().layers?.includes(Layers.HSWarning.name) ||
      this.appState.getState().layers?.includes(Layers.CSWarning.name)
    )
  }

  private cancelRequests() {
    this.cancelRequests$.next()
    this.cancelRequests$.complete()
    this.cancelRequests$ = new Subject()
  }

  private changeSelectAndHoverStateOfSelectedFeature() {
    if (this.onDestroy) return
    let selectedWarningLinesId =
      this.mapService.map.getFeatureState({
        source: this.sourceLinesWarningId,
        id: this.selectedFeatureId,
      }).selectedGeoId || ''
    let selectedStaticSegmentId =
      this.mapService.map.getFeatureState({
        source: this.sourceSegmentsId,
        sourceLayer: this.sourceWaysLayerName,
        id: this.selectedFeatureId,
      }).selectedGeoId || ''

    this.mapService.map.setFilter(this.layerDirectionWarningId, [
      '==',
      ['id'],
      Number(this.appState.getState().selectedFeatureId ?? ''),
    ])

    this.mapService.map.setFilter(this.layerStaticDirectionWarningId, [
      '==',
      ['id'],
      selectedStaticSegmentId,
    ])

    this.mapService.map.setLayoutProperty(this.layerSymbolsWarningId, 'icon-size', [
      'case',
      ['==', ['id'], Number(this.appState.getState().selectedFeatureId)],
      0.5,
      0.35,
    ])

    this.mapService.map.setPaintProperty(this.layerLinesWarningId, 'line-width', [
      'interpolate',
      ['linear'],
      ['zoom'],
      14,
      [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        6,
        ['==', ['get', 'segmentId'], selectedWarningLinesId],
        6,
        2,
      ],
      18,
      [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        14,
        ['==', ['get', 'segmentId'], selectedWarningLinesId],
        14,
        10,
      ],
      22,
      [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        18,
        ['==', ['get', 'segmentId'], selectedWarningLinesId],
        18,
        14,
      ],
    ])
    this.mapService.map.setPaintProperty(this.layerStaticSegmentId, 'line-width', [
      'interpolate',
      ['linear'],
      ['zoom'],
      14,
      [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        6,
        ['==', ['id'], selectedStaticSegmentId],
        6,
        2,
      ],
      18,
      [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        14,
        ['==', ['id'], selectedStaticSegmentId],
        14,
        10,
      ],
      22,
      [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        18,
        ['==', ['id'], selectedStaticSegmentId],
        18,
        14,
      ],
    ])
    this.mapService.map.setPaintProperty(this.layerHexagonsWarningId, 'fill-opacity', [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.5,
      ['==', ['id'], Number(this.selectedFeatureId)],
      0.5,
      0.2,
    ])
    this.mapService.map.setPaintProperty(this.layerStaticHexagonId, 'fill-opacity', [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.3,
      ['==', ['id'], Number(this.selectedFeatureId)],
      0.3,
      0.1,
    ])
  }
  removeMarkers() {
    for (let marker of this.clusterMarkers) {
      marker.remove()
    }
    this.clusterMarkers = []
  }

  private async updateMarkers() {
    this.removeMarkers()
    let features = this.mapService.map.querySourceFeatures(this.sourceSymbolsWarningId, {
      filter: ['all', ['has', 'point_count']],
    })
    features = features.filter((feature) => {
      return this.activeMetrics.some((activeMetric) => feature.properties[activeMetric] > 0)
    })

    // for every cluster on the screen, create an HTML marker for it
    for (let feature of features) {
      const coords = (feature.geometry as any).coordinates
      const props = feature.properties as any
      if (!props.cluster) continue
      const id = props.cluster_id
      const el = createDonutChart(props, this.activeMetrics)
      this.clusterMarkers.push(
        new maplibregl.Marker({
          element: el,
        }).setLngLat(coords),
      )
    }
    for (let marker of this.clusterMarkers) {
      marker.addTo(this.mapService.map)
    }
  }
}
