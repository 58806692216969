<!-- <ng-template [ngIf]="loginDisplay"> -->
<div class="container-fullbleed layout-main">
  <a class="position-absolute top-0 z-1" [routerLink]="'/'">
    <app-rb-live-clip></app-rb-live-clip>
  </a>

  <div class="position-absolute top-2 end-3 z-1 d-flex align-items-center">
    <app-app-selection></app-app-selection>
    <app-account></app-account>
  </div>

  <div class="position-relative h-100">
    <app-map></app-map>
    <router-outlet></router-outlet>
  </div>

  <div class="supergraphic bar"></div>
</div>

<!-- </ng-template> -->

<app-debug></app-debug>
