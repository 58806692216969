import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-layer-control',
  templateUrl: './layer-control-base.component.html',
})
export class LayerControlComponent {
  @Input() isLayerSelectionVisibile: boolean | undefined
  @Input() size = '4xl'
  @Input() isLayerConfigAvailable = true

  @Output() visibilityToggled = new EventEmitter<void>()
  @Output() layerConfigVisibiltyToggled = new EventEmitter<void>()
}
