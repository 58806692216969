import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { addMinutes, startOfToday, subMinutes, toDate } from 'date-fns'
import { combineLatest, firstValueFrom } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { Data } from '@angular/router'
import { isEqual } from 'lodash'
import { XyzTileModel } from '../models/xyz-tile.model'
import { WeatherModel } from '../models/weather.model'
import { AppStateService } from 'src/app/core/services/app-state.service'

@Injectable()
export class DataWeatherPredictionService {
  private weatherPredictionData = new Map<XyzTileModel, WeatherModel[]>()
  public isDataInitialized = false

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private appState: AppStateService,
  ) {}

  async loadWeatherPredictions(xyzTiles: XyzTileModel[], timeStamp?: Date) {
    if (this.appState.isLoading) return

    this.isDataInitialized = true
    this.weatherPredictionData = new Map<XyzTileModel, WeatherModel[]>()

    const weatherPredictionRequests$ = xyzTiles.map(async (tile) => {
      let data: WeatherModel[] = []
      if (timeStamp) {
        if (this.appState.getState().zoom! >= this.config.history_min_zoom_level) {
          data = await this.loadHistoricWeatherPredictionsForTile(tile, timeStamp)
        }
      } else {
        data = await this.loadWeatherForTile(tile)
      }
      return {
        key: tile,
        data: data,
      }
    })
    const weatherPredictions$ = combineLatest(weatherPredictionRequests$)
    const weatherPredictions = await firstValueFrom(weatherPredictions$)
    weatherPredictions.map((res) => {
      if (res && res.data != null) {
        this.weatherPredictionData.set(res.key, res.data)
      }
    })
    return this
  }

  async loadWeatherForTile(xyzTiles: XyzTileModel): Promise<WeatherModel[]> {
    const url = `${this.config.backend_url}rcs/weather/data/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}`
    const result = await firstValueFrom(this.http.get<WeatherModel[]>(url))
    return result
  }

  async loadHistoricWeatherPredictionsForTile(
    xyzTiles: XyzTileModel,
    timeStamp: Date,
  ): Promise<WeatherModel[]> {
    const timestampStart = subMinutes(timeStamp, 30).toISOString()
    const timestampEnd = addMinutes(timeStamp, 30).toISOString()
    const url = `${this.config.backend_url}rcs/weather/data/historic/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}?timestampStart=${timestampStart}&timestampEnd=${timestampEnd}`
    const result = await firstValueFrom(this.http.get<WeatherModel[]>(url))
    return result
  }

  getWeatherPredictions(timestamp?: Data): Map<XyzTileModel, WeatherModel[]> {
    const resultMap = new Map<XyzTileModel, WeatherModel[]>()
    if (timestamp === undefined) {
      return this.weatherPredictionData
    }
    this.weatherPredictionData.forEach((WeatherPrediction, tile) => {
      const filteredData = WeatherPrediction.filter((weatherResponse) => {
        return isEqual(
          toDate(weatherResponse[2] ? new Date(weatherResponse[2]) : startOfToday()),
          timestamp,
        )
      })
      resultMap.set(tile, filteredData)
    })
    return resultMap
  }
}
