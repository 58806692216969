import { Component } from '@angular/core'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { ObstaclesMetrics, getMetricFromMap } from 'src/app/obstacle/enums/obstacle-metrics.enum'
import { Layers } from 'src/app/shared/layers-config/layers'

@Component({
  selector: 'app-obstacle-segment-data',
  templateUrl: './obstacle-segment-data.component.html',
})
export class ObstacleSegmentComponent {
  public layerIds = Layers
  public isVAWarningLayerVisible: boolean | undefined
  public isBDVWarningVisible: boolean | undefined
  public isCSWarningVisible: boolean | undefined

  constructor(private appState: AppStateService) {
    const layers = this.appState.getState().layers

    if (layers) {
      this.isVAWarningLayerVisible = layers?.includes(Layers.VAWarning.name)
      this.isBDVWarningVisible = layers?.includes(Layers.BDVWarning.name)
      this.isCSWarningVisible = layers?.includes(Layers.CSWarning.name)
    } else {
      this.isVAWarningLayerVisible = true
      this.isBDVWarningVisible = true
      this.isCSWarningVisible = false
    }
  }

  toggleLayerVisbility(layerId: string) {
    switch (layerId) {
      case Layers.VAWarning.name:
        this.isVAWarningLayerVisible = !this.isVAWarningLayerVisible
        break
      case Layers.BDVWarning.name:
        this.isBDVWarningVisible = !this.isBDVWarningVisible
        break
      case Layers.CSWarning.name:
        this.isCSWarningVisible = !this.isCSWarningVisible
        break
      default:
        break
    }
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    const layers = this.appState
      .selectLayers()
      .filter(
        (layer) =>
          !layer.includes(Layers.BDVWarning.name) && !layer.includes(Layers.VAWarning.name) && !layer.includes(Layers.CSWarning.name),
      )

    if (this.isVAWarningLayerVisible) {
      layers.push(Layers.VAWarning.name)
    }
    if (this.isBDVWarningVisible) {
      layers.push(Layers.BDVWarning.name)
    }
    if (this.isCSWarningVisible) {
      layers.push(Layers.CSWarning.name)
    }

    this.appState.setState(AppPaths.Obstacle, {
      layers: layers?.toString(),
    })
  }
}
