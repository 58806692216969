import { Component } from '@angular/core'
import { AppPaths } from 'src/app/app-routing.module'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { RcsMetrics, getMetricFromMap } from 'src/app/road-condition/enums/rcs-metrics.enum'
import { Layers } from 'src/app/shared/layers-config/layers'

@Component({
  selector: 'app-rc-road-segment',
  templateUrl: './rc-road-segment.component.html',
})
export class RcRoadSegmentComponent {
  public Permissions = Permissions
  public Layers = Layers
  public metrics = RcsMetrics
  public getMetricFromMap = getMetricFromMap
  public selectedRoadMetric: string = ''
  public isRoadSegmentLayerVisible: boolean | undefined
  public defaultMetric = RcsMetrics.RoadSegment_RoadCondition

  constructor(private appState: AppStateService) {
    const layers = this.appState.getState().layers
    if (layers) {
      this.isRoadSegmentLayerVisible = layers?.includes(Layers.RoadSegment.name)
    } else {
      this.isRoadSegmentLayerVisible = true
      this.selectedRoadMetric = this.defaultMetric
    }

    const roadMetrics = this.appState.selectMetrics(Layers.RoadSegment.name)
    if (roadMetrics && roadMetrics.length > 0) {
      this.selectedRoadMetric = roadMetrics[0] as RcsMetrics
    }

    this.updateQueryParameters()
  }

  setMetric(metric: string) {
    this.selectedRoadMetric = this.selectedRoadMetric == metric ? '' : metric
    this.isRoadSegmentLayerVisible = this.selectedRoadMetric === '' ? false : true
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    let layers = this.appState.selectLayers()
    layers = layers.filter((layer) => !layer.startsWith(Layers.TLWarning.name))
    layers = layers.filter((layer) => !layer.startsWith(Layers.RoadSegment.name))

    if (this.isRoadSegmentLayerVisible) {
      layers?.push(Layers.RoadSegment.name)
    }

    const metrics = this.appState.selectMetrics(Layers.RoadSegment.name, false)
    if (this.selectedRoadMetric) {
      metrics.push(this.selectedRoadMetric)
    }

    if (this.selectedRoadMetric == RcsMetrics.RoadSegment_TractionLoss) {
      layers?.push(Layers.TLWarning.name)
    }

    this.appState.setState(AppPaths.RoadCondition, {
      layers: layers?.toString(),
      metrics: metrics?.toString(),
    })
  }
}
