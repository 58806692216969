import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  private renderer: Renderer2

  public addDemoModeSource = new BehaviorSubject<string>(
    window.localStorage.getItem('demoMode') || 'false',
  )

  constructor(private rendererFactory2: RendererFactory2) {
    this.renderer = this.rendererFactory2.createRenderer(null, null)

    // start listen for shift+d
    this.renderer.listen('document', 'keydown.shift.d', () => {
      this.updateDemoMode()
    })
  }

  updateDemoMode(): void {
    let demoMode = window.localStorage.getItem('demoMode')
    demoMode = demoMode === 'false' || demoMode == null ? 'true' : 'false'
    window.localStorage.setItem('demoMode', demoMode)
    this.addDemoModeSource.next(demoMode!)
  }
}
