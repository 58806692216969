import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ScreenLockService {
  private wakeLock: any
  public isScreenLockAvailable = false
  public isScreenLockAcitvated = false
  public screenLockChanged$ = new Subject<boolean>()

  constructor() {
    if ('wakeLock' in navigator) {
      this.isScreenLockAvailable = true
      console.log('Screen Wake Lock API supported!')
    } else {
      this.isScreenLockAvailable = false
      console.log('Wake lock is not supported by this browser.')
    }
  }

  private initListeners() {
    document.addEventListener('visibilitychange', async () => {
      if (this.wakeLock !== null && document.visibilityState === 'visible') {
        this.wakeLock = await (navigator as any).wakeLock.request('screen')
        console.log('Screen Wake Lock reactivated after visibility change!')
      }
    })
    this.wakeLock.addEventListener('release', () => {
      console.log('Wake Lock has been released')
    })
  }

  public async startScreenLock() {
    if (this.isScreenLockAvailable) {
      try {
        this.wakeLock = await (navigator as any).wakeLock.request('screen')
        this.initListeners()
        this.isScreenLockAcitvated = true
        this.screenLockChanged$.next(true)
        console.log('Wake Lock is active!')
      } catch (err) {
        // The Wake Lock request has failed - usually system related, such as battery.
        this.isScreenLockAcitvated = false
        console.log(err)
      }
    }
  }

  public async stopScreenLock() {
    this.wakeLock.release().then(() => {
      this.wakeLock = null
    })
  }
}
