import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-legend-item',
  templateUrl: './legend-item.component.html',
})
export class LegendItemComponent {
  @Input() value: string | undefined | null
  @Input() description: string = ''
  @Input() type: string | undefined = ''
  @Input() category: string | undefined = ''
}
