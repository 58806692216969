import { WarningTypes } from '../enums/warning-types.enum'

// code for creating an SVG donut chart from feature properties
export const createDonutChart = (properties: any, activeMetrics: WarningTypes[]) => {
  // colors to use for the categories
  const colorsProperties = {
    VA: '#c94d04',
    LV: '#0052a8',
    TL: '#ad0206',
    CS: '#088735',
    SW: '#99c408',
    HR: '#c91874',
    HS: '#02d1a1',
    BDV: '#ad9702',
  }

  const colors: any = []
  const counts: any = []
  const offsets = []
  activeMetrics.forEach((metric) => {
    colors.push(colorsProperties[metric])
    counts.push(properties[metric])
  })

  let total = 0
  for (let i = 0; i < counts.length; i++) {
    offsets.push(total)
    total += counts[i]
  }

  const fontSize = total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16
  const r = total >= 100 ? 35 : total >= 50 ? 30 : total >= 10 ? 25 : total >= 5 ? 20 : 15
  const r0 = Math.round(r * 0.8)
  const w = r * 2

  let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, colors[i])
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill='black' fill-opacity=0.3 /><text dominant-baseline="central" transform="translate(${r}, ${r})" fill='#BABABA' >${total.toLocaleString()}</text></svg></div>`

  const el = document.createElement('div')
  el.innerHTML = html
  return el.firstChild as HTMLElement
}

function donutSegment(start: number, end: number, r: number, r0: number, color: string) {
  if (end - start === 1) end -= 0.00001
  const a0 = 2 * Math.PI * (start - 0.25)
  const a1 = 2 * Math.PI * (end - 0.25)
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0)
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1)
  const largeArc = end - start > 0.5 ? 1 : 0

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    'L',
    r + r * x0,
    r + r * y0,
    'A',
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    'L',
    r + r0 * x1,
    r + r0 * y1,
    'A',
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    `" fill="${color}" />`,
  ].join(' ')
}
