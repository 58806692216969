<div
  class="details position-absolute start-5 z-3 top-26 max-vh-70 overflow-auto"
  cdkDrag
  cdkDragBoundary="app-root"
  [class.d-none]="details.size <= 0"
>
  <app-details
    class="container-fluid p-0"
    (closed)="close()"
    (selectedItemChanged)="toggleFeature($event)"
    [numberOfDetails]="features.length"
    [index]="index"
  >
    <div class="p-2">
      <div class="row">
        <app-details-meta-category
          class="col gx-5"
          [category]="details.get('Meta')"
        ></app-details-meta-category>
      </div>
      <div class="row">
        <app-details-category
          class="col-12 gy-2 gx-5"
          [category]="details.get(BwcMetrics.RoadSegment_HeavyRain)"
          *hasPermissionAny="[Permissions.Weather_All_Read]"
        >
        </app-details-category>
        <app-details-category
          class="col-12 gy-2 gx-5"
          [category]="details.get(BwcMetrics.RoadSegment_HeavySnow)"
          *hasPermissionAny="[Permissions.Weather_All_Read]"
        >
        </app-details-category>
        <app-details-category
          class="col-12 gy-2 gx-5"
          [category]="details.get(BwcMetrics.RoadSegment_StrongWind)"
          *hasPermissionAny="[Permissions.Weather_All_Read]"
        >
        </app-details-category>
        <app-details-category
          class="col-12 gy-2 gx-5"
          [category]="details.get(BwcMetrics.RoadSegment_LowVisibility)"
        ></app-details-category>
      </div>
    </div>
    <div
      cdkDragHandle
      class="rb-ic rb-ic-drag-handle text-gray fs-2xl z-3 position-absolute bottom-2 end-2 cursor-pointer hover-effect"
    ></div>
  </app-details>
</div>
