import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { AccountInfo } from '@azure/msal-browser'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  account: AccountInfo | null = null
  close: any
  isContentVisible: boolean = false

  constructor(private readonly authService: AuthService) {
    this.authService.activeAccountChanges.subscribe((account) => {
      this.account = account
    })
  }

  ngOnInit(): void {
    this.account = this.authService.getAccountInfo()
  }

  toggleContent() {
    this.isContentVisible = !this.isContentVisible
  }

  hideContent() {
    this.isContentVisible = false
  }

  logout() {
    this.authService.logout()
  }
}
