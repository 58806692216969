import { Component, Input } from '@angular/core'
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'details-accordion-basic',
  standalone: true,
  imports: [NgbAccordionModule],
  templateUrl: './details-accordion.html',
})
export class DetailsAccordionComponent {
  @Input() rawFeature: string | undefined = undefined
}
