import { Feature, FeatureCollection, Point } from 'geojson'
import * as h3 from 'h3-js'
import * as geojson2h3 from 'geojson2h3'
import _ from 'lodash'
import { RawDataModel } from 'src/app/shared/models/raw-event.model'

export const generateHexagons = (
  featureCollection: FeatureCollection<Point, RawDataModel>,
  resolution: number,
  kRingSize: number,
) => {
  featureCollection.features.forEach((feature) => {
    feature.properties.hex = h3.latLngToCell(
      feature.geometry.coordinates[1],
      feature.geometry.coordinates[0],
      resolution,
    )
  })
  const groups = _.groupBy(
    featureCollection.features,
    (feature: any): Array<Feature<Point, RawDataModel>> => {
      return feature.properties.hex
    },
  )
  const groupKeys = Object.getOwnPropertyNames(groups)

  const hexids: RawDataModel[] = []
  for (const groupKey of groupKeys) {
    const group = groups[groupKey] as Array<Feature<Point, RawDataModel>>

    const sumValues = group.reduce(
      (acc): { sampleDensity: number } => {
        acc.sampleDensity += 1
        return acc
      },
      { sampleDensity: 0 },
    )

    hexids.push({
      hex: group[0].properties.hex,
      timestamp: 0,
      endTimestamp: 0,
      startTimestamp: 0,
    })
  }
  const kring = hexids.map((hexid) => h3.gridDisk(hexid.hex, kRingSize)).flat()

  return geojson2h3.h3SetToFeatureCollection(
    kring.map((point): string => point),
    // (hexId: string): HeavyRainHexagonModel => {
    //   return hexids.find((point): boolean => point.hex === hexId)
    // }
  )
}
