import { LegendModel } from '../../shared/models/legend.model'

export const bwcLegendRoadHazards: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'ok',
      value: 'noncritical',
    },
    {
      description: 'warning',
      value: 'dangerous',
    },
  ],
}

export const bwcLegendWeatherRainFall: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'slight',
      value: 'noncritical',
    },
    {
      description: '',
      value: 'warning',
    },
    {
      description: 'heavy',
      value: 'dangerous',
    },
  ],
}

export const bwcLegendWeatherSnowFall: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'slight',
      value: 'noncritical',
    },
    {
      description: '',
      value: 'warning',
    },
    {
      description: 'heavy',
      value: 'dangerous',
    },
  ],
}

export const bwcLegendWeatherVisibility: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'high',
      value: 'noncritical',
    },
    {
      description: '',
      value: 'warning',
    },
    {
      description: 'low',
      value: 'dangerous',
    },
  ],
}

export const bwcLegendWeatherWindSpeed: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'slight',
      value: 'noncritical',
    },
    {
      description: '',
      value: 'warning',
    },
    {
      description: 'strong',
      value: 'dangerous',
    },
  ],
}

export const bwcLegendWeatherAirTemp: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'high',
      value: 'noncritical',
    },
    {
      description: '',
      value: 'warning',
    },
    {
      description: 'low',
      value: 'dangerous',
    },
  ],
}

export const bwcLegendVehicleData: LegendModel = {
  type: 'hazard',
  category: 'vehicle',
  items: [
    {
      description: 'Heavy Rain',
      value: 'rain-hazard',
    },
    {
      description: 'Fog',
      value: 'fog-hazard',
    },
  ],
}
