import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { RoadConditionPageComponent } from './pages/road-condition-page/road-condition-page.component'
import { SharedModule } from '../shared/shared.module'
import { RcDetailsComponent } from './components/rc-details/rc-details.component'
import { RcLegendComponent } from './components/rc-legend/rc-legend.component'
import { MobilePageComponent } from './pages/mobile-page/mobile-page.component'
import { MobileDetailsComponent } from './components/mobile-details/mobile-details.component'
import { RcLayerConfigComponent } from './components/rc-layer-config/rc-layer-config.component'
import { RcHistoryControlComponent } from './components/rc-history-control/rc-history-control.component'
import { RcRoadSegmentComponent } from './components/rc-layer-categories/rc-road-segment/rc-road-segment.component'
import { RcWeatherPredictionComponent } from './components/rc-layer-categories/rc-weather-prediction/rc-weather-prediction.component'
import { RcVehicleDataComponent } from './components/rc-layer-categories/rc-vehicle-data/rc-vehicle-data.component'
import { RcDetailsWeatherComponent } from './components/rc-details-weather/rc-details-weather.component'
import { DetailsAccordionComponent } from '../shared/components/details/details-accordion/details-accordion'
import { RcRawDetailsComponent } from './components/rc-raw-details/rc-raw-details.component'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    RcLegendComponent,
    RoadConditionPageComponent,
    RcDetailsComponent,
    RcRawDetailsComponent,
    MobilePageComponent,
    MobileDetailsComponent,
    RcLayerConfigComponent,
    RcHistoryControlComponent,
    RcRoadSegmentComponent,
    RcWeatherPredictionComponent,
    RcVehicleDataComponent,
    RcDetailsWeatherComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    SharedModule,
    DetailsAccordionComponent,
    NgbTooltipModule,
  ],
})
export class RoadConditionModule {}
