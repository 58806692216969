/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { APP_CONFIG } from './app/app.config'
import { DataWebcamsService } from './app/shared/services/data-webcams.service'

fetch('/config.json')
  .then(async (response) => await response.json())
  .then(async (config): Promise<void> => {
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err))
  })

// Declare global variables here:
declare global {
  // Add map to the Window interface to use it in the cypress e2e tests
  interface Window {
    map: any
  }
}
