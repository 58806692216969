<app-layer-category description="Standard Vehicle">
  <app-layer-control-item
    description="Heavy Rain"
    [isLayerVisibile]="isHeavyRainLayerVisible"
    [layerId]="layerIds.VehicleDataRain.name"
    icon="windshield-wiper"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="Slippery Road"
    [isLayerVisibile]="isSlipperyRoadVisible"
    [layerId]="layerIds.VehicleDataSlippery.name"
    icon="car-waves"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
</app-layer-category>
