<div class="d-flex justify-content-center gap-1">
  <i
    class="d-flex flex-column rb-ic rb-ic-back-left-double justify-content-center hover-effect cursor-pointer"
    (click)="toggleFeature(-1)"
  ></i>
  <span class="d-flex flex-column text-lightgray mw-100 text-center justify-content-center"
    >{{ this.index + 1 }} of {{ numberOfDetails }}</span
  >
  <i
    class="d-flex flex-column rb-ic rb-ic-forward-right-double justify-content-center hover-effect cursor-pointer"
    (click)="toggleFeature(+1)"
  ></i>
</div>
