<div class="row justify-content-start">
  <div class="col-12 text-white text-start mt-2">Links:</div>
</div>
<div class="row justify-content-evenly gy-2">
  <div class="col-12 col-md-4">
    <a
      class="btn btn-card text-lightgray miw-100"
      [href]="getBingMapsUrl()"
      target="_blank"
      rel="noopener noreferrer"
      >Bing</a
    >
  </div>
  <div class="col-12 col-md-4">
    <a
      class="btn btn-card text-lightgray miw-100"
      [href]="getGoogleMapsUrl()"
      target="_blank"
      rel="noopener noreferrer"
      >Google</a
    >
  </div>

  <div class="col-12 col-md-4">
    <a
      class="btn btn-card text-lightgray miw-100"
      [href]="getOsmUrl()"
      target="_blank"
      rel="noopener noreferrer"
      >OSM</a
    >
  </div>
</div>
